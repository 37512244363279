import React from 'react';
import PropTypes from 'prop-types';
import { Box, Alert } from '@chakra-ui/react';

function ErrorBanner({ errorMessages = [] }) {
  return (errorMessages ?? []).map((messages, messagesIndex) => (
    <Box key={messagesIndex} mb="24px">
      <Alert status="error" variant="subtle">
        <Box>
          {(messages ?? []).map((message, messageIndex) => (
            <Box key={`${messagesIndex}-${messageIndex}`}>{message}</Box>
          ))}
        </Box>
      </Alert>
    </Box>
  ));
}

ErrorBanner.propTypes = {
  errorMessages: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
};

export default ErrorBanner;
