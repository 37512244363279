import { useCallback } from 'react';
import { centsToDollars } from '../utils/money';
import { useAppContext } from '../pages/MemberCenter/context/appContext';

const useDollarsTextFromCents = () => {
  const { merchantData } = useAppContext();
  const {
    base_currency_code,
    base_currency: { alternate_symbol },
  } = merchantData;

  return useCallback(
    (cents) =>
      `${alternate_symbol}${centsToDollars(cents, base_currency_code)}`,
    [alternate_symbol, base_currency_code],
  );
};

export default useDollarsTextFromCents;
