/* eslint-disable react/prop-types */
import { Box } from '@chakra-ui/react';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';
import { useAppContext } from '../../context/appContext';

const RecaptchaCheckbox = (props) => {
  const {
    grecaptcha,
    recaptchaSiteKey,
    recaptchaEnterpriseCheckboxSiteKey,
    isRecaptchaEnabled,
  } = useAppContext();
  const {
    refReCAPTCHA,
    refCheckboxReCAPTCHA,
    isShowCheckboxRecaptcha,
    onRecaptchaCheckboxCallback,
    errorText,
    isForceShowRecaptcha = false,
  } = props;
  const isShowRecaptcha = isRecaptchaEnabled || isForceShowRecaptcha;
  return (
    isShowRecaptcha && (
      <>
        <ReCAPTCHA
          ref={refReCAPTCHA}
          size="invisible"
          grecaptcha={grecaptcha}
          sitekey={recaptchaSiteKey}
        />
        <Box
          mt={isShowCheckboxRecaptcha ? '32px' : '0'}
          h={isShowCheckboxRecaptcha ? 'inherit' : '0'}
          pos={isShowCheckboxRecaptcha ? '' : 'fixed'}
          top={isShowCheckboxRecaptcha ? '' : '100vh'}
          align="center"
        >
          <ReCAPTCHA
            style={{
              display: 'flex',
              'justify-content': 'center',
            }}
            ref={refCheckboxReCAPTCHA}
            size="normal"
            grecaptcha={grecaptcha}
            sitekey={recaptchaEnterpriseCheckboxSiteKey}
            onChange={onRecaptchaCheckboxCallback}
          />
          {isShowCheckboxRecaptcha && errorText}
        </Box>
      </>
    )
  );
};

RecaptchaCheckbox.propTypes = {
  refReCAPTCHA: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  refCheckboxReCAPTCHA: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isShowCheckboxRecaptcha: PropTypes.bool,
  onRecaptchaCheckboxCallback: PropTypes.func,
  errorText: PropTypes.element,
  isForceShowRecaptcha: PropTypes.bool,
};

export default RecaptchaCheckbox;
