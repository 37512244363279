import React from 'react';
import { createIcon } from '@chakra-ui/react';

const CheckBoard = createIcon({
  displayName: 'CheckBoard',
  viewBox: '0 0 20 20',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.488 13.462L14.5 7.45l-1.062-1.062-4.95 4.932-1.926-1.944L5.5 10.438l2.988 3.024zM10 2.95a.711.711 0 0 0-.522.216.711.711 0 0 0-.216.522c0 .204.072.384.216.54a.685.685 0 0 0 .522.234.685.685 0 0 0 .522-.234.772.772 0 0 0 .216-.54.711.711 0 0 0-.216-.522A.711.711 0 0 0 10 2.95zm5.238 0c.408 0 .762.15 1.062.45.3.3.45.654.45 1.062v10.476c0 .408-.15.762-.45 1.062-.3.3-.654.45-1.062.45H4.762C4.354 16.45 4 16.3 3.7 16c-.3-.3-.45-.654-.45-1.062V4.462c0-.408.15-.762.45-1.062.3-.3.654-.45 1.062-.45h3.132c.156-.444.423-.807.801-1.089A2.124 2.124 0 0 1 10 1.438c.492 0 .927.141 1.305.423.378.282.645.645.801 1.089h3.132z"
      fill="currentColor"
    />
  ),
});

export default CheckBoard;
