import { useEffect, useMemo } from 'react';
import { range, rangeRight } from 'lodash-es';
import dayjs from 'dayjs';
import { getJsMonth, getMaxDate } from '../utils/birthday';
import { BIRTHDAY_FORMAT } from '../shop/constants/user';

const EMPTY_OPTION = '-';

const useBirthdayPicker = ({ value, onChange, birthdayFormat }) => {
  const shouldShowYear = birthdayFormat !== BIRTHDAY_FORMAT.MMDD;
  const shouldShowDate = birthdayFormat !== BIRTHDAY_FORMAT.YYYYMM;

  const maxDate = useMemo(
    () => getMaxDate(value.year, getJsMonth(value.month)),
    [value.year, value.month],
  );

  const yearOptions = useMemo(
    () => [EMPTY_OPTION].concat(rangeRight(1900, dayjs().year() + 1)),
    [],
  );
  const monthOptions = useMemo(
    () => [EMPTY_OPTION].concat(range(1, 12 + 1)),
    [],
  );
  const dateOptions = useMemo(
    () => [EMPTY_OPTION].concat(range(1, maxDate + 1)),
    [maxDate],
  );

  useEffect(() => {
    if (maxDate < value.date) {
      onChange({ ...value, date: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxDate]);

  return {
    shouldShowDate,
    shouldShowYear,
    yearOptions,
    monthOptions,
    dateOptions,
    EMPTY_OPTION,
  };
};

export default useBirthdayPicker;
