import React from 'react';
import { createIcon } from '@chakra-ui/react';

const EyeClose = createIcon({
  displayName: 'EyeClose',
  viewBox: '0 0 20 14',
  path: (
    <path
      d="M19.5088 1.2089C19.611 1.25074 19.6601 1.36775 19.6163 1.46914C19.1289 2.59656 18.4454 3.62068 17.6082 4.49961L18.9568 5.99878L17.8417 7.00198L16.4985 5.50888C15.5938 6.22036 14.5674 6.7865 13.4555 7.17185L14.1946 9.2714L12.8053 9.72941L12.0394 7.55372C11.3797 7.68288 10.6977 7.75059 9.99978 7.75059C9.32819 7.75059 8.67131 7.68789 8.0348 7.56806L7.31157 9.73778L5.88854 9.26343L6.58145 7.18472C5.46686 6.80317 4.43758 6.24022 3.52977 5.53138L2.03027 7.03088L0.969613 5.97021L2.41524 4.52459C1.56718 3.64011 0.875232 2.60726 0.383259 1.46914C0.339431 1.36775 0.388517 1.25074 0.490744 1.2089L1.50879 0.79228C1.61102 0.750444 1.72764 0.799611 1.7718 0.900853C3.14365 4.04555 6.30943 6.25059 9.99978 6.25059C13.6901 6.25059 16.8559 4.04555 18.2277 0.900854C18.2719 0.799611 18.3885 0.750444 18.4907 0.79228L19.5088 1.2089Z"
      fill="currentColor"
    />
  ),
});

export default EyeClose;
