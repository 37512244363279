import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';
import { Check } from '../../../context/Chakra/Icons';

const CustomIcon = (props) => {
  const { isChecked, ...restProps } = props;

  if (isChecked) {
    return <Check boxSize="10px" {...restProps} />;
  }

  return null;
};

CustomIcon.propTypes = {
  isChecked: PropTypes.bool,
};

const Checkbox = (props) => {
  return <ChakraCheckbox icon={<CustomIcon />} {...props} />;
};

export default Checkbox;
