import { useAppContext } from '../utils/context';
import { parseUserSetting } from '../utils/userSetting';

const useUserSetting = () => {
  const { merchantData } = useAppContext();

  return parseUserSetting(merchantData?.user_setting?.data ?? {});
};

export default useUserSetting;
