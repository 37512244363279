import React, { useState, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Box } from '@chakra-ui/react';
import { getJsMonth, validateBirthday } from '../../../../utils/birthday';
import { BIRTHDAY_ERROR } from '../../../../shop/constants/user';
import { BOOLEAN_STATUS } from '../../../../constants/userSetting';
import { useAppContext } from '../../context/appContext';
import { STEPS } from '../../constants/steps';
import {
  emailSchema,
  phoneCountrySchema,
  mobilePhoneSchema,
  acceptPolicySchema,
  pincodeSchema,
  birthdaySchema,
  passwordSchema,
  generateCustomFieldsSchema,
  nameSchema,
  genderSchema,
} from '../../utils/yupSchema';
import StepOne from './steps/StepOne';
import StepTwo from './steps/StepTwo';
import StepThree from './steps/StepThree';
import StepSuccess from './steps/StepSuccess';

const SignUpSection = () => {
  const {
    userSetting,
    isEmailRequired,
    isMobilePhoneRequired,
    genderSetting,
    birthdaySetting,
    smsVerificationEnabled,
    emailVerificationEnabled,
  } = useAppContext();
  const [currentStep, setCurrentStep] = useState(STEPS.SIGN_UP.FILL_IN_ACCOUNT);
  const schema = useMemo(() => {
    switch (currentStep) {
      case STEPS.SIGN_UP.FILL_IN_ACCOUNT:
        return yup.object().shape({
          ...(isEmailRequired ? { email: emailSchema } : {}),
          ...(isMobilePhoneRequired
            ? {
                phone_country: phoneCountrySchema,
                mobile_phone: mobilePhoneSchema,
              }
            : {}),
          accept_policy: acceptPolicySchema,
        });
      case STEPS.SIGN_UP.MOBILE_PHONE_VERIFICATION:
        return yup.object().shape({
          pincode: pincodeSchema,
        });
      case STEPS.SIGN_UP.BASIC_INFO:
        return yup.object().shape({
          name: nameSchema,
          password: passwordSchema,
          ...(genderSetting.required === BOOLEAN_STATUS.TRUE
            ? { gender: genderSchema }
            : {}),
          ...(birthdaySetting.include === BOOLEAN_STATUS.TRUE
            ? {
                birthday: birthdaySchema.test({
                  name: 'custom',
                  test: (value, ctx) => {
                    const {
                      birthday_format: birthdayFormat,
                      minimum_age_limit: minAge,
                    } = userSetting;
                    const { year, month, date } = value;
                    const errorCode = validateBirthday({
                      birthdayFormat,
                      year,
                      month: getJsMonth(month),
                      date,
                      minAge,
                      nullable:
                        birthdaySetting.required !== BOOLEAN_STATUS.TRUE,
                    });

                    let message = null;
                    switch (errorCode) {
                      case BIRTHDAY_ERROR.INVALID:
                        message = {
                          translateKey: 'Validation - birthday - invalid',
                        };
                        break;
                      case BIRTHDAY_ERROR.MIN_AGE:
                        message = {
                          translateKey: 'Validation - birthday - min age',
                          params: { age: userSetting.minimum_age_limit },
                        };
                        break;
                      case BIRTHDAY_ERROR.REQUIRED:
                        message = {
                          translateKey: 'Validation - birthday - required',
                        };
                        break;
                      case null:
                        break;
                      default:
                        throw Error(`unknown birthday error: ${errorCode}`);
                    }

                    return message === null
                      ? true
                      : ctx.createError({ message });
                  },
                }),
              }
            : {}),
          custom_data: yup
            .object()
            .shape(generateCustomFieldsSchema(userSetting.custom_fields)),
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);
  const methods = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: false,
  });

  return (
    <Box>
      <FormProvider {...methods}>
        {currentStep === STEPS.SIGN_UP.FILL_IN_ACCOUNT && (
          <StepOne
            handleNextStep={() => {
              if (smsVerificationEnabled) {
                setCurrentStep(STEPS.SIGN_UP.MOBILE_PHONE_VERIFICATION);
              } else {
                setCurrentStep(STEPS.SIGN_UP.BASIC_INFO);
              }
            }}
          />
        )}
        {currentStep === STEPS.SIGN_UP.MOBILE_PHONE_VERIFICATION && (
          <StepTwo
            handleNextStep={() => {
              setCurrentStep(STEPS.SIGN_UP.BASIC_INFO);
            }}
            handlePrevStep={() => {
              setCurrentStep(STEPS.SIGN_UP.FILL_IN_ACCOUNT);
            }}
          />
        )}
        {currentStep === STEPS.SIGN_UP.BASIC_INFO && (
          <StepThree
            handlePrevStep={() => {
              if (smsVerificationEnabled) {
                setCurrentStep(STEPS.SIGN_UP.MOBILE_PHONE_VERIFICATION);
              } else {
                setCurrentStep(STEPS.SIGN_UP.FILL_IN_ACCOUNT);
              }
            }}
            handleSignUpSuccess={() => {
              if (emailVerificationEnabled) {
                return;
              }
              setCurrentStep(STEPS.SIGN_UP.SUCCESS);
            }}
          />
        )}
        {currentStep === STEPS.SIGN_UP.SUCCESS && <StepSuccess />}
      </FormProvider>
    </Box>
  );
};

export default SignUpSection;
