import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash-es';
import { INPUT_STATUS } from '../components/Input/inputStatus';

export const useInput = (props) => {
  const {
    status: inputStatus = INPUT_STATUS.DEFAULT,
    value,
    label,
    onFocus,
    onBlur,
  } = props;
  const [status, setStatus] = useState(inputStatus);

  useEffect(() => {
    setStatus(inputStatus);
  }, [inputStatus]);

  return {
    shouldShowLabel:
      status === INPUT_STATUS.FOCUSED || (!isEmpty(value) && !isEmpty(label)),
    onFocus: () => {
      onFocus?.();
      setStatus(INPUT_STATUS.FOCUSED);
    },
    onBlur: () => {
      onBlur?.();
      setStatus(INPUT_STATUS.DEFAULT);
    },
  };
};
