import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import slFeatureServiceModule from '@shopline/sl-feature/js/sl-feature';
import useGlobalSDK from '../../../hooks/useGlobalSDK';
import { parseUserSetting } from '../../../utils/userSetting';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({
  children,
  featuresLimit,
  ...restProps
}) => {
  const slFeatureService = slFeatureServiceModule(featuresLimit);
  const grecaptcha = useGlobalSDK('grecaptcha');
  const userSetting = parseUserSetting(
    restProps?.merchantData?.user_setting?.data,
  );
  const contextProps = useMemo(
    () => ({
      slFeatureService,
      grecaptcha,
      userSetting,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slFeatureService, grecaptcha],
  );

  return (
    <AppContext.Provider value={{ ...contextProps, ...restProps }}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  featuresLimit: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
      PropTypes.objectOf(PropTypes.bool),
    ]),
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isRecaptchaEnabled: PropTypes.bool,
  recaptchaSiteKey: PropTypes.string,
  requestCountry: PropTypes.string,
};
