import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { SUBSCRIPTION_STATUS_TYPE } from '../constants/benchat';
import benchatService from '../service/benchatService';

/**
 * For email, sms, whatsapp subscription and for sms, whatsapp, line, fb unsubscribe
 *
 * @typedef {Object} ReturnValue
 * @property {boolean} hasSubscribed
 * @property {function} setHasSubscribed
 * @property {function} subscribe
 * @property {function} unsubscribe
 *
 * @param {object} props
 * @param {string} props.platform
 * @param {array} props.topics
 * @param {array} props.subscriptions
 * @param {string} props.userId
 * @returns {ReturnValue}
 */
const useGeneralSubscription = (props) => {
  const { platform, subscriptions, topics, userId } = props;
  const subscriptionRef = useRef(subscriptions);
  const [hasSubscribed, setHasSubscribed] = useState(
    subscriptions.filter((subscription) => subscription.isActive).length ===
      topics.length,
  );
  const subscribe = () =>
    benchatService
      .activateSubscriptionRevamp(platform.toLowerCase())
      .then(() => {
        setHasSubscribed(true);
        return SUBSCRIPTION_STATUS_TYPE.SUCCESS;
      })
      .catch((error) => {
        switch (error?.response?.data?.type) {
          case SUBSCRIPTION_STATUS_TYPE.USER_WITHOUT_EMAIL_ERROR:
          case SUBSCRIPTION_STATUS_TYPE.USER_WITHOUT_MOBILE_ERROR:
            return error.response.data.type;
          default:
            return SUBSCRIPTION_STATUS_TYPE.UNKNOWN_ERROR;
        }
      });

  const unsubscribe = async () => {
    try {
      if (subscriptionRef.current.length === 0) {
        const response = await benchatService.getSubscriptions(
          userId,
          topics,
          platform,
        );
        subscriptionRef.current = response.data.subscriptions;
      }
      const promises = subscriptionRef.current.map((subscription) =>
        benchatService.deactivateSubscription(subscription._id),
      );
      await Promise.all(promises);
      setHasSubscribed(false);
      return SUBSCRIPTION_STATUS_TYPE.SUCCESS;
    } catch {
      return SUBSCRIPTION_STATUS_TYPE.UNKNOWN_ERROR;
    }
  };

  return {
    hasSubscribed,
    setHasSubscribed,
    subscribe,
    unsubscribe,
  };
};

useGeneralSubscription.propTypes = {
  platform: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }),
  ),
};
export default useGeneralSubscription;
