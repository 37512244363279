import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import { ChakraProvider } from '@chakra-ui/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import SignupLogin from './SignupLogin';
import theme from './theme';
import { AppContextProvider } from './context/appContext';
import { PANEL } from './constants/panel';
import {
  merchantDataPropTypes,
  userSettingPropTypes,
  localeDataPropTypes,
} from './utils/propTypes';
import '../../style/signupLogin/index.scss';
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

const App = ({ defaultPanel = PANEL.LOGIN, localeData, ...restProps }) => {
  const { i18n } = useTranslation();
  const emotionCache = createCache({
    key: 'emotion-css-cache',
    prepend: true, // ensures styles are prepended to the <head>, instead of appended
  });

  useEffect(() => {
    if (i18n.language !== localeData?.loadedLanguage?.code) {
      i18n.changeLanguage(localeData.loadedLanguage.code);
    }
  }, [i18n, localeData.loadedLanguage.code]);

  return (
    <CacheProvider value={emotionCache}>
      <ChakraProvider theme={theme}>
        <AppContextProvider defaultPanel={defaultPanel} {...restProps}>
          <SignupLogin />
        </AppContextProvider>
      </ChakraProvider>
    </CacheProvider>
  );
};

App.propTypes = {
  defaultPanel: PropTypes.oneOf(Object.values(PANEL)),
  localeData: localeDataPropTypes,
  userSetting: userSettingPropTypes,
  merchantData: merchantDataPropTypes,
};

export default App;
