import React from 'react';
import PropTypes from 'prop-types';
import { translateModel } from '../../../../../utils/translate';
import { Button } from '../../../components';
import { Link, Image } from '@chakra-ui/react';

const OAuthApplicationLinks = ({ applications }) => {
  return (
    <>
      {applications?.length > 0 &&
        applications.map(
          ({
            client_id,
            authorize_url,
            redirect_uri,
            application_scopes,
            media_url,
            name_translations,
          }) => {
            const href = `${authorize_url}?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=${application_scopes}`;

            return (
              <Link key={client_id} href={href}>
                <Button isFullWidth variant="outline" mt="24px">
                  <Image src={media_url} boxSize="24px" mr="8px" />
                  {translateModel(name_translations)}
                </Button>
              </Link>
            );
          },
        )}
    </>
  );
};

OAuthApplicationLinks.propTypes = {
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      client_id: PropTypes.string.isRequired,
      authorize_url: PropTypes.string.isRequired,
      redirect_uri: PropTypes.string.isRequired,
      application_scopes: PropTypes.string.isRequired,
      media_url: PropTypes.string,
      name_translations: PropTypes.objectOf(PropTypes.string).isRequired,
    }),
  ).isRequired,
};

export default OAuthApplicationLinks;
