import { useEffect, useRef, useState } from 'react';
import useGeneralSubscription from './useGeneralSubscription';
import { SUBSCRIPTION_STATUS_TYPE } from '../constants/benchat';
import benchatService from '../service/benchatService';
import useCountdownTimer from './useCountdownTimer';

export const DEFAULT_SECONDS = 5;

/**
 * For platforms that need to jump to a third party, resulting in inability to obtain subscription results immediately, such as FB, Line
 * @typedef {object} ReturnValue
 * @property {boolean} hasSubscribed
 * @property {function} startPolling
 * @property {string} subscriptionStatus
 * @property {object} userRef
 * @property {function} unsubscribe
 *
 * @param {object} props
 * @param {string} props.platform
 * @param {array} props.topics
 * @param {array} props.subscriptions
 * @param {string} props.userId
 * @param {number=DEFAULT_SECONDS} props.pollingSeconds
 * @returns {ReturnValue}
 */
const useAsyncSubscription = (props) => {
  const {
    hasSubscribed,
    setHasSubscribed,
    unsubscribe,
  } = useGeneralSubscription(props);
  const { hasFinished, isRunning, timeLeft, reset, start } = useCountdownTimer({
    defaultSeconds: props.pollingSeconds ?? DEFAULT_SECONDS,
  });
  const [subscriptionStatus, setSubscribeStatus] = useState(
    SUBSCRIPTION_STATUS_TYPE.INITIAL,
  );
  const userRef = useRef(benchatService.generateUserRef(props.userId));

  const getSubscribeStatus = async () => {
    try {
      if (hasFinished) {
        stopPolling();
        setSubscribeStatus(SUBSCRIPTION_STATUS_TYPE.UNKNOWN_ERROR);
        return;
      }
      const subscriptionsResponse = await benchatService.getSubscriptions(
        props.userId,
        props.topics,
        props.platform,
      );
      const result = benchatService.getSubscribeStatus(
        subscriptionsResponse.data.subscriptions,
        userRef.current,
      );
      if (result === SUBSCRIPTION_STATUS_TYPE.LOADING) {
        return;
      }
      stopPolling();
      setSubscribeStatus(result);
      setHasSubscribed(result === SUBSCRIPTION_STATUS_TYPE.SUCCESS);
    } catch (error) {
      console.error(error);
    }
  };

  const startPolling = () => {
    setSubscribeStatus(SUBSCRIPTION_STATUS_TYPE.LOADING);
    start();
  };

  const stopPolling = () => {
    userRef.current = benchatService.generateUserRef(props.userId);
    reset();
  };

  const unsubscribeAndUpdateUserRef = async () => {
    const result = await unsubscribe();
    if (result === SUBSCRIPTION_STATUS_TYPE.SUCCESS) {
      userRef.current = benchatService.generateUserRef(props.userId);
    }
    return result;
  };

  useEffect(() => {
    if (isRunning) {
      getSubscribeStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, isRunning]);

  return {
    hasSubscribed,
    startPolling,
    subscriptionStatus,
    userRef,
    unsubscribe: unsubscribeAndUpdateUserRef,
  };
};

useAsyncSubscription.propTypes = useGeneralSubscription.propTypes;

export default useAsyncSubscription;
