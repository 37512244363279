import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash-es';
import clsx from 'classnames';
import {
  Input as ChakraInput,
  InputGroup,
  InputRightElement,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/react';
import { EyeClose, EyeOpen } from '../../../context/Chakra/Icons';
import { FONT_SIZE, COLOR, CLASSNAME } from '../../constants/style';
import { useInput } from '../../hooks/useInput';

export const InputLabel = ({ className, label, isInvalid, ...restProps }) => {
  return (
    <FormLabel
      color={isInvalid ? COLOR.ERROR : '#888'}
      fontSize={FONT_SIZE.INPUT.LABEL}
      marginEnd="0"
      mb="6px"
      className={clsx(
        className,
        isInvalid ? CLASSNAME.CUSTOM_ERROR_TEXT : null,
      )}
      {...restProps}
    >
      {
        label || '\u00a0' // to remain spacing, avoid layout shift
      }
    </FormLabel>
  );
};

InputLabel.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  isInvalid: PropTypes.bool,
};

const BaseInput = (props) => {
  const { className, isInvalid } = props;
  return (
    <ChakraInput
      {...props}
      variant="flushed"
      isInvalid={isInvalid}
      focusBorderColor={isInvalid ? COLOR.ERROR : '#EDEDED'}
      className={clsx(
        className,
        isInvalid ? CLASSNAME.CUSTOM_ERROR_BORDER : '',
      )}
    />
  );
};

BaseInput.propTypes = {
  className: PropTypes.string,
  isInvalid: PropTypes.bool,
};

export const InputErrorMessage = (props) => {
  const { children } = props;
  return (
    <FormErrorMessage
      mt="1px"
      fontSize={FONT_SIZE.INPUT.ERROR_MESSAGE}
      color={COLOR.ERROR}
      className={CLASSNAME.CUSTOM_ERROR_TEXT}
    >
      {children}
    </FormErrorMessage>
  );
};

InputErrorMessage.propTypes = {
  children: PropTypes.node,
};

const Input = (props) => {
  const {
    label = '',
    placeholder,
    errorMessage = '',
    isInvalid = false,
  } = props;
  const { shouldShowLabel, ...useInputProps } = useInput(props);
  const placeholderText = shouldShowLabel ? placeholder : label;

  return (
    <FormControl isInvalid={isInvalid}>
      <InputLabel label={shouldShowLabel ? label : ''} isInvalid={isInvalid} />
      <InputGroup>
        <BaseInput
          {...props}
          {...useInputProps}
          placeholder={placeholderText}
        />
      </InputGroup>
      <InputErrorMessage>{errorMessage}</InputErrorMessage>
    </FormControl>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  isInvalid: PropTypes.bool,
};

const PasswordInput = (props) => {
  const {
    label = '',
    placeholder,
    errorMessage = '',
    value,
    isInvalid,
  } = props;
  const { shouldShowLabel, ...useInputProps } = useInput(props);
  const [isPasswordMasked, setIsPasswordMasked] = useState(true);
  const placeholderText = shouldShowLabel ? placeholder : label;

  return (
    <FormControl isInvalid={isInvalid}>
      <InputLabel label={shouldShowLabel ? label : ''} isInvalid={isInvalid} />
      <InputGroup>
        <BaseInput
          {...props}
          {...useInputProps}
          type={isPasswordMasked ? 'password' : 'text'}
          letterSpacing={!isEmpty(value) && isPasswordMasked ? '6px' : '0px'}
          placeholder={placeholderText}
        />
        <InputRightElement
          onClick={() => setIsPasswordMasked((prev) => !prev)}
          cursor="pointer"
          paddingBottom="12px"
        >
          {isPasswordMasked ? (
            <EyeClose
              boxSize="20px"
              className={CLASSNAME.ICON_PASSWORD_VISIBILITY}
            />
          ) : (
            <EyeOpen
              boxSize="20px"
              className={CLASSNAME.ICON_PASSWORD_VISIBILITY}
            />
          )}
        </InputRightElement>
      </InputGroup>
      <InputErrorMessage>{errorMessage}</InputErrorMessage>
    </FormControl>
  );
};

PasswordInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errorMessage: PropTypes.string,
  isInvalid: PropTypes.bool,
  value: PropTypes.string,
};

Input.PasswordInput = PasswordInput;

export default Input;
