import React, { useCallback, useState, useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Link, Center, HStack, Text } from '@chakra-ui/react';
import { RECAPTCHA_TOKEN_FIELD } from '../../../../../constants/recaptchable';
import authenticationService from '../../../../../service/authenticationService';
import { getInputFieldProps as _getInputFieldProps } from '../../../utils/propsBuilder';
import extractServerErrorMessages from '../../../utils/extractServerErrorMessages';
import { PANEL } from '../../../constants/panel';
import { useAppContext } from '../../../context/appContext';
import { useFlash, useRecaptcha } from '../../../hooks';
import {
  Button,
  Input,
  TitleText,
  ErrorBanner,
  RecaptchaCheckbox,
} from '../../../components';
import {
  FacebookAccount,
  LineAccount,
  OAuthApplicationLinks,
} from '../../components';
import { QUOTA_LIMIT_ERROR_TYPE } from '../../../../../shop/constants/quota_limit';
import pnotifyService from '../../../../../service/pnotifyService';
import {
  EVENT_CATEGORY_FOR_USER_FLOW,
  VALUE_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
  EVENT_NAME_FOR_USER_FLOW,
} from '../../../../../shop/constants/signupLoginEventsTracking';
import regex from '../../../../../shop/constants/regex';
import trackerService from '../../../../../service/trackerService';

const RESET_PASSWORD_PATH = '/users/password/new';

export default function LoginForm() {
  const { t } = useTranslation();
  const {
    setCurrentPanel,
    defaultAccount,
    isRecaptchaEnabled,
    isFacebookLoginEnabled,
    isLineLoginEnabled,
    customerAuthenticationOauthApplications,
  } = useAppContext();
  const {
    errors,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useFormContext();
  const [errorMessages, setErrorMessages] = useState([]);

  const i18nKey = {
    label: 'Login - email or mobile phone - label',
    placeholder: 'Login - email or mobile phone - placeholder',
  };

  const { field: emailAndMobileField } = useController({
    name: 'emailAndMobile',
    control,
    defaultValue: defaultAccount?.email || defaultAccount?.mobile_phone || null,
  });
  const { field: passwordField } = useController({
    name: 'password',
    control,
    defaultValue: '',
  });

  const onSubmit = async (data) => {
    setErrorMessages([]);
    const { password, emailAndMobile } = data;

    try {
      const urlQueries = new URLSearchParams(window.location.search);
      const encryptData = urlQueries.get('encrypt_data');
      const payload = {
        user: {
          password,
          mobile_phone_or_email: emailAndMobile,
        },
        encrypt_data: encryptData,
      };
      if (isRecaptchaEnabled) {
        const token = await getRecaptchaToken();
        payload[RECAPTCHA_TOKEN_FIELD] = token;
        if (recaptchaCheckboxToken) {
          payload['is_use_checkbox'] = true;
        }
        refReCAPTCHA.current?.reset();
        if (isShowCheckboxRecaptcha) {
          setIsShowCheckboxRecaptcha(false);
        }
      }
      const result = await authenticationService.signIn(payload);

      const loginMethod = regex.EMAIL_REGEX.test(emailAndMobile)
        ? VALUE_FOR_USER_FLOW.email
        : VALUE_FOR_USER_FLOW.mobile;

      await trackerService.track({
        type: EVENT_NAME_FOR_USER_FLOW.MemberLoginSucceed,
        data: {
          event_category: EVENT_CATEGORY_FOR_USER_FLOW.MemberLogin,
          property: PROPERTY_FOR_USER_FLOW.login_option,
          value: loginMethod,
        },
      });

      const { redirect_to } = result.data;
      window.location.href = redirect_to;
    } catch (error) {
      const data = error?.response?.data ?? {};
      let input = data;
      if (
        data.error_code === QUOTA_LIMIT_ERROR_TYPE.TRIAL_EMAIL_QUOTA_EXCEEDED
      ) {
        return pnotifyService.notify(
          t('Verification - email - trial merchant - reach limit - failure'),
          {
            customClass: 'error',
            icon: 'fa fa-exclamation-triangle',
          },
        );
      } else if (isRecaptchaError({ errorCode: data?.error_code })) {
        refCheckboxReCAPTCHA.current?.reset();
        setIsShowCheckboxRecaptcha(true);
      } else {
        if (data.unconfirmed_email && !data.reached_rate_limit) {
          input = {
            error_messages: [
              t('Login - Error - unconfirmed email', {
                email: data.unconfirmed_email,
              }),
            ],
          };
        }
        setErrorMessages(extractServerErrorMessages(input));
      }
    }
  };

  const getInputFieldProps = useCallback(
    ({ field, error }) => _getInputFieldProps({ field, error, t }),
    [t],
  );

  useFlash((flash) => {
    if (typeof flash?.alert === 'string' && flash.alert) {
      setErrorMessages(
        extractServerErrorMessages({
          error_messages: [flash.alert],
        }),
      );
    }
  });

  const getThirdPartyOptions = (isFacebookLoginEnabled, isLineLoginEnabled) => {
    return [
      ...(isFacebookLoginEnabled ? [VALUE_FOR_USER_FLOW.facebook] : []),
      ...(isLineLoginEnabled ? [VALUE_FOR_USER_FLOW.line] : []),
    ];
  };

  useEffect(() => {
    trackerService.track({
      type: EVENT_NAME_FOR_USER_FLOW.MemberLoginFormShow,
      data: {
        event_category: EVENT_CATEGORY_FOR_USER_FLOW.MemberLogin,
        property: PROPERTY_FOR_USER_FLOW.third_party_login_option,
        value: getThirdPartyOptions(isFacebookLoginEnabled, isLineLoginEnabled),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    refReCAPTCHA,
    refCheckboxReCAPTCHA,
    recaptchaCheckboxToken,
    isRecaptchaError,
    getRecaptchaToken,
    isShowCheckboxRecaptcha,
    setIsShowCheckboxRecaptcha,
    onRecaptchaCheckboxCallback,
  } = useRecaptcha({ callback: handleSubmit(onSubmit) });

  return (
    <>
      <Box mb="72px">
        <TitleText mb="30px">{t('Sign In')}</TitleText>
        <ErrorBanner errorMessages={errorMessages} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb="10px">
            <Input
              data-e2e-id="login-email_input"
              label={t(i18nKey.label)}
              placeholder={t(i18nKey.placeholder)}
              {...getInputFieldProps({
                field: emailAndMobileField,
                error: errors?.emailAndMobile,
              })}
            />
          </Box>
          <Box mb="10px">
            <Input.PasswordInput
              data-e2e-id="login-password_input"
              label={t('Password')}
              placeholder={t('Password (8 characters minimum)')}
              {...getInputFieldProps({
                field: passwordField,
                error: errors?.password,
              })}
            />
          </Box>
          <Box my="20px">
            <Link href={RESET_PASSWORD_PATH} fontSize="1.2rem" color="#3493FB">
              {t('Forgot Password')}
            </Link>
          </Box>
          <RecaptchaCheckbox
            refReCAPTCHA={refReCAPTCHA}
            refCheckboxReCAPTCHA={refCheckboxReCAPTCHA}
            isShowCheckboxRecaptcha={isShowCheckboxRecaptcha}
            onRecaptchaCheckboxCallback={onRecaptchaCheckboxCallback}
          />
          <Button
            data-e2e-id="login-submit_button"
            isFullWidth
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
          >
            {t('Lets go')}
          </Button>
          <OAuthApplicationLinks
            applications={customerAuthenticationOauthApplications}
          />
          {(isFacebookLoginEnabled || isLineLoginEnabled) && (
            <Box mt="24px">
              <Text
                fontSize="12px"
                fontWeight={500}
                color="#272D36"
                textAlign="center"
                mb="8px !important"
              >
                {t('login in with social account')}
              </Text>
              <Center>
                <HStack spacing="12px">
                  {isFacebookLoginEnabled && (
                    <FacebookAccount
                      e2eId="login-facebook_icon"
                      currentPanel={PANEL.LOGIN}
                    />
                  )}
                  {isLineLoginEnabled && (
                    <LineAccount e2eId="login-line_icon" />
                  )}
                </HStack>
              </Center>
            </Box>
          )}
        </form>
      </Box>
      {/* @TODO: add third party auth */}
      <TitleText mb="24px">{t('Not a member')}</TitleText>
      <Button
        isFullWidth
        onClick={() => setCurrentPanel(PANEL.SIGN_UP)}
        variant="outline"
      >
        {t('Sign Up')}
      </Button>
    </>
  );
}
