import { useState, useEffect, useRef } from 'react';

const useCountdownTimer = ({ defaultSeconds }) => {
  const [timeLeft, setTimeLeft] = useState(defaultSeconds);
  const [isRunning, setIsRunning] = useState(false);
  const timerId = useRef(null);

  useEffect(() => {
    if (timeLeft === 0) {
      setIsRunning(false);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (!isRunning || timeLeft === 0) return;
    timerId.current = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);
    return () => {
      clearInterval(timerId.current);
    };
  }, [timeLeft, isRunning]);

  return {
    timeLeft,
    isRunning,
    hasFinished: timeLeft === 0,
    setTimeLeft,
    start: () => {
      setIsRunning(true);
      setTimeLeft(defaultSeconds);
    },
    reset: () => {
      setIsRunning(false);
      setTimeLeft(defaultSeconds);
    },
  };
};

export default useCountdownTimer;
