import axios from 'axios';

const addressFormatService = {
  getAddressPreference({ country, scope }) {
    return axios.get(`/api/address_preferences`, {
      params: {
        country,
        scope,
      },
    });
  },
  getAddressNode({
    country,
    with_children,
    nodeCodes,
    deliveryMethodId,
    withAllChildren,
  }) {
    const params = {
      address_node_id: nodeCodes,
      with_children,
      delivery_option_id: deliveryMethodId,
    };
    if (withAllChildren) {
      Object.assign(params, {
        'with_all_children[]': country,
        with_restrict_level: 3,
      });
    }
    return axios.get(`/api/address_nodes?countries[]=${country}`, {
      params,
    });
  },
  getCountries: (merchantId) =>
    axios.get(`/api/merchants/${merchantId}/countries`),
};

export default addressFormatService;
