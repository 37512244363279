import React from 'react';
import { Text } from '@chakra-ui/react';

const TitleText = (props) => (
  <Text
    fontSize="32px"
    lineHeight="45px"
    fontWeight="bold"
    color="#272d36"
    className="title"
    {...props}
  />
);

export default TitleText;
