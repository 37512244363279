import { useEffect, useRef, useState } from 'react';
import { RECAPTCHA_VERIFICATION_FAILED } from '../../../constants/recaptchable';
import { useAppContext } from '../context/appContext';

const useRecaptcha = ({ callback }) => {
  const { isRecaptchaEnterprise } = useAppContext();
  const refReCAPTCHA = useRef();
  const refCheckboxReCAPTCHA = useRef();
  const [recaptchaCheckboxToken, setRecaptchaCheckboxToken] = useState('');

  const isRecaptchaError = ({ errorCode }) =>
    errorCode === RECAPTCHA_VERIFICATION_FAILED &&
    !recaptchaCheckboxToken &&
    isRecaptchaEnterprise;

  const getRecaptchaToken = async () =>
    recaptchaCheckboxToken || (await refReCAPTCHA.current?.executeAsync());

  const getRecaptchaCheckboxPayload = () =>
    recaptchaCheckboxToken ? { is_use_checkbox: true } : {};

  const [isShowCheckboxRecaptcha, setIsShowCheckboxRecaptcha] = useState(false);

  const onRecaptchaCheckboxCallback = (token) => {
    setRecaptchaCheckboxToken(token);
  };

  useEffect(() => {
    if (recaptchaCheckboxToken) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recaptchaCheckboxToken]);

  return {
    refReCAPTCHA,
    refCheckboxReCAPTCHA,
    recaptchaCheckboxToken,
    setRecaptchaCheckboxToken,
    isRecaptchaError,
    getRecaptchaCheckboxPayload,
    getRecaptchaToken,
    isShowCheckboxRecaptcha,
    setIsShowCheckboxRecaptcha,
    onRecaptchaCheckboxCallback,
  };
};

export default useRecaptcha;
