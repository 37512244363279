import axios from 'axios';
import { pick } from 'lodash-es';
import { RECAPTCHA_TOKEN_FIELD } from '../constants/recaptchable';

const authenticationService = {
  checkRegisterStatus: (params) => {
    /*
    expected response
    {
      "email": "new/guest/member", optional
      "mobile": "new/guest/member" optional
    }
    **/
    return axios.get('/api/users/register_status', { params });
  },
  registerValidation: (payload) => {
    return axios.post('/api/users/register_validation', payload);
  },
  createUser: (payload) => {
    return axios.post('/api/users', payload);
  },
  sendSignUpCode: (payload) => {
    return axios.post(
      '/api/mobile_phone_verifications/send_sign_up_code',
      pick(payload, [
        'country_calling_code',
        'mobile_phone',
        'is_use_checkbox',
        RECAPTCHA_TOKEN_FIELD,
      ]),
    );
  },
  sendMobileChangeCode: (payload) => {
    return axios.post(
      '/api/mobile_phone_verifications/send_mobile_change_code',
      pick(payload, [
        'country_calling_code',
        'mobile_phone',
        'is_use_checkbox',
        RECAPTCHA_TOKEN_FIELD,
      ]),
    );
  },
  verifyCode: (payload) => {
    return axios.get('/api/mobile_phone_verifications/verify_code', {
      params: pick(payload, ['country_calling_code', 'mobile_phone', 'code']),
    });
  },
  signIn: (payload) => axios.post('/api/users/sign_in', payload),
  updateUser: (userId, payload) => axios.put(`/api/users/${userId}`, payload),
  getUser: (userId, params = {}, config = {}) =>
    axios.get(
      `/api/users/${userId}`,
      {
        params: {
          includes: ['membership_tier_gap', 'member_info_reward'],
          ...params,
        },
      },
      {
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        ...config,
      },
    ),
  sendVerificationEmail: () => axios.post('/api/users/send_verification_email'),
  checkUserExistenceByPhone: (params) =>
    axios.get('/api/users/exist', {
      params: {
        country_calling_code: params.countryCallingCode,
        mobile_phone: params.mobilePhone,
      },
    }),
};

export default authenticationService;
