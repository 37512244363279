export default {
  translation: {
    'Validation - field - required': 'กรอก {{ fieldName }} ให้ครบถ้วน',
    'Sign Up': 'สมัครใช้งาน',
    'Mobile Number': 'หมายเลขโทรศัพท์',
    Email: 'อีเมล',
    'Enter your email': 'กรอกอีเมล',
    'Accept to receive store updates':
      'ฉันตกลงที่จะรับอัปเดต, โปรโมชัน, และข้อมูลการตลาดต่างๆจากร้าน {{ shopName }}',
    'Terms of Use and Privacy Policy':
      'ฉันยอมรับ <0>เงื่อนไขบริการ</0> และ <2>นโยบายความเป็นส่วนตัว</2>ของร้านค้า',
    'Terms of Use and Privacy Policy and Age Policy':
      "I agree to the store's <0>Term of Use</0> and <2>Privacy Policy</2> and I am over {{ age }}",
    Next: 'ต่อไป',
    'Already a member': 'เป็นสมาชิกอยู่แล้ว?',
    'Sign in to enjoy more benefits': 'เข้าสู่ระบบเพื่อรับสิทธิประโยชน์มากมาย!',
    'Validation - email - required': 'จำเป็นต้องมีอีเมล',
    'Validation - email - invalid': 'อีเมลไม่ถูกต้อง',
    'Validation - email - max': 'อีเมลยาวเกินไป',
    'Validation - mobile phone - required': 'จำเป็นต้องมีหมายเลขโทรศัพท์',
    'Validation - mobile phone - invalid': 'หมายเลขโทรศัพท์ไม่ถูกต้อง',
    'Validation - terms and policy - required':
      'คุณต้องยอมรับต่อข้อกำหนดและเงื่อนไขเพื่อดำเนินการต่อ',
    'Continue Sign up': 'สมัครใช้งาน',
    'Sign In': 'เข้าสู่ระบบ',
    'Register Status - result - email belongs to guest':
      'อีเมลนี้เคยใช้ในร้านค้าก่อนหน้านี้ คุณจะพบคำแนะนำเกี่ยวกับการตั้งค่าบัญชีในกล่องจดหมายของคุณ',
    'Register Status - result - email belongs to member':
      'อีเมลนี้ได้ลงทะเบียนไว้แล้ว ไปที่หน้าเข้าสู่ระบบหรือใช้อีเมลอื่นเพื่อสมัครใช้งาน',
    'Register Status - result - mobile phone belongs to guest':
      'เบอร์มือถือนี้เคยมีกิจกรรมกับร้านนี้แล้วคำแนะนำในการตั้งค่าบัญชีของคุณได้ถูกส่งไปยังมือถือของคุณแล้ว',
    'Register Status - result - mobile phone belongs to member':
      'หมายเลขโทรศัพท์นี้ได้ลงทะเบียนไว้แล้ว ไปที่หน้าเข้าสู่ระบบหรือใช้หมายเลขอื่นเพื่อสมัครใช้งาน',
    'Register Status - result - email and mobile phone both not allowed':
      'อีเมลและหมายเลขโทรศัพท์นี้ได้ลงทะเบียนแยกกันไว้; กรุณาไปที่หน้าเข้าสู่ระบบ',
    'Register Status - result - email is taken':
      'อีเมลนี้ถูกใช้โดยสมาชิกรายอื่น',
    'Register Status - result - mobile phone is taken':
      'สมาชิกคนอื่นใช้โทรศัพท์มือถือ',
    'Verify mobile number': 'ยืนยันหมายเลขโทรศัพท์',
    'Verification code': 'รหัสยืนยัน',
    'Enter verification code': 'กรอกรหัสยืนยัน',
    'Send verification code': 'ส่งรหัสยืนยัน',
    'Resend Verification Code': 'ส่งรหัสยืนยันอีกครั้ง',
    'Verification code will be sent after you pass reCAPTCHA':
      'Verification code will be sent after you pass reCAPTCHA',
    'Verification code has been sent to':
      'Verification code has been sent to +{{countryCode}} {{phoneNumber}}',
    'Seconds left': '{{ seconds }} วินาที',
    Back: 'ย้อนกลับ',
    'Validation - pincode - required': 'จำเป็นต้องมีรหัสยืนยัน',
    'Validation - pincode - max length':
      'รหัสต้องมีความยาว {{ maxLength }} ตัวอักษร',
    'Validation - pincode - invalid': 'รหัสไม่ถูกต้อง',
    'Last Step': 'ขั้นตอนสุดท้าย',
    'Please enter your profile':
      'กรุณากรอกข้อมูลของคุณเพื่อลงทะเบียนให้เสร็จสมบูรณ์',
    Username: 'ขื่อบัญชีผู้ใช้ (Username)',
    'Enter your username': 'กรอกชื่อผู้ใช้',
    Password: 'รหัสผ่าน',
    'Password(at least 8 characters)': 'รหัสผ่าน (อย่างน้อย 8 ตัวอักษร)',
    Gender: 'เพศ',
    'Gender - male': 'ชาย',
    'Gender - female': 'หญิง',
    'Gender - undisclosed': 'ไม่เปิดเผย',
    Birthday: 'วันเกิด',
    'Birthday - year': 'YYYY',
    'Birthday - month': 'MM',
    'Birthday - date': 'DD',
    'Validation - birthday - invalid': 'วันเกิด ไม่ถูกต้อง',
    'Validation - birthday - min age':
      'วันเกิดไม่ถูกต้อง ควรมีอายุมากกว่า {{ age }} ปี',
    'Validation - birthday - required': 'จำเป็นต้องมีวันเกิด',
    Optional: '(ไม่จำเป็น)',
    'Sign me up': 'สมัครใช้งานเลย!',
    'Validation - username - required': 'จำเป็นต้องมีชื่อเต็ม',
    'Validation - username - too long': 'ชื่อ-นามสกุล ยาวเกินไป',
    'Validation - password - required': 'ต้องมีรหัสผ่าน',
    'Validation - password - too short': 'รหัสผ่านสั้นเกินไป',
    'Validation - password - too long': 'รหัสผ่านยาวเกินไป',
    'Email or Mobile Number': 'อีเมลหรือหมายเลขโทรศัพท์มือถือ',
    'Password (8 characters minimum)': 'รหัสผ่าน (อย่างน้อย 8 ตัวอักษร)',
    'Forgot Password': 'ลืมรหัสผ่าน?',
    'Lets go': 'เข้าสู่ระบบ',
    'Not a member': 'ไม่ได้เป็นสมาชิก?',
    'Validation - email and mobile - required':
      'จำเป็นต้องใส่อีเมลหรือหมายเลขโทรศัพท์',
    'Validation - email and mobile - invalid':
      'อีเมลหรือหมายเลขโทรศัพท์ไม่ถูกต้อง',
    'Sign up Success': 'ขอบคุณค่ะ! การลงทะเบียนบัญชีใหม่ของคุณสำเร็จแล้ว',
    'Complete member info': 'ใส่ข้อมูลสมาชิกให้เสร็จสิ้น',
    'Complete Phone Number Notice':
      'ใส่หมายเลขโทรศัพท์เพื่อรับข่าวสารแคมเปญล่าสุดจากร้านค้า',
    'Complete Email Notice': 'ใส่อีเมลรับข่าวสารแคมเปญล่าสุดจากร้านค้า',
    Complete: 'Complete',
    Skip: 'ข้าม',
    'sign up with social account': 'หรือสมัครด้วยบัญชีโซเชียล',
    'login in with social account': 'ใช้บัญชีโซเชียล',
    'Login - email - label': 'อีเมล',
    'Login - email - placeholder': 'กรอกอีเมล',
    'Login - mobile phone - label': 'หมายเลขโทรศัพท์มือถือ',
    'Login - mobile phone - placeholder': 'กรอกหมายเลขโทรศัพท์',
    'Login - email or mobile phone - label': 'อีเมลหรือหมายเลขโทรศัพท์',
    'Login - email or mobile phone - placeholder':
      'กรอกอีเมลหรือหมายเลขโทรศัพท์',
    'Login - Error - unconfirmed email':
      'ยินดีต้อนรับกลับมาค่ะ! เพื่อความปลอดภัยของบัญชี คุณต้องยืนยันตัวตนเพื่อเข้าสู่ระบบ อีเมลยืนยันได้ถูกส่งไปยัง {{email}} กรุณาตรวจสอบในกล่องจดหมายของคุณ ขอบคุณค่ะ!',
    'Member info has been updated': 'อัปเดตข้อมูลสมาชิกแล้ว',
    'Subscription - description':
      'สมัครรับอัปเดตและข่าวสารการตลาดผ่านช่องทางโซเชียลมีเดีย',
    'Subscription - LINE - subscribe': 'สมัครด้วย LINE',
    'Subscription - connection notification - success': 'สมัครสำเร็จ',
    'Subscription - LINE - connection notification - failure':
      'การสมัครล้มเหลว กรุณาเข้าสู่ระบบ LINE อีกครั้ง',
    'Verification - email - trial merchant - reach limit - failure':
      'ไม่สามารถส่งอีเมลยืนยันได้ระหว่างการทดลองใช้งานของร้านค้า หากต้องการใช้งาน โปรดติดต่อร้านค้าเพื่อขอความช่วยเหลือ ขอบคุณ',
    'Subscription - FACEBOOK - connection notification - failure':
      'การสมัครล้มเหลว กรุณาเข้าสู่ระบบ Facebook อีกครั้ง',
  },
};
