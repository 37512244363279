import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Link = createIcon({
  displayName: 'Link',
  viewBox: '0 0 14 14',
  path: (
    <path
      d="M12.6831 7.023C13.919 5.78707 13.919 3.7855 12.6831 2.54957C11.5893 1.45582 9.86559 1.31363 8.60778 2.21269L8.57278 2.23675C8.25778 2.46207 8.1856 2.89957 8.41091 3.21238C8.63622 3.52519 9.07372 3.59957 9.38653 3.37425L9.42153 3.35019C10.1237 2.84925 11.084 2.928 11.6922 3.53832C12.3812 4.22738 12.3812 5.343 11.6922 6.03207L9.23778 8.49082C8.54872 9.17988 7.43309 9.17988 6.74403 8.49082C6.13372 7.8805 6.05497 6.92019 6.55591 6.22019L6.57997 6.18519C6.80528 5.87019 6.73091 5.43269 6.4181 5.20957C6.10528 4.98644 5.6656 5.05863 5.44247 5.37144L5.41841 5.40644C4.51716 6.66207 4.65935 8.38582 5.7531 9.47957C6.98903 10.7155 8.99059 10.7155 10.2265 9.47957L12.6831 7.023ZM1.31685 6.51113C0.0809082 7.74707 0.0809082 9.74863 1.31685 10.9846C2.4106 12.0783 4.13435 12.2205 5.39216 11.3214L5.42716 11.2974C5.74216 11.0721 5.81435 10.6346 5.58903 10.3218C5.36372 10.0089 4.92622 9.93457 4.61341 10.1599L4.57841 10.1839C3.87622 10.6849 2.91591 10.6061 2.30778 9.99582C1.61872 9.30457 1.61872 8.18894 2.30778 7.49988L4.76216 5.04332C5.45122 4.35425 6.56685 4.35425 7.25591 5.04332C7.86622 5.65363 7.94497 6.61394 7.44403 7.31613L7.41997 7.35113C7.19466 7.66613 7.26903 8.10363 7.58185 8.32675C7.89466 8.54988 8.33435 8.47769 8.55747 8.16488L8.58153 8.12988C9.48278 6.87207 9.3406 5.14832 8.24685 4.05457C7.01091 2.81863 5.00935 2.81863 3.77341 4.05457L1.31685 6.51113Z"
      fill="#currentColor"
    />
  ),
});

export default Link;
