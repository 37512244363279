import { getColor } from '@chakra-ui/theme-tools';
import { COLOR } from '../constants/style';

const colors = {
  red: {
    alert_bg: '#FCEFED',
    alert_border: '#FED6D1',
    alert_text: COLOR.ERROR,
  },
  green: {
    alert_bg: '#DFF0D8',
    alert_border: '#D6E9C6',
    alert_text: '#3C763D',
  },
};

const variantSubtle = (props) => {
  const { theme, colorScheme: c } = props;
  return {
    container: {
      bg: getColor(theme, `${c}.alert_bg`, `${c}.100`),
      color: getColor(theme, `${c}.alert_text`, `${c}.500`),
      borderColor: getColor(theme, `${c}.alert_border`, `${c}.200`),
    },
  };
};

const Alert = {
  baseStyle: {
    container: {
      padding: '15px',
      borderWidth: '1px',
      borderRadius: '4px',
      fontSize: '1.4rem',
      fontWeight: '400',
    },
  },
  variants: {
    subtle: variantSubtle,
  },
};

export { colors };

export default Alert;
