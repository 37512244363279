const _disabled = {
  backgroundColor: '#1E1E1E',
  borderColor: '#1E1E1E',
  color: '#666666',
};

const Button = {
  sizes: {
    sm: {
      fontSize: '1.2rem',
      height: '36px',
      paddingX: '10px',
    },
    md: {
      fontSize: '1.6rem',
      height: '46px',
      paddingX: '12px',
    },
  },
  baseStyle: {
    borderRadius: '4px',
    _disabled: {
      opacity: 1,
    },
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  variants: {
    solid: {
      color: '#fff',
      backgroundColor: '#272d36',
      borderWidth: '1px',
      borderColor: '#272d36',
      _hover: {
        color: '#272d36',
        backgroundColor: 'transparent',
        borderColor: '#272d36',
        _disabled,
      },
      _disabled,
      _loading: {
        _disabled: {
          color: '#fff',
          backgroundColor: '#272d36',
          borderColor: '#272d36',
        },
      },
    },
    outline: {
      color: '#272d36',
      backgroundColor: 'transparent',
      borderColor: '#272d36',
      _hover: {
        backgroundColor: '#c4cad433',
        _disabled,
      },
      _disabled,
      _loading: {
        _disabled: {
          color: '#272d36',
          backgroundColor: 'transparent',
          borderColor: '#272d36',
        },
      },
    },
    link: {
      color: '#3493FB',
      _hover: {
        color: '#034996',
      },
    },
    solidWhite: {
      borderWidth: '1px',
      color: '#000',
      backgroundColor: '#FFF',
      borderColor: '#FFF',
      _hover: {
        color: '#FFF',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderColor: '#FFF',
      },
      _loading: {
        color: '#000 !important',
        backgroundColor: '#FFF !important',
        borderColor: '#FFF !important',
      },
    },
    outlineWhite: {
      borderWidth: '1px',
      backgroundColor: 'transparent',
      borderColor: '#FFF',
      color: '#FFF',
      _hover: {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
      },
    },
    // Fallback to outline, the original style of Send Code Button
    outlineSendCode: {
      borderWidth: '1px',
      backgroundColor: 'transparent',
      borderColor: '#272d36',
      color: '#272d36',
      _hover: {
        backgroundColor: '#c4cad433',
        _disabled,
      },
      _disabled,
      _loading: {
        _disabled: {
          backgroundColor: 'transparent',
          borderColor: '#272d36',
          color: '#272d36',
        },
      },
    },
  },
};

export default Button;
