import { flow, isString, compact } from 'lodash-es';

const HTML_BR_TAG = '<br>';
const REGEX_NEWLINE_BREAKER = new RegExp(`${HTML_BR_TAG}|\n`);

/**
 * Extract error message from variable error responses from backend
 * @param {*} responseData
 * @returns {string[]}
 */
export function getErrorMessagesFromResponseData(responseData = {}) {
  const errorMessages =
    responseData?.error_messages ??
    responseData?.error ??
    responseData?.message;
  if (Array.isArray(errorMessages)) {
    return errorMessages;
  } else if (isString(errorMessages)) {
    return [errorMessages];
  }
  return [JSON.stringify(responseData)];
}

/**
 * Break the strings in Array of error messages and flat the Array
 * @param {string[]} errorMessages
 * @returns {string[][]}
 */
export function breakNewlines(errorMessages = []) {
  return errorMessages.map((message) => message.split(REGEX_NEWLINE_BREAKER));
}

/**
 * Trim the paddings in the strings in Array
 * @param {(string | string[])[]} errorMessages
 * @returns {string[]}
 */
export function trimPaddings(errorMessages = []) {
  return errorMessages.map((message) => {
    if (Array.isArray(message)) {
      return trimPaddings(message);
    } else if (isString(message)) {
      return message.trim();
    }
    return message;
  });
}

export default flow(
  getErrorMessagesFromResponseData,
  compact,
  breakNewlines,
  trimPaddings,
);
