import React from 'react';
import { Text } from '@chakra-ui/react';
import { COLOR, FONT_SIZE } from '../../constants/style';

const ErrorText = (props) => (
  <Text
    fontSize={FONT_SIZE.INPUT.ERROR_MESSAGE}
    lineHeight="1.4rem"
    color={COLOR.ERROR}
    mt="1px"
    className="chakra-error_message"
    {...props}
  />
);

export default ErrorText;
