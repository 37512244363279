import React from 'react';
import { createIcon } from '@chakra-ui/react';

const MemberCard = createIcon({
  displayName: 'MemberCard',
  viewBox: '0 0 20 20',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.664 13.2825L11.872 10.2225L14.32 8.18848L11.17 8.00848L10 5.05648L8.84804 8.00848L5.64405 8.18848L8.14605 10.2225L7.33605 13.2825L10 11.5545L12.664 13.2825ZM16.012 9.70048C16.012 10.1085 16.156 10.4625 16.444 10.7625C16.732 11.0625 17.08 11.2125 17.488 11.2125V14.2005C17.488 14.6085 17.344 14.9625 17.056 15.2625C16.768 15.5625 16.42 15.7125 16.012 15.7125H3.98805C3.58005 15.7125 3.23205 15.5625 2.94405 15.2625C2.80516 15.123 2.69544 14.9572 2.62127 14.7749C2.5471 14.5926 2.50997 14.3973 2.51205 14.2005V11.2125C2.93205 11.2125 3.28305 11.0625 3.56505 10.7625C3.8407 10.4782 3.99275 10.0964 3.98805 9.70048C3.98805 9.29248 3.84405 8.93848 3.55605 8.63848C3.26805 8.33848 2.92005 8.18848 2.51205 8.18848V5.20048C2.51205 4.79248 2.65605 4.43848 2.94405 4.13848C3.23205 3.83848 3.58005 3.68848 3.98805 3.68848H16.012C16.42 3.68848 16.768 3.83848 17.056 4.13848C17.344 4.43848 17.488 4.79248 17.488 5.20048V8.18848C17.08 8.18848 16.732 8.33848 16.444 8.63848C16.156 8.93848 16.012 9.29248 16.012 9.70048Z"
      fill="currentColor"
    />
  ),
});

export default MemberCard;
