/**
 * From GitHub repo Yoctol/react-messenger-send-to-messenger
 * For attribute 'size' cannot be assigned to div in React
 * @see {@link https://github.com/Yoctol/react-messenger-send-to-messenger} Yoctol/react-messenger-send-to-messenger
 * @see {@link https://github.com/Yoctol/react-messenger-send-to-messenger/blob/ac05f8c51b1a3bd9b5bf0b40176368537cd10d9f/src/MessengerSendToMessenger.js#L44-L70}
 */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

function FacebookComponent(
  { pageId, appId, dataRef, color, size, enforceLogin, ctaText, origin },
  ref,
) {
  const dataRefAttribute = dataRef !== undefined ? `data-ref="${dataRef}"` : '';

  const ctaTextAttribute = ctaText !== undefined ? `cta_text="${ctaText}"` : '';

  return (
    <div
      ref={ref}
      dangerouslySetInnerHTML={{
        __html: `<div
          class="fb-send-to-messenger"
          page_id="${pageId}"
          messenger_app_id="${appId}"
          color="${color}"
          size="${size}"
          enforce_login="${enforceLogin}"
          origin="${origin}"
          ${dataRefAttribute}
          ${ctaTextAttribute}
        ></div>`,
      }}
    />
  );
}

FacebookComponent.propTypes = {
  pageId: PropTypes.string,
  appId: PropTypes.string,
  dataRef: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  enforceLogin: PropTypes.bool,
  ctaText: PropTypes.string,
  origin: PropTypes.string,
};

export default forwardRef(FacebookComponent);
