import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { ReCAPTCHA } from 'react-google-recaptcha';
import { Box, Center, HStack, Text } from '@chakra-ui/react';
import { getCountryCallingCode } from 'libphonenumber-js/min';
import { isPlainObject, isEmpty, values } from 'lodash-es';
import { RECAPTCHA_TOKEN_FIELD } from '../../../../../constants/recaptchable';
import { ALL_SUPPORTED_COUNTRIES_STRING } from '../../../../../constants/userSetting';
import { useCountdownTimer } from '../../../../../hooks';
import authenticationService from '../../../../../service/authenticationService';
import pnotifyService from '../../../../../service/pnotifyService';
import trackerService from '../../../../../service/trackerService';
import { checkIsRegistrationExisted } from '../../../utils/checkRegisterStatus';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import { getInputFieldProps } from '../../../utils/propsBuilder';
import extractServerErrorMessages from '../../../utils/extractServerErrorMessages';
import {
  PINCODE_COUNT_DOWN_TIME,
  PINCODE_LENGTH,
} from '../../../constants/pincode';
import {
  registerStatus,
  registrationErrorMessageMap,
} from '../../../constants/registerStatus';
import { CLASSNAME } from '../../../constants/style';
import {
  Input,
  MobilePhoneInput,
  TitleText,
  Button,
  ErrorBanner,
  ErrorText,
  RecaptchaCheckbox,
} from '../../../components';
import { useAppContext } from '../../../context/appContext';
import { useRecaptcha } from '../../../hooks';
import {
  EVENT_CATEGORY_FOR_USER_FLOW,
  VALUE_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
  EVENT_NAME_FOR_USER_FLOW,
} from '../../../../../shop/constants/signupLoginEventsTracking';

const VERIFY_CODE_FAILED = 'verifyCode failed';
const CHECK_REGISTER_STATUS_FAILED = 'checkRegisterStatus failed';
const VERIFY_ERRORS = [VERIFY_CODE_FAILED, CHECK_REGISTER_STATUS_FAILED];

const MobileForm = () => {
  const { t } = useTranslation();
  const {
    merchantData,
    userSetting,
    smsVerificationEnabled,
    redirectTo,
    grecaptcha,
    isRecaptchaEnabled,
    recaptchaSiteKey,
    currentUser,
  } = useAppContext();
  const {
    errors,
    control,
    formState,
    trigger,
    handleSubmit,
    setError,
  } = useFormContext();
  const supportedCountries =
    userSetting?.sms_verification?.supported_countries?.map((country) =>
      country.toLowerCase(),
    ) ?? [];
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const {
    timeLeft,
    start: startTimer,
    isRunning: isTimerRunning,
    setTimeLeft,
  } = useCountdownTimer({
    defaultSeconds: PINCODE_COUNT_DOWN_TIME,
  });

  const { field: phoneCountryField } = useController({
    name: 'phone_country',
    control,
    defaultValue: merchantData.base_country_code,
  });
  const { field: mobilePhoneField } = useController({
    name: 'mobile_phone',
    control,
    defaultValue: '',
  });
  const { field: pincodeField } = useController({
    name: 'pincode',
    control,
    defaultValue: '',
  });

  const mobilePayload = useMemo(
    () => ({
      country_calling_code: getCountryCallingCode(phoneCountryField.value),
      mobile_phone: mobilePhoneField.value,
    }),
    [mobilePhoneField.value, phoneCountryField.value],
  );

  const checkRegisterStatus = async () => {
    try {
      const response = await authenticationService.checkRegisterStatus(
        mobilePayload,
      );
      const result = checkIsRegistrationExisted(response.data);

      if (result !== registerStatus.ALLOW_CREATE_MEMBER) {
        throw new Error(result);
      }
    } catch (error) {
      if (values(registerStatus).includes(error?.message)) {
        setError('mobile_phone', {
          type: 'api_response_error',
          message: t(registrationErrorMessageMap[error.message]),
        });
        throw CHECK_REGISTER_STATUS_FAILED;
      }

      // unexpected error
      throw error;
    }
  };
  const verifyCode = async () => {
    try {
      await authenticationService.verifyCode({
        ...mobilePayload,
        code: pincodeField.value,
      });
    } catch (error) {
      const errorMessages = error?.response?.data?.error_messages ?? [];

      if (errorMessages.length > 0) {
        setError('pincode', {
          type: 'custom',
          message: errorMessages.join(','),
        });
        throw VERIFY_CODE_FAILED;
      }

      // unexpected error
      throw error;
    }
  };
  const handleSubmitError = (error) => {
    if (isPlainObject(error?.response?.data)) {
      setErrorMessages(extractServerErrorMessages(error.response.data));
    } else if (!VERIFY_ERRORS.includes(error)) {
      // handle unexpected error
      console.error(error);
    }
  };

  const handleSendCode = async () => {
    const isValid = await trigger(['phone_country', 'mobile_phone']);
    if (!isValid) {
      return;
    }

    setErrorMessages([]);
    setIsSendingCode(true);

    // tracks the number of times a user clicks the 'Send Code' button.
    trackerService.track({
      type: EVENT_NAME_FOR_USER_FLOW.SMSVerificationSendButtonClick,
      data: {
        event_category: EVENT_CATEGORY_FOR_USER_FLOW.UserVerification,
        property: PROPERTY_FOR_USER_FLOW.purpose,
        value: VALUE_FOR_USER_FLOW.member_info_update,
      },
    });

    try {
      await checkRegisterStatus();
      const token = await getResendRecaptchaToken();
      refSendCodeReCAPTCHA.current?.reset();
      if (isShowReSendCheckboxRecaptcha) {
        setIsShowReSendCheckboxRecaptcha(false);
      }
      startTimer();

      // track when user triggers 'send code' process on complete member info page
      trackerService.track({
        type: EVENT_NAME_FOR_USER_FLOW.SMSVerificationCodeSend,
        data: {
          event_category: EVENT_CATEGORY_FOR_USER_FLOW.UserVerification,
          property: PROPERTY_FOR_USER_FLOW.purpose,
          value: VALUE_FOR_USER_FLOW.member_info_update,
        },
      });

      await authenticationService.sendSignUpCode({
        ...mobilePayload,
        [RECAPTCHA_TOKEN_FIELD]: token,
        ...getResendRecaptchaCheckboxPayload(),
      });
    } catch (error) {
      if (
        isResendRecaptchaError({ errorCode: error?.response?.data?.error_code })
      ) {
        refSendCodeCheckboxReCAPTCHA.current?.reset();
        setIsShowReSendCheckboxRecaptcha(true);
        setTimeLeft(0);
      } else {
        handleSubmitError(error);
      }
    } finally {
      setIsSendingCode(false);
      setReSendRecaptchaCheckboxToken('');
    }
  };

  const onSubmit = async () => {
    setErrorMessages([]);

    try {
      if (smsVerificationEnabled) {
        await verifyCode();
      } else {
        await checkRegisterStatus();

        const payload = {
          user: mobilePayload,
        };
        if (isRecaptchaEnabled) {
          payload[RECAPTCHA_TOKEN_FIELD] = await getUpdateUserRecaptchaToken();
          if (updateUserRecaptchaCheckboxToken) {
            payload['is_use_checkbox'] = true;
          }
          refUpdateUserReCAPTCHA.current?.reset();
        }

        await authenticationService.updateUser(currentUser._id, payload);
        pnotifyService.notify(t('Member info has been updated'), {});
      }

      await trackerService.track({
        type: EVENT_NAME_FOR_USER_FLOW.UpdateMemberInfoSucceed,
        data: {
          event_category: EVENT_CATEGORY_FOR_USER_FLOW.ProfileUpdate,
          property: PROPERTY_FOR_USER_FLOW.update_info,
          value: VALUE_FOR_USER_FLOW.mobile,
        },
      });

      if (smsVerificationEnabled) {
        await trackerService.track({
          type: EVENT_NAME_FOR_USER_FLOW.UpdateMemberInfoSucceed,
          data: {
            event_category: EVENT_CATEGORY_FOR_USER_FLOW.ProfileUpdate,
            property: PROPERTY_FOR_USER_FLOW.need_verification,
            value: VALUE_FOR_USER_FLOW.sms,
          },
        });
      }

      window.location.href = redirectTo;
    } catch (error) {
      if (
        isUpdateUserRecaptchaError({
          errorCode: error?.response?.data?.error_code,
        })
      ) {
        refUpdateUserCheckboxReCAPTCHA.current?.reset();
        setIsShowUpdateUserCheckboxRecaptcha(true);
      } else {
        handleSubmitError(error);
      }
    }
  };

  const {
    refReCAPTCHA: refSendCodeReCAPTCHA,
    refCheckboxReCAPTCHA: refSendCodeCheckboxReCAPTCHA,
    setRecaptchaCheckboxToken: setReSendRecaptchaCheckboxToken,
    isRecaptchaError: isResendRecaptchaError,
    getRecaptchaToken: getResendRecaptchaToken,
    getRecaptchaCheckboxPayload: getResendRecaptchaCheckboxPayload,
    isShowCheckboxRecaptcha: isShowReSendCheckboxRecaptcha,
    setIsShowCheckboxRecaptcha: setIsShowReSendCheckboxRecaptcha,
    onRecaptchaCheckboxCallback: onReSendRecaptchaCheckboxCallback,
  } = useRecaptcha({ callback: handleSendCode });

  const {
    refReCAPTCHA: refUpdateUserReCAPTCHA,
    refCheckboxReCAPTCHA: refUpdateUserCheckboxReCAPTCHA,
    recaptchaCheckboxToken: updateUserRecaptchaCheckboxToken,
    isRecaptchaError: isUpdateUserRecaptchaError,
    getRecaptchaToken: getUpdateUserRecaptchaToken,
    isShowCheckboxRecaptcha: isShowUpdateUserCheckboxRecaptcha,
    setIsShowCheckboxRecaptcha: setIsShowUpdateUserCheckboxRecaptcha,
    onRecaptchaCheckboxCallback: onUpdateUserRecaptchaCheckboxCallback,
  } = useRecaptcha({ callback: handleSubmit(onSubmit) });

  useEffect(() => {
    // track update member info form show, with sms verification enabled
    if (smsVerificationEnabled) {
      trackerService.track({
        type: EVENT_NAME_FOR_USER_FLOW.UpdateMemberInfoFormShow,
        data: {
          event_category: EVENT_CATEGORY_FOR_USER_FLOW.ProfileUpdate,
          property: PROPERTY_FOR_USER_FLOW.need_verification,
          value: VALUE_FOR_USER_FLOW.sms,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box mb="72px">
        <TitleText mb="16px">{t('Complete member info')}</TitleText>
        <Text
          color="#888"
          fontSize="14px"
          fontWeight={500}
          mb="32px"
          className={CLASSNAME.DESCRIPTION}
        >
          {t('Complete Phone Number Notice')}
        </Text>
        <ErrorBanner errorMessages={errorMessages} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <HStack gap="12px" mb="10px" alignItems="flex-start">
            <Box flex="1">
              <MobilePhoneInput
                formatOnInit={false}
                value={mobilePhoneField.value}
                onSelectFlag={(_number, selectedCountryData) => {
                  phoneCountryField.onChange(
                    selectedCountryData?.iso2?.toUpperCase(),
                  );
                  trigger('mobile_phone');
                }}
                onPhoneNumberBlur={(_isValid, newNumber) => {
                  mobilePhoneField.onBlur(
                    formatPhoneNumber({
                      mobilePhone: newNumber,
                      country: phoneCountryField.value.toUpperCase(),
                    }),
                  );
                }}
                onPhoneNumberChange={(_isValid, newNumber) => {
                  mobilePhoneField.onChange(newNumber.replace(/\+/, ''));
                }}
                label={t('Mobile Number')}
                defaultCountry={phoneCountryField.value}
                isInvalid={!isEmpty(errors?.mobile_phone?.message)}
                errorMessage={t(errors?.mobile_phone?.message)}
                onlyCountries={
                  supportedCountries.includes(ALL_SUPPORTED_COUNTRIES_STRING)
                    ? []
                    : supportedCountries
                }
              />
            </Box>
            {smsVerificationEnabled && (
              <Button
                data-e2e-id="complete-info-sms-verification-send-code_button"
                onClick={handleSendCode}
                variant="outlineSendCode"
                size="sm"
                minWidth="80px"
                mt="18px !important"
                isLoading={isSendingCode}
                isDisabled={
                  isTimerRunning ||
                  (!isEmpty(errors.mobile_phone) &&
                    errors.mobile_phone?.type !== 'api_response_error')
                }
              >
                {isTimerRunning
                  ? t('Seconds left', { seconds: timeLeft })
                  : t('Send verification code')}
              </Button>
            )}
          </HStack>
          {smsVerificationEnabled && (
            <Box mb="10px">
              <Input
                type="text"
                inputMode="numeric"
                autoComplete="one-time-code"
                maxLength={PINCODE_LENGTH}
                pattern={`\\d{${PINCODE_LENGTH}}`}
                placeholder={t('Enter verification code')}
                label={t('Verification code')}
                {...getInputFieldProps({
                  field: pincodeField,
                  error: errors?.pincode,
                  t,
                })}
              />
            </Box>
          )}
          {smsVerificationEnabled && (
            <RecaptchaCheckbox
              refReCAPTCHA={refSendCodeReCAPTCHA}
              refCheckboxReCAPTCHA={refSendCodeCheckboxReCAPTCHA}
              isShowCheckboxRecaptcha={isShowReSendCheckboxRecaptcha}
              onRecaptchaCheckboxCallback={onReSendRecaptchaCheckboxCallback}
              isForceShowRecaptcha={true}
              errorText={
                <ErrorText as="span" mt="6px">
                  {t('Verification code will be sent after you pass reCAPTCHA')}
                </ErrorText>
              }
            />
          )}
          <RecaptchaCheckbox
            refReCAPTCHA={refUpdateUserReCAPTCHA}
            refCheckboxReCAPTCHA={refUpdateUserCheckboxReCAPTCHA}
            isShowCheckboxRecaptcha={isShowUpdateUserCheckboxRecaptcha}
            onRecaptchaCheckboxCallback={onUpdateUserRecaptchaCheckboxCallback}
          />
          <Button
            data-e2e-id="mobile-form-submit_button"
            isFullWidth
            onClick={handleSubmit(onSubmit)}
            mt="32px"
            type="submit"
            isLoading={formState.isSubmitting}
          >
            {t('Complete')}
          </Button>
          {isRecaptchaEnabled && (
            <ReCAPTCHA
              ref={refUpdateUserReCAPTCHA}
              size="invisible"
              grecaptcha={grecaptcha}
              sitekey={recaptchaSiteKey}
            />
          )}
        </form>
        <Center mt="64px">
          <Button
            onClick={() => (window.location.href = redirectTo)}
            variant="link"
            size="sm"
          >
            {t('Skip')}
          </Button>
        </Center>
      </Box>
    </>
  );
};

export default MobileForm;
