import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cloneDeep, partialRight, isNil, merge, pick } from 'lodash-es';
import {
  DEFAULT_ADDRESS,
  ERROR_TYPE,
  MEDIA,
} from '../../constants/addressModule';
import addressFormatService from '../../service/addressFormatService';
import { translateModel } from '../../utils/translate';
import * as utils from './utils';

/**
 * handle address module logic
 * @typedef {Object} ReturnValue
 * @property {boolean} isReady
 * @property {DEFAULT_ADDRESS} address
 * @property {Set<ERROR_TYPE>} errorTypes
 * @property {number|null} loadingLevel
 * @property {string} merchantId
 * @property {array} config
 */
/**
 * @param {object} props
 * @param {PREFERENCE_SCOPE} props.scope
 * @param {DEFAULT_ADDRESS} props.address
 * @param {string} props.address.country - require field, e.g. TW, HK
 * @param {MEDIA} props.maxMedia
 * @param {object} props.getNodeParams
 * @param {string} props.getNodeParams.deliveryMethodId
 * @returns {ReturnValue}
 */
const useAddressModule = (props) => {
  const {
    merchantId,
    scope,
    address: defaultAddress,
    maxMedia,
    getNodeParams,
  } = props;
  const { i18n } = useTranslation();
  const t = partialRight(translateModel, i18n.language);
  const [address, setAddress] = useState(() =>
    merge({}, DEFAULT_ADDRESS, defaultAddress),
  );
  const [isReady, setIsReady] = useState(false);
  const [errorTypes, setErrorTypes] = useState(new Set());
  const [loadingLevel, setLoadingLevel] = useState(null);
  const [config, setConfig] = useState({
    maxLevel: Number.MIN_SAFE_INTEGER,
    preferences: [],
  });
  const [countryOptions, setCountryOptions] = useState([]);
  const [nodeOptions, setNodeOptions] = useState([]);
  const [media, setMedia] = useState(MEDIA.SMALL);
  const addressData = useMemo(
    () =>
      utils.buildAddress({
        address,
        preferences: config.preferences,
        translateModel: t,
      }),
    [address, config.preferences, t],
  );
  const addErrorType = (type) =>
    setErrorTypes((prevErrorTypes) => new Set(prevErrorTypes).add(type));

  const deleteErrorType = (type) =>
    setErrorTypes((prevErrorTypes) => {
      const nextErrorTypes = new Set(prevErrorTypes);
      nextErrorTypes.delete(type);
      return nextErrorTypes;
    });

  const getNodeApi = useCallback(
    async (nodeId) => {
      const params = { country: address.country, with_children: true };
      if (nodeId) {
        params.nodeCodes = nodeId;
        params.withAllChildren = utils.isMY(address.country);
      } else {
        params.with_restrict_level = 1;
      }
      return addressFormatService
        .getAddressNode(merge({}, params, getNodeParams))
        .then((response) => response.data[address.country]);
    },
    [address.country, getNodeParams],
  );
  const getCountries = async () => {
    try {
      const countriesResponse = await addressFormatService.getCountries(
        merchantId,
      );
      setCountryOptions(
        countriesResponse.data.map((country) => ({
          key: country.code,
          label: country.name,
        })),
      );
      deleteErrorType(ERROR_TYPE.COUNTRY);
    } catch (error) {
      addErrorType(ERROR_TYPE.COUNTRY);
      console.error(ERROR_TYPE.COUNTRY, error);
    }
  };

  const initialize = async (preferenceParams) => {
    try {
      setIsReady(false);
      if (countryOptions.length === 0) {
        await getCountries();
      }
      const preferenceResponse = await addressFormatService.getAddressPreference(
        preferenceParams,
      );
      const preferenceConfig = utils.initializeAddressConfig(
        preferenceResponse.data.data,
      );
      const { preferences } = preferenceConfig;
      const nodeData = await utils.initializeAddressNode({
        address,
        country: preferenceParams.country,
        preferences,
        getNodeApi,
      });
      const addressNodeOptions = utils.initializeAddressOptions({
        country: preferenceParams.country,
        nodeData,
        preferences,
      });
      setConfig(preferenceConfig);
      setNodeOptions(addressNodeOptions);
      setAddress(
        utils.transformAddressDataToState({
          address,
          preferences,
          nodeOptions: addressNodeOptions,
        }),
      );
      setIsReady(true);
      deleteErrorType(ERROR_TYPE.INITIALIZATION);
    } catch (error) {
      addErrorType(ERROR_TYPE.INITIALIZATION);
      console.error(ERROR_TYPE.INITIALIZATION, error);
    }
  };

  const calcMedia = () => {
    switch (true) {
      case Boolean(maxMedia):
        setMedia(maxMedia);
        break;
      case window.screen.width >= 1200:
        setMedia(MEDIA.LARGE);
        break;
      case window.screen.width >= 768:
        setMedia(MEDIA.MEDIUM);
        break;
      default:
        setMedia(MEDIA.SMALL);
    }
  };

  useEffect(() => {
    calcMedia();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initialize(merge({ scope }, { country: address.country }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address.country]);

  const getNextLevelNode = useCallback(
    async (preference, newValue) => {
      try {
        setLoadingLevel(preference.level);
        const node = await getNodeApi(newValue);
        const nextLevelOptions = utils.getNextLevelOptions(
          address.country,
          node,
          preference,
        );
        setNodeOptions((prevOptions) =>
          merge([], prevOptions, nextLevelOptions),
        );
        deleteErrorType(ERROR_TYPE.NODE);
      } catch (error) {
        addErrorType(ERROR_TYPE.NODE);
        console.error(ERROR_TYPE.NODE, error);
      } finally {
        setLoadingLevel(null);
      }
    },
    [address.country, getNodeApi],
  );

  const handleInputChange = (newValue, fieldName) =>
    setAddress((prevAddress) => ({
      ...prevAddress,
      [fieldName]: newValue,
    }));

  const handleCountryChange = (newCountry) =>
    setAddress({
      ...cloneDeep(DEFAULT_ADDRESS),
      country: newCountry,
    });

  const handleFieldChange = useCallback(
    (newValue, preference) => {
      const changePreferenceLevel = preference.level;
      const node = utils.getNodeById(
        nodeOptions[changePreferenceLevel],
        newValue,
      );
      if (changePreferenceLevel >= config.maxLevel) {
        setAddress((prevAddress) => ({
          ...prevAddress,
          [preference.field_name]: node,
        }));
        return;
      }
      getNextLevelNode(preference, newValue);
      setNodeOptions((prevOptions) =>
        utils.removeArrayAfterLevelIndex(prevOptions, changePreferenceLevel),
      );
      const needToClearFields = config.preferences
        .filter((item) => item.level > changePreferenceLevel)
        .map((item) => item.field_name);
      setAddress((prevAddress) => ({
        ...prevAddress,
        [preference.field_name]: node,
        ...pick(DEFAULT_ADDRESS, needToClearFields),
      }));
    },
    [config.maxLevel, config.preferences, getNextLevelNode, nodeOptions],
  );

  const renderConfigs = useMemo(() => {
    if (!isReady) {
      return [];
    }
    return utils
      .sortPreferencesByLayout(config.preferences, media)
      .map((item) => {
        const defaultConfig = {
          ...pick(item, ['field_name', 'type', 'required', 'rule', 'level']),
          title: t(item.title_translations),
          placeholder: t(item.placeholder_translations),
          isDisabled: () =>
            item.dependentField && !address[item.dependentField],
          isLoading:
            !isNil(item.level) &&
            !isNil(loadingLevel) &&
            loadingLevel < item.level,
        };
        switch (true) {
          case item.type === 'input':
            return {
              ...defaultConfig,
              onChange: partialRight(handleInputChange, item.field_name),
            };
          case item.field_name === 'country':
            return {
              ...defaultConfig,
              options: countryOptions,
              onChange: handleCountryChange,
              getValue: () => address.country,
            };
          default:
            return {
              ...defaultConfig,
              options: (nodeOptions[item.level] || []).map((option) => ({
                key: option._id,
                label: t(option.name_translations),
              })),
              onChange: partialRight(handleFieldChange, item),
              getValue: () => address[item.field_name]?._id,
            };
        }
      });
  }, [
    address,
    config.preferences,
    countryOptions,
    handleFieldChange,
    isReady,
    loadingLevel,
    media,
    nodeOptions,
    t,
  ]);

  return {
    address: addressData,
    errorTypes,
    loadingLevel,
    isReady,
    renderConfigs,
  };
};

export default useAddressModule;
