export default {
  translation: {
    'Validation - field - required': '{{ fieldName }} is required',
    'Sign Up': 'Sign up',
    'Mobile Number': 'Mobile Number',
    Email: 'Email',
    'Enter your email': 'Enter your email',
    'Accept to receive store updates':
      'I accept to receive store updates, promotions and other marketing information from {{ shopName }}',
    'Terms of Use and Privacy Policy':
      "I agree to the store's <0>Terms of Use</0> and <2>Privacy Policy</2>",
    'Terms of Use and Privacy Policy and Age Policy':
      "I agree to the store's <0>Term of Use</0> and <2>Privacy Policy</2> and I am over {{ age }}",
    Next: 'Next',
    'Already a member': 'Already a member?',
    'Sign in to enjoy more benefits': 'Sign in to enjoy more benefits!',
    'Validation - email - required': 'Email is required',
    'Validation - email - invalid': 'Email is invalid',
    'Validation - email - max': 'Email is too long',
    'Validation - mobile phone - required': 'Mobile number is required',
    'Validation - mobile phone - invalid': 'Mobile number is invalid',
    'Validation - terms and policy - required':
      'You must agree with the terms and conditions to proceed.',
    'Continue Sign up': 'Sign up',
    'Sign In': 'Sign in',
    'Register Status - result - email belongs to guest':
      'This email has previous activity with this store. Instructions on setting up your account have been sent to your inbox.',
    'Register Status - result - email belongs to member':
      'The email has been registered, go to the sign in page or use another email to sign up.',
    'Register Status - result - mobile phone belongs to guest':
      'This mobile number has previous activity with this store. Instructions on how to set up your account has been sent to your mobile.',
    'Register Status - result - mobile phone belongs to member':
      'The mobile number has been registered, go to the sign in page or use another mobile number to sign up.',
    'Register Status - result - email and mobile phone both not allowed':
      'The email and mobile number have been registered separately; please go to the sign in page.',
    'Register Status - result - email is taken':
      'The email is used by another member',
    'Register Status - result - mobile phone is taken':
      'The mobile number is used by another member',
    'Verify mobile number': 'Verify mobile number',
    'Verification code': 'Verification code',
    'Enter verification code': 'Enter verification code',
    'Send verification code': 'Send Verification Code',
    'Resend Verification Code': 'Resend Verification Code',
    'Verification code will be sent after you pass reCAPTCHA':
      'Verification code will be sent after you pass reCAPTCHA',
    'Verification code has been sent to':
      'Verification code has been sent to +{{countryCode}} {{phoneNumber}}',
    'Seconds left': '{{ seconds }} s',
    Back: 'Back',
    'Validation - pincode - required': 'Verification code is required',
    'Validation - pincode - max length':
      'The code must be {{ maxLength }} characters long',
    'Validation - pincode - invalid': 'Invalid code',
    'Last Step': 'Last Step',
    'Please enter your profile':
      'Please enter your profile to complete the registration',
    Username: 'Username',
    'Enter your username': 'Enter your username',
    Password: 'Password',
    'Password(at least 8 characters)': 'Password(at least 8 characters)',
    Gender: 'Gender',
    'Gender - male': 'Male',
    'Gender - female': 'Female',
    'Gender - undisclosed': 'Undisclosed',
    Birthday: 'Birthday',
    'Birthday - year': 'YYYY',
    'Birthday - month': 'MM',
    'Birthday - date': 'DD',
    'Validation - birthday - invalid': 'Birthday is invalid',
    'Validation - birthday - min age':
      'Birthday is invalid, should over {{ age }} years old.',
    'Validation - birthday - required': 'Birthday is required',
    Optional: '(optional)',
    'Sign me up': 'Sign me up!',
    'Validation - username - required': 'Full name is required',
    'Validation - username - too long': 'Full name is too long',
    'Validation - password - required': 'Password is required',
    'Validation - password - too short': 'Password is too short',
    'Validation - password - too long': 'Password is too long',
    'Email or Mobile Number': 'Email or Mobile Number',
    'Password (8 characters minimum)': 'Password(at least 8 characters)',
    'Forgot Password': 'Forgot your password?',
    'Lets go': "Let's go!",
    'Not a member': 'Not a member?',
    'Validation - email and mobile - required':
      'Email or Mobile phone is required',
    'Validation - email and mobile - invalid':
      'Email or Mobile phone is invalid',
    'Sign up Success': 'Thanks! Your account has been successfully created.',
    'Complete member info': 'Complete member info',
    'Complete Phone Number Notice':
      "Provide phone number to receive the store's latest campaign information",
    'Complete Email Notice':
      "Provide email to receive the store's latest campaign information",
    Complete: 'Complete',
    Skip: 'Skip',
    'sign up with social account': 'or sign up with social account',
    'login in with social account': 'Use social account',
    'Login - email - label': 'Email',
    'Login - email - placeholder': 'Enter your email',
    'Login - mobile phone - label': 'Mobile Number',
    'Login - mobile phone - placeholder': 'Enter your mobile number',
    'Login - email or mobile phone - label': 'Email or Mobile Number',
    'Login - email or mobile phone - placeholder':
      'Enter your email or mobile number',
    'Login - Error - unconfirmed email':
      'Welcome back! For your account security, you need to verify when logging in to your account. The verification email has already been sent to {{email}}. Please check it in your mailbox. Thank you!',
    'Member info has been updated': 'Member info has been updated',
    'Subscription - description':
      'Subscribe to store updates and promotional news with social platforms',
    'Subscription - LINE - subscribe': 'Subscribe with LINE',
    'Subscription - connection notification - success':
      'Subscribed successfully.',
    'Subscription - LINE - connection notification - failure':
      'Failed to subscribe. Please log in to LINE again.',
    'Verification - email - trial merchant - reach limit - failure':
      'The verification email is not available during the trial operation of the store. If needed, please contact the store for assistance.',
    'Subscription - FACEBOOK - connection notification - failure':
      'Failed to subscribe. Please log in to Facebook again.',
  },
};
