/* eslint-disable react/prop-types */
/*
  Usage note:
  withContext should be the first param of pipe fn due to injected props from react_on_rails
*/
import slFeatureService from '@shopline/sl-feature/js/sl-feature';
import React, { createContext, useContext, useState } from 'react';
import isMobileOrPad from '../utils/isMobileOrPad';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

const withAppContext = (Comp) =>
  function AppContextProvider(props) {
    const [isExceedCartLimitation, setIsExceedCartLimitation] = useState(
      props.isExceedCartLimitation,
    );

    const featureService = slFeatureService(props.featuresLimit);

    return (
      <AppContext.Provider
        value={{
          ...props,
          isExceedCartLimitation,
          setIsExceedCartLimitation,
          featureService,
          isMobileOrPad: isMobileOrPad(),
        }}
      >
        <Comp />
      </AppContext.Provider>
    );
  };

export default withAppContext;
