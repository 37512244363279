import { isEmpty } from 'lodash-es';

/**
 * Get props for <Input/> with react-hook-form
 * @param {Object} arg
 * @param {import('react-hook-form').ControllerRenderProps} arg.field
 * @param {import('react-hook-form').FieldErrors} arg.error
 * @param {import('i18next').TFunction} arg.t
 * @returns {Object} props that <Input/> is needed
 */
export const getInputFieldProps = ({ field, error, t }) => ({
  onChange: (e) => field.onChange(e.target.value),
  value: field.value || '',
  onBlur: field?.onBlur,
  isInvalid: !isEmpty(error?.message),
  errorMessage: error?.message?.translateKey
    ? t(error?.message?.translateKey, error?.message?.params)
    : t(error?.message),
});
