import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from '@chakra-ui/react';
import { Facebook as FacebookIcon } from '../../../../context/Chakra/Icons';
import { PANEL } from '../../../constants/panel';
import {
  EVENT_CATEGORY_FOR_USER_FLOW,
  VALUE_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
  EVENT_NAME_FOR_USER_FLOW,
} from '../../../../../shop/constants/signupLoginEventsTracking';
import trackerService from '../../../../../service/trackerService';

const FacebookAccount = (props) => {
  const { currentPanel, isSubscribed, e2eId } = props;
  const facebookUrl = useMemo(() => {
    let url = '/users/auth/facebook';
    const params = new URLSearchParams();
    if (currentPanel === PANEL.SIGN_UP) {
      params.set('signup', true);
    }
    if (isSubscribed) {
      params.set('marketing', true);
    }
    const urlQueries = new URLSearchParams(window.location.search);
    const encryptDataParams = urlQueries.get('encrypt_data');
    if (encryptDataParams) {
      // URLSearchParams will decode params once so we need to encode to handle uri contains plus symbol case
      // as fb redirect_uri
      const encryptData = encodeURIComponent(encryptDataParams);
      params.set('encrypt_data', encryptData);
    }
    const stringifyParams = params.toString();
    if (stringifyParams.length > 0) {
      url = `${url}?${stringifyParams}`;
    }
    return url;
  }, [currentPanel, isSubscribed]);

  const handleClick = () => {
    if (currentPanel !== PANEL.SIGN_UP) return;

    trackerService.track({
      type: EVENT_NAME_FOR_USER_FLOW.ThirdPartySignUpClick,
      data: {
        event_category: EVENT_CATEGORY_FOR_USER_FLOW.SignUp,
        property: PROPERTY_FOR_USER_FLOW.sign_up_option,
        value: VALUE_FOR_USER_FLOW.facebook,
      },
    });
  };

  return (
    <Link href={facebookUrl}>
      <FacebookIcon
        data-e2e-id={e2eId}
        color="#1877F2"
        boxSize="40px"
        onClick={handleClick}
      />
    </Link>
  );
};

FacebookAccount.propTypes = {
  currentPanel: PropTypes.oneOf([PANEL.LOGIN, PANEL.SIGN_UP]).isRequired,
  isSubscribed: PropTypes.bool,
  e2eId: PropTypes.string,
};

export default FacebookAccount;
