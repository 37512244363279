import {
  cloneDeep,
  cond,
  curryRight,
  flow,
  isPlainObject,
  isString,
  partial,
  pick,
  result,
  sortBy,
  stubTrue,
} from 'lodash-es';
import {
  GENERAL_ADDRESS_FIELDS,
  MY_ADDRESS_FIELDS,
  REGION_CODE_FIELD,
  TW_ADDRESS_FIELDS,
} from '../../constants/addressModule';

export const isMY = (country) => country === 'MY';

export const isTW = (country) => country === 'TW';

const isSupportRegionCode = (country) => ['CA', 'US'].includes(country);

const isMYDistrict = (country, level) => isMY(country) && level === 3;

const hasAddressNodeId = ({ address }) => address?.address_node_ids?.length > 0;

const filterHiddenAddress = (preferences) =>
  preferences.filter((preference) => preference.display);

const getMaxLevel = (preferences) =>
  Math.max(1, ...preferences.map((preference) => preference.level));

const sortPreferencesByLevel = (preferences) => sortBy(preferences, ['level']);

export const sortPreferencesByLayout = (preferences, media) => {
  const sortByColumn = sortBy(preferences, [
    (preference) => preference.layout[media].column,
  ]);
  return sortBy(sortByColumn, [(preference) => preference.layout[media].row]);
};

const appendPreferenceField = (maxPriority, preferences) =>
  preferences.map((preference) => {
    const priority = preference.priority;
    if (priority > 0 && priority < maxPriority) {
      const dependentField = preferences.find(
        (target) => target.priority === priority + 1,
      );
      if (dependentField) {
        return {
          ...preference,
          dependentField: dependentField.field_name,
        };
      }
    }
    return preference;
  });

const transformRuleToRegExp = (preferences) =>
  preferences.map((preference) => {
    if (preference.rule) {
      return {
        ...preference,
        rule: new RegExp(preference.rule),
      };
    }
    return preference;
  });

export const removeArrayAfterLevelIndex = (options, targetLevel) =>
  options.slice(0, targetLevel + 1);

const findNodeByName = (node, country, text) => {
  const lowerCaseText = text.toLowerCase();
  return Object.values(node.name_translations).some(
    // name may be null
    (name) =>
      isTW(country)
        ? // This is for TW address_2 because the API options are POSTCODE + ADDRESS_2 and ADDRESS_2 of the selected address has no POSTCODE
          name?.toLowerCase().includes(lowerCaseText)
        : name?.toLowerCase() === lowerCaseText,
  );
};

export const getNodeByName = (nodes, country, text) =>
  nodes.find((node) => findNodeByName(node, country, text));

export const getNodeById = (nodes, id) => nodes.find((node) => node._id === id);

const getTargetAddressNodes = (node, level, country) => {
  if (isMYDistrict(country, level)) {
    return node.address_nodes[0].address_nodes;
  }
  return result(node, Array(level).fill('address_nodes').join('[0].'), []);
};

/**
 * This method determines how many nodes are needed to execute the API to obtain the next level.
 * MY only needs the first node, while other countries need all nodes except the last node (because the last one will not have next layer)
 */
const getEndIndex = (country) => (isMY(country) ? 1 : -1);

const concurrencyGetAddressNode = ({ address, country, getNodeApi }) => {
  const getFirstNodePromise = getNodeApi('');
  const promises = address.address_node_ids
    .slice(0, getEndIndex(country))
    .map(getNodeApi);
  return Promise.all([getFirstNodePromise, ...promises]);
};

const sequencyGetAddressNode = ({
  address,
  country,
  preferences,
  getNodeApi,
}) => {
  const getFirstNodePromise = getNodeApi('');
  const promises = sortPreferencesByLevel(preferences)
    .filter(
      (preference) => preference.level > 0 && address[preference.field_name],
    )
    .slice(0, getEndIndex(country))
    .map((preference) => ({
      getNode: curryRight(getNodeByName, 3)(address[preference.field_name])(
        country,
      ),
      level: preference.level,
    }))
    .reduce(
      (accumulatorPromise, { getNode, level }) => {
        const lastPromise = accumulatorPromise[accumulatorPromise.length - 1];
        return accumulatorPromise.concat(
          lastPromise.then((data) => {
            const node = flow(getTargetAddressNodes, getNode)(
              data,
              level,
              country,
            );
            return getNodeApi(node._id);
          }),
        );
      },
      [getFirstNodePromise],
    );
  return Promise.all(promises);
};

const transformNodeToOption = ({ country, nodeData, levelList }) =>
  nodeData.reduce((accumulate, node, index) => {
    const level = levelList[index];
    const addressNodes = getTargetAddressNodes(node, level, country);
    if (isMYDistrict(country, level)) {
      accumulate[level] = addressNodes.reduce((accumulate, item) => {
        accumulate.push(
          ...item.address_nodes.map((node) => ({
            ...node,
            parentNode: item,
          })),
        );
        return accumulate;
      }, []);
    } else {
      accumulate[level] = addressNodes;
    }
    return accumulate;
  }, []);

export const initializeAddressConfig = (preferenceRule) => ({
  preferences: flow(
    transformRuleToRegExp,
    filterHiddenAddress,
    partial(appendPreferenceField, preferenceRule.max_priority),
  )(preferenceRule.preferences),
  maxLevel: getMaxLevel(preferenceRule.preferences),
});

export const initializeAddressNode = cond([
  [hasAddressNodeId, concurrencyGetAddressNode],
  [stubTrue, sequencyGetAddressNode],
]);

export const initializeAddressOptions = ({
  country,
  nodeData,
  preferences,
}) => {
  const levelList = sortPreferencesByLevel(preferences)
    .filter((preference) => preference.level > 0)
    .map((preference) => preference.level);
  return transformNodeToOption({ country, nodeData, levelList });
};

export const getNextLevelOptions = (country, node, preference) => {
  const nextLevel = isMY(country) ? 3 : preference.level + 1;
  return transformNodeToOption({
    country,
    nodeData: [node],
    levelList: [nextLevel],
  });
};

const trimString = (address) =>
  Object.keys(address)
    .filter((key) => isString(address[key]))
    .reduce((accumulate, field) => {
      accumulate[field] = accumulate[field].trim();
      return accumulate;
    }, address);

const filterWhiteListFields = (address, preferences) => {
  const preferenceField = preferences.map(
    (preference) => preference.field_name,
  );
  if (isTW(address.country)) {
    preferenceField.push(...TW_ADDRESS_FIELDS);
  } else if (isMY(address.country)) {
    preferenceField.push(...MY_ADDRESS_FIELDS);
  } else {
    preferenceField.push(...GENERAL_ADDRESS_FIELDS);
  }
  if (isSupportRegionCode(address.country)) {
    preferenceField.push(REGION_CODE_FIELD);
  }
  return pick(address, preferenceField);
};

const appendAddressNodeIdAndLogisticCode = (address, node) => {
  const addressNodeIds = address.address_node_ids || [];
  const logisticCodes = address.logistic_codes || [];
  const index = node.level - 1;
  addressNodeIds[index] = node._id;
  if (node.code) {
    logisticCodes[index] = node.code;
  }
  return {
    ...address,
    address_node_ids: addressNodeIds,
    logistic_codes: logisticCodes,
  };
};

const processNodeObject = (address, translateModel) =>
  Object.keys(address)
    .filter((key) => isPlainObject(address[key]))
    .reduce((accumulate, fieldName) => {
      const node = accumulate[fieldName];
      accumulate = appendAddressNodeIdAndLogisticCode(accumulate, node);
      if (isTW(accumulate.country) && fieldName === 'address_2') {
        const value = translateModel(node.name_translations);
        accumulate.postcode = value.replace(/[^0-9]/g, '').trim();
        accumulate.address_2 = value.replace(/[0-9]/g, '').trim();
        return accumulate;
      }
      if (isMY(accumulate.country) && node.level === 3) {
        accumulate = appendAddressNodeIdAndLogisticCode(
          accumulate,
          node.parentNode,
        );
        accumulate.city = translateModel(node.parentNode.name_translations);
      }
      // only for ca and us tax
      if (isSupportRegionCode(accumulate.country) && node.region_code) {
        accumulate.region_code = node.region_code;
      }
      accumulate[fieldName] = translateModel(node.name_translations);
      return accumulate;
    }, address);

export const buildAddress = ({ address, preferences, translateModel }) =>
  flow(
    cloneDeep,
    trimString,
    curryRight(filterWhiteListFields)(preferences),
    curryRight(processNodeObject)(translateModel),
  )(address);

export const transformAddressDataToState = ({
  address,
  preferences,
  nodeOptions,
}) => {
  const dropdownWithValuePreferences = preferences.filter(
    (preference) => preference.level > 0 && address[preference.field_name],
  );
  return dropdownWithValuePreferences.reduce((accumulate, preference) => {
    accumulate[preference.field_name] = getNodeByName(
      nodeOptions[preference.level],
      address.country,
      accumulate[preference.field_name],
    );
    return accumulate;
  }, cloneDeep(address));
};
