import React from 'react';
import { keys } from 'lodash-es';
import PropTypes from 'prop-types';
import { Button as ChakraButton } from '@chakra-ui/react';

const classNameMap = {
  solid: 'solid-btn',
  outline: 'outline-btn',
  solidWhite: 'solid-white-btn',
  outlineWhite: 'outline-white-btn',
  outlineSendCode: 'outline-send-code-btn',
  link: 'link-btn',
};

const Button = (props) => {
  const { children, variant = 'solid' } = props;
  return (
    <ChakraButton {...props} className={classNameMap[variant]}>
      {children}
    </ChakraButton>
  );
};

Button.propTypes = {
  children: PropTypes.string,
  variant: PropTypes.oneOf(keys(classNameMap)),
};

export default Button;
