import React, { useState, useEffect } from 'react';
import { useFormContext, useController } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import {
  Box,
  Text,
  Link,
  useDisclosure,
  Center,
  HStack,
} from '@chakra-ui/react';
import { getCountryCallingCode } from 'libphonenumber-js/max'; // NOTE: use most strict validation (same as old checkout page)
import { isEmpty } from 'lodash-es';
import { RECAPTCHA_TOKEN_FIELD } from '../../../../../constants/recaptchable';
import { ALL_SUPPORTED_COUNTRIES_STRING } from '../../../../../constants/userSetting';
import authenticationService from '../../../../../service/authenticationService';
import { PANEL } from '../../../constants/panel';
import { registerStatus } from '../../../constants/registerStatus';
import {
  EVENT_CATEGORY_FOR_USER_FLOW,
  VALUE_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
  EVENT_NAME_FOR_USER_FLOW,
} from '../../../../../shop/constants/signupLoginEventsTracking';
import { useAppContext } from '../../../context/appContext';
import { checkIsRegistrationExisted } from '../../../utils/checkRegisterStatus';
import { formatPhoneNumber } from '../../../utils/formatPhoneNumber';
import {
  Input,
  MobilePhoneInput,
  TitleText,
  Button,
  Checkbox,
  ErrorText,
  RecaptchaCheckbox,
  ErrorBanner,
} from '../../../components';
import {
  RegisterStatusPopup,
  FacebookAccount,
  LineAccount,
} from '../../components';
import { QUOTA_LIMIT_ERROR_TYPE } from '../../../../../shop/constants/quota_limit';
import pnotifyService from '../../../../../service/pnotifyService';
import trackerService from '../../../../../service/trackerService';
import { useRecaptcha } from '../../../hooks';
import extractServerErrorMessages from '../../../utils/extractServerErrorMessages';

// eslint-disable-next-line react/prop-types
const StepOne = ({ handleNextStep }) => {
  const {
    setCurrentPanel,
    setDefaultAccount,
    merchantData,
    isEmailRequired,
    isMobilePhoneRequired,
    slFeatureService,
    userSetting,
    isRecaptchaEnabled,
    isRecaptchaEnterprise,
    isFacebookLoginEnabled,
    isLineLoginEnabled,
    smsVerificationEnabled,
  } = useAppContext();
  const { t } = useTranslation();
  const {
    errors,
    control,
    handleSubmit,
    formState,
    trigger,
    setError,
    setValue,
    getValues,
  } = useFormContext();
  const [isCheckingRegisterStatus, setIsCheckingRegisterStatus] = useState(
    false,
  );
  const {
    isOpen: isOpenRegistrationStatusPopup,
    onOpen: openRegistrationStatusPopup,
    onClose: closeRegistrationStatusPopup,
  } = useDisclosure();
  const [registrationResult, setRegistrationResult] = useState('');
  const [registrationErrorMessage, setRegistrationErrorMessage] = useState('');
  const [requestErrors, setRequestErrors] = useState([]);

  const { field: phoneCountryField } = useController({
    name: 'phone_country',
    control,
    defaultValue: merchantData.base_country_code,
  });

  const { field: mobilePhoneField } = useController({
    name: 'mobile_phone',
    control,
    defaultValue: '',
  });
  const { field: emailField } = useController({
    name: 'email',
    control,
    defaultValue: '',
  });
  const { field: subscriptionField } = useController({
    name: 'subscription',
    control,
    defaultValue: slFeatureService.hasFeature('pretick_accept_marketing'),
  });
  const { field: acceptPolicyField } = useController({
    name: 'accept_policy',
    control,
    defaultValue: false,
  });

  const trackerRecaptchaVerificationVal = isRecaptchaEnterprise
    ? VALUE_FOR_USER_FLOW.shopline
    : VALUE_FOR_USER_FLOW.merchant;

  const btnDisabled =
    isEmpty(emailField.value) && isEmpty(mobilePhoneField.value);

  const trackUserSignUp = (
    eventName,
    eventCategory,
    eventProperty,
    signupOption,
  ) => {
    trackerService.track({
      type: eventName,
      data: {
        event_category: eventCategory,
        property: eventProperty,
        value: signupOption,
      },
    });
  };

  const applyReCAPTCHAToPayload = async (payload = {}) => {
    if (isRecaptchaEnabled) {
      const token = await getRecaptchaToken();
      refReCAPTCHA.current?.reset();
      if (token) {
        payload[RECAPTCHA_TOKEN_FIELD] = token;
        if (recaptchaCheckboxToken) {
          payload['is_use_checkbox'] = true;
        }
      }
      if (isShowCheckboxRecaptcha) {
        setIsShowCheckboxRecaptcha(false);
      }
    }
    return payload;
  };

  const notifyUserOfGuestEmail = async (emailPayload) => {
    const payload = await applyReCAPTCHAToPayload({ user: emailPayload });
    try {
      const res = await authenticationService.createUser(payload);

      if (res?.data?.message) {
        const { message } = res?.data ?? {};
        setError('email', {
          message,
        });
      }
    } catch (error) {
      const err = error?.response?.data || {};
      if (
        err?.error_code === QUOTA_LIMIT_ERROR_TYPE.TRIAL_EMAIL_QUOTA_EXCEEDED
      ) {
        pnotifyService.notify(
          t('Verification - email - trial merchant - reach limit - failure'),
          { customClass: 'error', icon: 'fa fa-exclamation-triangle' },
        );
      }
    }
  };

  const notifyUserOfGuestMobilePhone = async (mobilePayload) => {
    const res = await authenticationService.registerValidation({
      user: mobilePayload,
    });
    if (res?.data?.message) {
      const { message } = res?.data ?? {};
      setError('mobile_phone', {
        message,
      });
    }
  };

  const sendCode = async () => {
    // track if user trigger send code verification process
    trackUserSignUp(
      EVENT_NAME_FOR_USER_FLOW.SMSVerificationCodeSend,
      EVENT_CATEGORY_FOR_USER_FLOW.UserVerification,
      PROPERTY_FOR_USER_FLOW.purpose,
      VALUE_FOR_USER_FLOW.initial_signup,
    );

    // start timer for recaptcha event tracking
    const recaptchaStart = new Date();

    // tracker when recaptcha verification start, recaptcha by shopline or merchant
    trackUserSignUp(
      EVENT_NAME_FOR_USER_FLOW.RecaptchaVerificationStart,
      EVENT_CATEGORY_FOR_USER_FLOW.UserVerification,
      PROPERTY_FOR_USER_FLOW.set_up_by,
      trackerRecaptchaVerificationVal,
    );

    const token = await getRecaptchaToken();

    // tracker when recaptcha verification complete(get token), recaptcha by shopline or merchant
    if (token) {
      trackUserSignUp(
        EVENT_NAME_FOR_USER_FLOW.RecaptchaVerificationComplete,
        EVENT_CATEGORY_FOR_USER_FLOW.UserVerification,
        PROPERTY_FOR_USER_FLOW.set_up_by,
        trackerRecaptchaVerificationVal,
      );

      const recaptchaEnd = new Date();
      const timeDiff = (recaptchaEnd - recaptchaStart) / 1000;

      // tracker when recaptcha verification complete(get token), how long it takes
      trackUserSignUp(
        EVENT_NAME_FOR_USER_FLOW.RecaptchaVerificationComplete,
        EVENT_CATEGORY_FOR_USER_FLOW.UserVerification,
        PROPERTY_FOR_USER_FLOW.pass_time,
        timeDiff,
      );
    }

    refReCAPTCHA.current?.reset();
    if (isShowCheckboxRecaptcha) {
      setIsShowCheckboxRecaptcha(false);
    }
    const payload = {
      country_calling_code: getCountryCallingCode(getValues('phone_country')),
      mobile_phone: getValues('mobile_phone'),
      [RECAPTCHA_TOKEN_FIELD]: token,
      ...(recaptchaCheckboxToken ? { is_use_checkbox: true } : {}),
    };
    await authenticationService.sendSignUpCode(payload);
  };

  const onSubmit = async () => {
    if (!isEmpty(errors)) return;
    if (isMobilePhoneRequired) {
      mobilePhoneField.onChange(
        formatPhoneNumber({
          mobilePhone: mobilePhoneField.value,
          country: phoneCountryField.value,
        }),
      );
    }
    const email = emailField.value.trim();
    const mobilePayload = {
      mobile_phone: mobilePhoneField.value,
      country_calling_code: getCountryCallingCode(phoneCountryField.value),
    };
    let isUnmount = false;
    try {
      setIsCheckingRegisterStatus(true);
      const registerStatusResponse = await authenticationService.checkRegisterStatus(
        {
          ...(isEmailRequired ? { email } : {}),
          ...(isMobilePhoneRequired ? mobilePayload : {}),
        },
      );
      const result = checkIsRegistrationExisted(registerStatusResponse.data);
      setRegistrationResult(result);

      switch (result) {
        case registerStatus.ALLOW_CREATE_MEMBER: {
          isUnmount = true;

          trackUserSignUp(
            EVENT_NAME_FOR_USER_FLOW.MemberSignUpFormSubmit,
            EVENT_CATEGORY_FOR_USER_FLOW.SignUp,
            PROPERTY_FOR_USER_FLOW.sign_up_option,
            userSetting?.signup_method,
          );

          // track sms verification option
          trackUserSignUp(
            EVENT_NAME_FOR_USER_FLOW.MemberSignUpFormSubmit,
            EVENT_CATEGORY_FOR_USER_FLOW.SignUp,
            PROPERTY_FOR_USER_FLOW.sms_verification,
            smsVerificationEnabled,
          );

          // track if user check to receive store updates and marketing email
          trackUserSignUp(
            EVENT_NAME_FOR_USER_FLOW.MemberSignUpFormSubmit,
            EVENT_CATEGORY_FOR_USER_FLOW.SignUp,
            PROPERTY_FOR_USER_FLOW.accept_marketing,
            subscriptionField.value,
          );

          if (smsVerificationEnabled) {
            await sendCode();
          }
          refCheckboxReCAPTCHA.current?.reset();
          handleNextStep();
          break;
        }
        case registerStatus.EMAIL_BELONGS_TO_EXISTING_GUEST: {
          await notifyUserOfGuestEmail({ email });
          break;
        }
        case registerStatus.EMAIL_BELONGS_TO_EXISTING_MEMBER: {
          setRegistrationErrorMessage(
            t('Register Status - result - email belongs to member'),
          );
          openRegistrationStatusPopup();
          break;
        }
        case registerStatus.MOBILE_PHONE_BELONGS_TO_EXISTING_GUEST: {
          await notifyUserOfGuestMobilePhone(mobilePayload);
          break;
        }
        case registerStatus.MOBILE_PHONE_BELONGS_TO_EXISTING_MEMBER: {
          setRegistrationErrorMessage(
            t('Register Status - result - mobile phone belongs to member'),
          );
          openRegistrationStatusPopup();
          break;
        }
        case registerStatus.EMAIL_AND_MOBILE_PHONE_BOTH_NOT_ALLOWED: {
          setRegistrationErrorMessage(
            t(
              'Register Status - result - email and mobile phone both not allowed',
            ),
          );
          openRegistrationStatusPopup();
          break;
        }
      }
    } catch (e) {
      const data = e?.response?.data;
      if (isRecaptchaError({ errorCode: data?.error_code })) {
        refCheckboxReCAPTCHA.current?.reset();
        setIsShowCheckboxRecaptcha(true);
      } else {
        // Handle Unexpected Error
        setRequestErrors(extractServerErrorMessages(data));
        console.error(e);
      }
      setIsCheckingRegisterStatus(false);
    } finally {
      if (!isUnmount) {
        setIsCheckingRegisterStatus(false);
      }
    }
  };

  const clearAccountInput = () => {
    if (
      [
        registerStatus.EMAIL_BELONGS_TO_EXISTING_MEMBER,
        registerStatus.EMAIL_AND_MOBILE_PHONE_BOTH_NOT_ALLOWED,
      ].includes(registrationResult)
    ) {
      setValue('email', '', { shouldValidate: false });
    }

    if (
      [
        registerStatus.MOBILE_PHONE_BELONGS_TO_EXISTING_MEMBER,
        registerStatus.EMAIL_AND_MOBILE_PHONE_BOTH_NOT_ALLOWED,
      ].includes(registrationResult)
    ) {
      setValue('mobile_phone', '', {
        shouldValidate: false,
      });
    }
  };

  const setAccountToLogin = () => {
    if (
      registrationResult === registerStatus.EMAIL_BELONGS_TO_EXISTING_MEMBER
    ) {
      setDefaultAccount({ email: emailField.value, mobile_phone: '' });
      return;
    }
    if (
      registrationResult ===
      registerStatus.MOBILE_PHONE_BELONGS_TO_EXISTING_MEMBER
    ) {
      setDefaultAccount({ email: '', mobile_phone: mobilePhoneField.value });
      return;
    }
    if (
      registrationResult ===
      registerStatus.EMAIL_AND_MOBILE_PHONE_BOTH_NOT_ALLOWED
    ) {
      setDefaultAccount({ email: '', mobile_phone: '' });
      return;
    }
  };

  const {
    refReCAPTCHA,
    refCheckboxReCAPTCHA,
    recaptchaCheckboxToken,
    isRecaptchaError,
    getRecaptchaToken,
    isShowCheckboxRecaptcha,
    setIsShowCheckboxRecaptcha,
    onRecaptchaCheckboxCallback,
  } = useRecaptcha({ callback: handleSubmit(onSubmit) });

  const handleSignInClick = () => {
    setCurrentPanel(PANEL.LOGIN);
    // track user click login button on sign up page
    trackUserSignUp(
      EVENT_NAME_FOR_USER_FLOW.MemberLoginClick,
      EVENT_CATEGORY_FOR_USER_FLOW.MemberLogin,
    );
  };

  function getThirdPartyOptions(isFacebookLoginEnabled, isLineLoginEnabled) {
    const thirdPartyOptions = [];

    if (isFacebookLoginEnabled) {
      thirdPartyOptions.push(VALUE_FOR_USER_FLOW.facebook);
    }

    if (isLineLoginEnabled) {
      thirdPartyOptions.push(VALUE_FOR_USER_FLOW.line);
    }

    return thirdPartyOptions;
  }

  useEffect(() => {
    // track land on sign up page and the form shows
    trackUserSignUp(
      EVENT_NAME_FOR_USER_FLOW.MemberSignUpFormShow,
      EVENT_CATEGORY_FOR_USER_FLOW.SignUp,
      PROPERTY_FOR_USER_FLOW.sign_up_option,
      userSetting?.signup_method,
    );

    const thirdPartyOptions = getThirdPartyOptions(
      isFacebookLoginEnabled,
      isLineLoginEnabled,
    );
    trackUserSignUp(
      EVENT_NAME_FOR_USER_FLOW.MemberSignUpFormShow,
      EVENT_CATEGORY_FOR_USER_FLOW.SignUp,
      PROPERTY_FOR_USER_FLOW.third_party_sign_up_option,
      thirdPartyOptions,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isOpenRegistrationStatusPopup && (
        <RegisterStatusPopup
          errorMessage={registrationErrorMessage}
          handleRegisterClick={() => {
            clearAccountInput();
            closeRegistrationStatusPopup();
          }}
          handleLoginClick={() => {
            setAccountToLogin();
            setCurrentPanel(PANEL.LOGIN);
          }}
        />
      )}
      <Box mb="72px">
        <TitleText mb="30px">{t('Sign Up')}</TitleText>
        <ErrorBanner errorMessages={requestErrors} />
        <form onSubmit={handleSubmit(onSubmit)}>
          {isMobilePhoneRequired && (
            <Box mb="10px">
              <MobilePhoneInput
                e2eId="sign-up-mobile-phone_input"
                formatOnInit={false}
                value={mobilePhoneField.value}
                onSelectFlag={(_number, selectedCountryData) => {
                  phoneCountryField.onChange(
                    selectedCountryData?.iso2?.toUpperCase(),
                  );
                  if (formState.isSubmitted) {
                    trigger('mobile_phone');
                  }
                }}
                onPhoneNumberBlur={(_isValid, newNumber) => {
                  if (
                    newNumber.length === 0 ||
                    !isEmpty(errors?.mobile_phone?.message) ||
                    !formState.isSubmitted
                  ) {
                    return;
                  }
                  mobilePhoneField.onChange(
                    formatPhoneNumber({
                      mobilePhone: newNumber,
                      country: phoneCountryField.value.toUpperCase(),
                    }),
                  );
                }}
                onPhoneNumberChange={(_isValid, newNumber) => {
                  mobilePhoneField.onChange(newNumber.replace(/\+/, ''));
                }}
                label={t('Mobile Number')}
                defaultCountry={phoneCountryField.value}
                isInvalid={!isEmpty(errors?.mobile_phone?.message)}
                errorMessage={t(errors?.mobile_phone?.message)}
                onlyCountries={
                  userSetting?.sms_verification?.supported_countries.includes(
                    ALL_SUPPORTED_COUNTRIES_STRING,
                  )
                    ? []
                    : userSetting?.sms_verification?.supported_countries.map(
                        (country) => country.toLowerCase(),
                      ) || []
                }
              />
            </Box>
          )}
          {isEmailRequired && (
            <Box mb="32px">
              <Input
                data-e2e-id="sing-up-email_input"
                onChange={(e) => emailField.onChange(e.target.value)}
                value={emailField.value}
                label={t('Email')}
                placeholder={t('Enter your email')}
                isInvalid={!isEmpty(errors?.email?.message)}
                errorMessage={t(errors?.email?.message)}
              />
            </Box>
          )}

          <Box mb="12px">
            <Checkbox
              data-e2e-id="sign-up-accept-store-updates_checkbox"
              isChecked={subscriptionField.value}
              onChange={(e) => subscriptionField.onChange(e.target.checked)}
            >
              {t('Accept to receive store updates', {
                shopName: merchantData.name,
              })}
            </Checkbox>
          </Box>
          <Box>
            <Checkbox
              isChecked={acceptPolicyField.value}
              onChange={(e) => acceptPolicyField.onChange(e.target.checked)}
            >
              <Trans
                i18nKey={
                  userSetting.enable_age_policy
                    ? 'Terms of Use and Privacy Policy and Age Policy'
                    : 'Terms of Use and Privacy Policy'
                }
                values={{ age: userSetting.minimum_age_limit }}
              >
                <Link
                  color="#272D36"
                  textDecoration="underline"
                  href="/about/terms"
                  target="_blank"
                  fontWeight={500}
                />
                and
                <Link
                  color="#272D36"
                  textDecoration="underline"
                  href="/about/privacy-policy"
                  target="_blank"
                  fontWeight={500}
                />
              </Trans>
            </Checkbox>
            {!isEmpty(errors?.accept_policy?.message) && (
              <ErrorText>{t(errors.accept_policy.message)}</ErrorText>
            )}
          </Box>
          <RecaptchaCheckbox
            refReCAPTCHA={refReCAPTCHA}
            refCheckboxReCAPTCHA={refCheckboxReCAPTCHA}
            isShowCheckboxRecaptcha={isShowCheckboxRecaptcha}
            isForceShowRecaptcha={true}
            onRecaptchaCheckboxCallback={onRecaptchaCheckboxCallback}
          />
          <Button
            data-e2e-id="sign-up-next-step_button"
            isFullWidth
            onClick={handleSubmit(onSubmit)}
            mt="32px"
            type="submit"
            isDisabled={btnDisabled}
            isLoading={isCheckingRegisterStatus}
          >
            {t('Next')}
          </Button>
        </form>
        {(isFacebookLoginEnabled || isLineLoginEnabled) && (
          <Box mt="24px">
            <Text
              fontSize="12px"
              fontWeight={500}
              color="#272D36"
              textAlign="center"
              mb="8px !important"
            >
              {t('sign up with social account')}
            </Text>
            <Center>
              <HStack spacing="12px">
                {isFacebookLoginEnabled && (
                  <FacebookAccount
                    e2eId="sign-up-facebook_icon"
                    currentPanel={PANEL.SIGN_UP}
                    isSubscribed={subscriptionField.value}
                  />
                )}
                {isLineLoginEnabled && (
                  <LineAccount
                    e2eId="sign-up-line_icon"
                    currentPanel={PANEL.SIGN_UP}
                  />
                )}
              </HStack>
            </Center>
          </Box>
        )}
      </Box>
      <TitleText mb="24px">{t('Already a member')}</TitleText>
      <Text mb="24px">{t('Sign in to enjoy more benefits')}</Text>
      <Button
        data-e2e-id="sign-up-sign-in_button"
        isFullWidth
        onClick={handleSignInClick}
        variant="outline"
      >
        {t('Sign In')}
      </Button>
    </>
  );
};

export default StepOne;
