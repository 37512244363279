import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Chevron = createIcon({
  displayName: 'Chevron',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M9.4502 12.9205L4.4502 7.92052L5.51086 6.85986L9.98053 11.3295L14.4502 6.85986L15.5109 7.92052L10.5109 12.9205C10.218 13.2134 9.74309 13.2134 9.4502 12.9205Z"
      fill="currentColor"
    />
  ),
});

export default Chevron;
