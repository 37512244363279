export default {
  translation: {
    'Validation - field - required': '{{ fieldName }} là bắt buộc',
    'Sign Up': 'Đăng ký',
    'Mobile Number': 'Số điện thoại',
    Email: 'Email',
    'Enter your email': 'Nhập email của bạn',
    'Accept to receive store updates':
      'Tôi chấp nhận nhận thông tin cập nhật về cửa hàng, chương trình khuyến mãi và thông tin tiếp thị khác từ {{ shopName }}',
    'Terms of Use and Privacy Policy':
      'Tôi đồng ý <0>với Điều khoản sử dụng</0> và <2>Chính sách bảo mật</2> của cửa hàng',
    'Terms of Use and Privacy Policy and Age Policy':
      "I agree to the store's <0>Term of Use</0> and <2>Privacy Policy</2> and I am over {{ age }}",
    Next: 'Tiếp theo',
    'Already a member': 'Đã là thành viên?',
    'Sign in to enjoy more benefits': 'Đăng nhập để nhận nhiều lợi ích hơn!',
    'Validation - email - required': 'Email là bắt buộc',
    'Validation - email - invalid': 'Email không hợp lệ',
    'Validation - email - max': 'Email quá dài',
    'Validation - mobile phone - required': 'Số điện thoại là bắt buộc',
    'Validation - mobile phone - invalid': 'Số điện thoại di động không hợp lệ',
    'Validation - terms and policy - required':
      'Bạn phải đồng ý với các điều khoản và điều kiện để tiếp tục.',
    'Continue Sign up': 'Đăng ký',
    'Sign In': 'Đăng nhập',
    'Register Status - result - email belongs to guest':
      'E-mail này trước đây đã được sử dụng trong cửa hàng này. Bạn có thể tìm thấy các hướng dẫn để thiết lập tài khoản của bạn trong hộp thư của bạn.',
    'Register Status - result - email belongs to member':
      'Email này đã được đăng ký, hãy chuyển đến trang đăng nhập hoặc sử dụng email khác để đăng ký.',
    'Register Status - result - mobile phone belongs to guest':
      'Số điện thoại này đã được sử dụng ở cửa hàng này. Hướng dẫn thiết lập tài khoản đã được gửi đến điện thoại của bạn.',
    'Register Status - result - mobile phone belongs to member':
      'Số điện thoại này đã được đăng ký, hãy chuyển đến trang đăng nhập hoặc sử dụng số điện thoại khác để đăng ký.',
    'Register Status - result - email and mobile phone both not allowed':
      'Email và số điện thoại đã được đăng ký riêng; vui lòng truy cập trang đăng nhập.',
    'Register Status - result - email is taken':
      'Email đã được sử dụng bởi một người dùng khác',
    'Register Status - result - mobile phone is taken':
      'Số điện thoại đã được sử dụng bởi một người dùng khác',
    'Verify mobile number': 'Xác minh số điện thoại',
    'Verification code': 'Mã xác nhận',
    'Enter verification code': 'Nhập mã xác nhận',
    'Send verification code': 'Gửi mã xác minh',
    'Resend Verification Code': 'Gửi lại mã xác minh',
    'Verification code will be sent after you pass reCAPTCHA':
      'Verification code will be sent after you pass reCAPTCHA',
    'Verification code has been sent to':
      'Verification code has been sent to +{{countryCode}} {{phoneNumber}}',
    'Seconds left': '{{ seconds }} giây',
    Back: 'Quay lại',
    'Validation - pincode - required': 'Mã xác nhận là bắt buộc',
    'Validation - pincode - max length': 'Mã phải gồm {{ maxLength }} ký tự',
    'Validation - pincode - invalid': 'Mã không hợp lệ',
    'Last Step': 'Bước kế tiếp',
    'Please enter your profile':
      'Vui lòng nhập hồ sơ của bạn để hoàn tất đăng ký',
    Username: 'Tên tài khoản',
    'Enter your username': 'Nhập tên người dùng của bạn',
    Password: 'Mật khẩu',
    'Password(at least 8 characters)': 'Mật khẩu (ít nhất 8 ký tự)',
    Gender: 'Giới tính',
    'Gender - male': 'Nam',
    'Gender - female': 'Nữ',
    'Gender - undisclosed': 'Không khai báo',
    Birthday: 'Sinh nhật',
    'Birthday - year': 'YYYY',
    'Birthday - month': 'MM',
    'Birthday - date': 'DD',
    'Validation - birthday - invalid': 'Sinh nhật không hợp lệ',
    'Validation - birthday - min age':
      'Ngày sinh không hợp lệ, phải trên {{ age }} tuổi',
    'Validation - birthday - required': 'Sinh nhật là bắt buộc',
    Optional: '(không bắt buộc)',
    'Sign me up': 'Đăng ký ngay!',
    'Validation - username - required': 'Tên đầy đủ là bắt buộc',
    'Validation - username - too long': 'Họ và tên quá dài',
    'Validation - password - required': 'Mật khẩu là bắt buộc',
    'Validation - password - too short': 'Mật khẩu quá ngắn',
    'Validation - password - too long': 'Mật khẩu quá dài',
    'Email or Mobile Number': 'Email hoặc số di động',
    'Password (8 characters minimum)': 'Mật khẩu (ít nhất 8 ký tự)',
    'Forgot Password': 'Quên mật khẩu?',
    'Lets go': 'Bắt đầu',
    'Not a member': 'Không phải thành viên?',
    'Validation - email and mobile - required':
      'Email hoặc số điện thoại là bắt buộc',
    'Validation - email and mobile - invalid':
      'Email hoặc số điện thoại không hợp lệ',
    'Sign up Success': 'Cảm ơn! Tài khoản của bạn đã được tạo thành công.',
    'Complete member info': 'Hoàn thành thông tin thành viên',
    'Complete Phone Number Notice':
      'Cung cấp số điện thoại để nhận thông tin chiến dịch mới nhất của cửa hàng',
    'Complete Email Notice':
      'Cung cấp Email để nhận thông tin chiến dịch mới nhất của cửa hàng',
    Complete: 'Complete',
    Skip: 'Bỏ qua',
    'sign up with social account': 'hoặc đăng ký bằng tài khoản xã hội',
    'login in with social account': 'Sử dụng tài khoản xã hội',
    'Login - email - label': 'Email',
    'Login - email - placeholder': 'Nhập email của bạn',
    'Login - mobile phone - label': 'Số điện thoại di động',
    'Login - mobile phone - placeholder': 'Nhập số điện thoại của bạn',
    'Login - email or mobile phone - label': 'email hoặc số điện thoại',
    'Login - email or mobile phone - placeholder':
      'Nhập email hoặc số điện thoại của bạn',
    'Login - Error - unconfirmed email':
      'Chào mừng trở lại! Để bảo mật tài khoản của bạn, bạn cần xác minh khi đăng nhập. Email xác minh đã được gửi đến {{email}}. Vui lòng kiểm tra hộp thư của bạn. Cảm ơn!',
    'Member info has been updated': 'Thông tin thành viên đã được cập nhật',
    'Subscription - description':
      'Đăng ký để cập nhật cửa hàng và tin quảng cáo với các nền tảng xã hội',
    'Subscription - LINE - subscribe': 'Đăng ký bằng LINE',
    'Subscription - connection notification - success':
      'Subscribed successfully.',
    'Subscription - LINE - connection notification - failure':
      'Failed to subscribe. Please log in to LINE again.',
    'Verification - email - trial merchant - reach limit - failure':
      'Cửa hàng đang trong giai đoạn dùng thử nên không gửi được email xác minh. Nếu có nhu cầu vui lòng liên hệ với cửa hàng.',
    'Subscription - FACEBOOK - connection notification - failure':
      'Failed to subscribe. Please log in to Facebook again.',
  },
};
