import { extendTheme } from '@chakra-ui/react';
import { merge } from 'lodash-es';
import Alert, { colors as alertColors } from './Alert';
import Button from './Button';
import Checkbox from './Checkbox';
import Input from './Input';
import Select from './Select';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        position: 'static',
      },
      svg: {
        // Use SVG default display
        display: 'inline',
      },
    },
  },
  colors: merge({}, alertColors),
  breakpoints: {
    sm: '768px',
    md: '992px',
    lg: '1200px',
  },
  sizes: {
    'signup-login-page': {
      container: {
        desktop: '460px',
      },
    },
  },
  components: {
    Alert,
    Checkbox,
    Input,
    Button,
    Select,
  },
});

export default theme;
