import { useState, useEffect, useMemo, useCallback } from 'react';
import benchatService from '../../../service/benchatService';

/**
 * @typedef Params
 * @prop {string} userId - when login, it is current user ID, or request session ID
 * @prop {string} channelOwnerId - merchant ID
 * @prop {(BenchatSubscription[]): BenchatSubscription} queryFilter - filter the get results after query
 */

/**
 * Manage the CRUD of benchat subscriptions
 * Using hook is for data store
 * @param {Params} param
 */
function useBenchatSubscriptions({ userId, channelOwnerId, queryFilter }) {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const userRef = useMemo(() => benchatService.generateUserRef(userId), [
    userId,
  ]);

  const generateData = useCallback(
    ({ topic, platform } = {}) => ({
      userId,
      channelOwnerId,
      userRef,
      platform,
      topic,
    }),
    [channelOwnerId, userRef, userId],
  );

  const getSubscriptions = useCallback(async () => {
    setIsLoaded(false);
    let subscriptions = [];
    try {
      const res = await benchatService.getSubscriptions(userId);
      subscriptions = queryFilter(res.data?.subscriptions ?? []);
      setSubscriptions(subscriptions);
    } catch (e) {
      setSubscriptions(subscriptions);
    } finally {
      setIsLoaded(true);
    }
    return subscriptions;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const patch = useCallback(async (subscriptions) => {
    const res = await benchatService.patchSubscriptions(subscriptions);
    return res?.data ?? [];
  }, []);

  const activate = useCallback(
    (subscriptions) =>
      Promise.all(
        subscriptions.map((item) =>
          benchatService.activateSubscription(item._id, item),
        ),
      ),
    [],
  );

  const deactivate = useCallback(
    (subscriptions) =>
      Promise.all(
        subscriptions.map((item) =>
          benchatService.deactivateSubscription(item._id),
        ),
      ),
    [],
  );

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions, userId]);

  return {
    subscriptions,
    isLoaded,
    userRef,
    get: getSubscriptions,
    patch,
    activate,
    deactivate,
    generateData,
  };
}

export default useBenchatSubscriptions;
