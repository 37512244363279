import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@chakra-ui/react';
import benchatService from '../../../../../service/benchatService';
import { Line as LineIcon } from '../../../../context/Chakra/Icons';
import { PANEL } from '../../../constants/panel';
import { useAppContext } from '../../../context/appContext';
import {
  EVENT_CATEGORY_FOR_USER_FLOW,
  VALUE_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
  EVENT_NAME_FOR_USER_FLOW,
} from '../../../../../shop/constants/signupLoginEventsTracking';
import trackerService from '../../../../../service/trackerService';

const LineAccount = ({ currentPanel, e2eId }) => {
  const { lineLoginChannel } = useAppContext();
  const url = benchatService.getLineLoginUri(
    lineLoginChannel?.platformChannelId,
  );

  const handleClick = () => {
    if (currentPanel !== PANEL.SIGN_UP) return;

    trackerService.track({
      type: EVENT_NAME_FOR_USER_FLOW.ThirdPartySignUpClick,
      data: {
        event_category: EVENT_CATEGORY_FOR_USER_FLOW.SignUp,
        property: PROPERTY_FOR_USER_FLOW.sign_up_option,
        value: VALUE_FOR_USER_FLOW.line,
      },
    });
  };

  return (
    <Link href={url}>
      <LineIcon
        data-e2e-id={e2eId}
        color="#06C755"
        boxSize="40px"
        onClick={handleClick}
      />
    </Link>
  );
};

LineAccount.propTypes = {
  currentPanel: PropTypes.oneOf([PANEL.LOGIN, PANEL.SIGN_UP]).isRequired,
  e2eId: PropTypes.string,
};

export default LineAccount;
