import React from 'react';
import { Icon } from '@chakra-ui/react';

function CheckCircle(props) {
  return (
    <Icon viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M12.1979 7.63542L7.80208 12.0312C7.67014 12.1632 7.51389 12.2292 7.33333 12.2292C7.15278 12.2292 6.99653 12.1632 6.86458 12.0312L3.80208 8.96875C3.67014 8.83681 3.60417 8.68056 3.60417 8.5C3.60417 8.31944 3.67014 8.16319 3.80208 8.03125L4.86458 6.96875C4.99653 6.83681 5.15278 6.77083 5.33333 6.77083C5.51389 6.77083 5.67014 6.83681 5.80208 6.96875L7.33333 8.5L10.1979 5.63542C10.3299 5.50347 10.4861 5.4375 10.6667 5.4375C10.8472 5.4375 11.0035 5.50347 11.1354 5.63542L12.1979 6.69792C12.3299 6.82986 12.3958 6.98611 12.3958 7.16667C12.3958 7.34722 12.3299 7.50347 12.1979 7.63542ZM12.9062 11.3437C13.4132 10.4757 13.6667 9.52778 13.6667 8.5C13.6667 7.47222 13.4132 6.52431 12.9062 5.65625C12.3993 4.78819 11.7118 4.10069 10.8437 3.59375C9.97569 3.08681 9.02778 2.83333 8 2.83333C6.97222 2.83333 6.0243 3.08681 5.15625 3.59375C4.28819 4.10069 3.60069 4.78819 3.09375 5.65625C2.58681 6.52431 2.33333 7.47222 2.33333 8.5C2.33333 9.52778 2.58681 10.4757 3.09375 11.3437C3.60069 12.2118 4.28819 12.8993 5.15625 13.4062C6.0243 13.9132 6.97222 14.1667 8 14.1667C9.02778 14.1667 9.97569 13.9132 10.8437 13.4062C11.7118 12.8993 12.3993 12.2118 12.9062 11.3437ZM14.9271 4.48958C15.6424 5.71181 16 7.04861 16 8.5C16 9.95139 15.6424 11.2917 14.9271 12.5208C14.2118 13.7431 13.2396 14.7118 12.0104 15.4271C10.7882 16.1424 9.45139 16.5 8 16.5C6.54861 16.5 5.20833 16.1424 3.97917 15.4271C2.75694 14.7118 1.78819 13.7431 1.07292 12.5208C0.357639 11.2917 0 9.95139 0 8.5C0 7.04861 0.357639 5.71181 1.07292 4.48958C1.78819 3.26042 2.75694 2.28819 3.97917 1.57292C5.20833 0.857639 6.54861 0.5 8 0.5C9.45139 0.5 10.7882 0.857639 12.0104 1.57292C13.2396 2.28819 14.2118 3.26042 14.9271 4.48958Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export default CheckCircle;
