import parsePhoneNumber from 'libphonenumber-js/min';

/**
 * Handle phone number with country code
 * @date 2023-03-17
 * @param {Object} arg
 * @param {String} arg.mobilePhone
 * @param {String} arg.country
 * @returns {String} from libphonenumber-js/min
 */
export const formatPhoneNumber = ({ mobilePhone, country }) => {
  return parsePhoneNumber(mobilePhone, country.toUpperCase())
    .formatNational()
    .replace(/[^\d]/g, '');
};
