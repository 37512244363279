import React from 'react';
import { createIcon } from '@chakra-ui/react';

const User = createIcon({
  displayName: 'User',
  viewBox: '0 0 20 20',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9999 14.9998V14.1798C14.9999 13.6598 14.6929 13.1868 14.0799 12.7598C13.5599 12.3998 12.8799 12.1128 12.0399 11.8998C11.2929 11.6998 10.6129 11.5998 9.99995 11.5998C9.38695 11.5998 8.70695 11.6998 7.95995 11.8998C7.11995 12.1128 6.43995 12.3998 5.91995 12.7598C5.30695 13.1868 4.99995 13.6598 4.99995 14.1798V14.9998H14.9999ZM9.99995 4.99982C9.55995 4.99982 9.14695 5.11282 8.75995 5.33982C8.37849 5.56127 8.06139 5.87837 7.83995 6.25982C7.61295 6.64682 7.49995 7.05982 7.49995 7.49982C7.49995 7.93982 7.61295 8.35282 7.83995 8.73982C8.06695 9.12682 8.37295 9.43282 8.75995 9.65982C9.14695 9.88682 9.55995 9.99982 9.99995 9.99982C10.4399 9.99982 10.8529 9.88682 11.2399 9.65982C11.6269 9.43282 11.9329 9.12682 12.1599 8.73982C12.3869 8.35282 12.4999 7.93982 12.4999 7.49982C12.4999 7.05982 12.3869 6.64682 12.1599 6.25982C11.9385 5.87837 11.6214 5.56127 11.2399 5.33982C10.8529 5.11282 10.4399 4.99982 9.99995 4.99982ZM15.8199 2.49982C16.2729 2.49982 16.6669 2.66682 16.9999 2.99982C17.3329 3.33282 17.4999 3.72682 17.4999 4.17982V15.8198C17.4999 16.2728 17.3329 16.6668 16.9999 16.9998C16.6669 17.3328 16.2729 17.4998 15.8199 17.4998H4.17995C3.71295 17.4998 3.31695 17.3328 2.98995 16.9998C2.83301 16.8463 2.70871 16.6626 2.62451 16.4598C2.54031 16.2571 2.49794 16.0394 2.49995 15.8198V4.17982C2.49995 3.72682 2.66295 3.33282 2.98995 2.99982C3.31695 2.66682 3.71295 2.49982 4.17995 2.49982H4.99995V0.819824H6.67995V2.49982H13.3199V0.819824H14.9999V2.49982H15.8199Z"
      fill="currentColor"
    />
  ),
});

export default User;
