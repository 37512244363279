import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Facebook = createIcon({
  displayName: 'Facebook',
  viewBox: '0 0 41 40',
  path: (
    <>
      <g clipPath="url(#clip0_341_25823)">
        <path
          d="M20.5 0C9.45556 0 0.5 9.00182 0.5 20.1033C0.5 30.1829 7.88889 38.5034 17.5111 39.9609V25.4307H12.5611V20.148H17.5111V16.6299C17.5111 10.8055 20.3333 8.25352 25.1444 8.25352C27.45 8.25352 28.6722 8.42664 29.2444 8.50482V13.1174H25.9611C23.9167 13.1174 23.2056 15.0663 23.2056 17.2609V20.148H29.1944L28.3833 25.4307H23.2056V40C32.9667 38.6709 40.5 30.2834 40.5 20.1033C40.5 9.00182 31.5444 0 20.5 0Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_341_25823">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </>
  ),
});

export default Facebook;
