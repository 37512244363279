import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Stack } from '@chakra-ui/react';
import { Select } from '../Select';
import { InputLabel } from '../Input/Input';
import useBirthdayPicker from '../../../../hooks/useBirthdayPicker';

const BIRTHDAY_KEY = {
  year: 'year',
  month: 'month',
  date: 'date',
};

const BirthdayPicker = (props) => {
  const {
    value,
    onChange,
    birthdayFormat,
    isDisabled = false,
    isInvalid,
    label,
  } = props;

  const { t } = useTranslation();
  const {
    shouldShowDate,
    shouldShowYear,
    yearOptions,
    monthOptions,
    dateOptions,
    EMPTY_OPTION,
  } = useBirthdayPicker({ value, onChange, birthdayFormat });

  const handleChange = (key) => (event) => {
    const val = event.target.value;
    onChange({ ...value, [key]: val === EMPTY_OPTION ? null : Number(val) });
  };

  const selectList = [
    {
      key: BIRTHDAY_KEY.year,
      text: t('Birthday - year'),
      options: yearOptions,
      shouldShow: shouldShowYear,
    },
    {
      key: BIRTHDAY_KEY.month,
      text: t('Birthday - month'),
      options: monthOptions,
      shouldShow: true,
    },
    {
      key: BIRTHDAY_KEY.date,
      text: t('Birthday - date'),
      options: dateOptions,
      shouldShow: shouldShowDate,
    },
  ];

  return (
    <>
      <InputLabel label={label} isInvalid={isInvalid} mb="0" />
      <Stack direction="row" gap="15px">
        {selectList.map(
          ({ key, text, options, shouldShow }) =>
            shouldShow && (
              <Select
                key={key}
                value={value[key]}
                onChange={handleChange(key)}
                isDisabled={isDisabled}
                isInvalid={isInvalid}
              >
                <option hidden value={null}>
                  {text}
                </option>
                {options.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </Select>
            ),
        )}
      </Stack>
    </>
  );
};

BirthdayPicker.propTypes = {
  value: PropTypes.shape({
    year: PropTypes.number,
    month: PropTypes.number,
    date: PropTypes.number,
  }),
  onChange: PropTypes.func,
  birthdayFormat: PropTypes.string,
  isDisabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  label: PropTypes.string,
};

export default BirthdayPicker;
