import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Check = createIcon({
  displayName: 'Check',
  viewBox: '0 0 11 8',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1185 1.38799C10.1966 1.46609 10.1966 1.59273 10.1185 1.67083L4.75988 7.02941C4.46699 7.3223 3.99211 7.3223 3.69922 7.02941L0.34064 3.67083C0.262535 3.59273 0.262535 3.46609 0.34064 3.38799L1.11846 2.61017C1.19656 2.53207 1.3232 2.53207 1.4013 2.61017L4.22955 5.43842L9.0578 0.610171C9.1359 0.532066 9.26254 0.532066 9.34064 0.610171L10.1185 1.38799Z"
      fill="currentColor"
    />
  ),
});

export default Check;
