export const CLASSNAME = {
  DESCRIPTION: 'description',
  CUSTOM_ERROR_TEXT: 'chakra-custom_error_text',
  CUSTOM_ERROR_BORDER: 'chakra-custom_error_border',
  ICON_PASSWORD_VISIBILITY: 'icon-password-visibility',
};

export const FONT_SIZE = {
  INPUT: {
    LABEL: '1.2rem',
    ERROR_MESSAGE: '1.2rem',
  },
};

export const COLOR = {
  ERROR: '#FF4C4C',
};
