import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import { Alert, TitleText } from '../../../components';
import { useAppContext } from '../../../context/appContext';
import {
  EVENT_CATEGORY_FOR_USER_FLOW,
  EVENT_NAME_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
} from '../../../../../shop/constants/signupLoginEventsTracking';
import trackerService from '../../../../../service/trackerService';

const StepSuccess = () => {
  const { userSetting } = useAppContext();
  const { t } = useTranslation();

  useEffect(() => {
    trackerService.track({
      type: EVENT_NAME_FOR_USER_FLOW.MemberSignUpSucceed,
      data: {
        event_category: EVENT_CATEGORY_FOR_USER_FLOW.SignUp,
        property: PROPERTY_FOR_USER_FLOW.sign_up_option,
        value: userSetting?.signup_method,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TitleText mb="32px">{t('Sign In')}</TitleText>
      <Alert status="success">{t('Sign up Success')}</Alert>
    </Box>
  );
};

export default StepSuccess;
