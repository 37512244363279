import React, { useState, useEffect, useMemo } from 'react';
import { Box, HStack, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import { getCountryCallingCode } from 'libphonenumber-js/max';
import { useFormContext, useController } from 'react-hook-form';
import { RECAPTCHA_TOKEN_FIELD } from '../../../../../constants/recaptchable';
import { useCountdownTimer } from '../../../../../hooks';
import {
  EVENT_CATEGORY_FOR_USER_FLOW,
  VALUE_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
  EVENT_NAME_FOR_USER_FLOW,
} from '../../../../../shop/constants/signupLoginEventsTracking';
import { useAppContext } from '../../../context/appContext';
import authenticationService from '../../../../../service/authenticationService';
import trackerService from '../../../../../service/trackerService';
import {
  PINCODE_COUNT_DOWN_TIME,
  PINCODE_LENGTH,
} from '../../../constants/pincode';
import extractServerErrorMessages from '../../../utils/extractServerErrorMessages';
import { useRecaptcha } from '../../../hooks';
import {
  Input,
  Button,
  TitleText,
  ErrorBanner,
  ErrorText,
  RecaptchaCheckbox,
} from '../../../components';
import { InputLabel } from '../../../components/Input/Input';

// eslint-disable-next-line react/prop-types
const StepTwo = ({ handleNextStep, handlePrevStep }) => {
  const { userSetting } = useAppContext();
  const { t } = useTranslation();
  const {
    errors,
    control,
    handleSubmit,
    setError,
    getValues,
  } = useFormContext();
  const { field: pincodeField } = useController({
    name: 'pincode',
    control,
    defaultValue: '',
  });

  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isVerifyingCode, setIsVerifyingCode] = useState(false);
  const [hasSentCode, setHasSentCode] = useState(true);
  const [sendCodeErrors, setSendCodeErrors] = useState([]);
  const { timeLeft, hasFinished, start: startTimer } = useCountdownTimer({
    defaultSeconds: PINCODE_COUNT_DOWN_TIME,
  });
  const countryCode = getCountryCallingCode(getValues('phone_country'));
  const phoneNumber = getValues('mobile_phone');

  const handleSendCode = async () => {
    // tracks the number of times a user clicks the 'Resend Code' button on the initial sign-up page.
    trackerService.track({
      type: EVENT_NAME_FOR_USER_FLOW.SMSVerificationSendButtonClick,
      data: {
        event_category: EVENT_CATEGORY_FOR_USER_FLOW.UserVerification,
        property: PROPERTY_FOR_USER_FLOW.purpose,
        value: VALUE_FOR_USER_FLOW.signup_resend,
      },
    });

    try {
      const token = await getRecaptchaToken();
      refReCAPTCHA.current?.reset();
      setIsSendingCode(true);
      setSendCodeErrors([]);
      if (isShowCheckboxRecaptcha) {
        setIsShowCheckboxRecaptcha(false);
      }
      startTimer();
      const payload = {
        country_calling_code: countryCode,
        mobile_phone: phoneNumber,
        is_forget_password: false,
        [RECAPTCHA_TOKEN_FIELD]: token,
        ...getRecaptchaCheckboxPayload(),
      };
      await authenticationService.sendSignUpCode(payload);
      setHasSentCode(true);

      // tracking user clicks resend code button
      trackerService.track({
        type: EVENT_NAME_FOR_USER_FLOW.SMSVerificationCodeSend,
        data: {
          event_category: EVENT_CATEGORY_FOR_USER_FLOW.UserVerification,
          property: PROPERTY_FOR_USER_FLOW.purpose,
          value: VALUE_FOR_USER_FLOW.signup_resend,
        },
      });
    } catch (e) {
      const data = e?.response?.data ?? {};
      if (isRecaptchaError({ errorCode: data?.error_code })) {
        refCheckboxReCAPTCHA.current?.reset();
        setIsShowCheckboxRecaptcha(true);
      } else {
        setSendCodeErrors(extractServerErrorMessages(data));
      }
    } finally {
      setIsSendingCode(false);
      setRecaptchaCheckboxToken('');
    }
  };

  const handleVerify = async () => {
    try {
      setIsVerifyingCode(true);
      const payload = {
        country_calling_code: getCountryCallingCode(getValues('phone_country')),
        mobile_phone: getValues('mobile_phone'),
        code: pincodeField.value,
      };
      await authenticationService.verifyCode(payload);

      // tracks which sign-up method a user uses when submitting a verification code.
      await trackerService.track({
        type: EVENT_NAME_FOR_USER_FLOW.SMSVerificationSubmit,
        data: {
          event_category: EVENT_CATEGORY_FOR_USER_FLOW.UserVerification,
          property: PROPERTY_FOR_USER_FLOW.sign_up_option,
          value: userSetting?.signup_method,
        },
      });

      handleNextStep();
    } catch (error) {
      setError('pincode', {
        type: 'custom',
        message: error?.response?.data?.error_messages.join(),
      });
    } finally {
      setIsVerifyingCode(false);
    }
  };

  const onSubmit = () => {
    if (!hasSentCode) {
      return;
    }
    handleVerify();
  };

  const {
    refReCAPTCHA,
    refCheckboxReCAPTCHA,
    setRecaptchaCheckboxToken,
    isRecaptchaError,
    getRecaptchaToken,
    getRecaptchaCheckboxPayload,
    isShowCheckboxRecaptcha,
    setIsShowCheckboxRecaptcha,
    onRecaptchaCheckboxCallback,
  } = useRecaptcha({ callback: handleSendCode });

  const isDisabledSendBtn = useMemo(
    () => hasSentCode && !hasFinished && !isShowCheckboxRecaptcha,
    [hasFinished, hasSentCode, isShowCheckboxRecaptcha],
  );

  const buildSendSmsBtnText = () => {
    if (isDisabledSendBtn) {
      return t('Seconds left', { seconds: timeLeft });
    } else {
      return t('Resend Verification Code');
    }
  };

  useEffect(() => {
    if (!hasSentCode || !isEmpty(errors?.pincode?.message)) {
      return;
    }
    if (pincodeField.value.length === PINCODE_LENGTH) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pincodeField.value, hasSentCode]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(startTimer, []);

  useEffect(() => {
    trackerService.track({
      type: EVENT_NAME_FOR_USER_FLOW.SMSVerificationFormShow,
      data: {
        event_category: EVENT_CATEGORY_FOR_USER_FLOW.UserVerification,
        property: PROPERTY_FOR_USER_FLOW.verification_type,
        value: userSetting?.signup_method,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box>
        <TitleText mb="32px">{t('Verify mobile number')}</TitleText>
        <ErrorBanner errorMessages={sendCodeErrors} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <HStack gap="12px" alignItems="flex-end">
            <Box flex="1">
              <InputLabel
                label={t('Verification code has been sent to', {
                  countryCode,
                  phoneNumber,
                })}
                mb="2px"
              />
              <Input
                type="text"
                inputmode="numeric"
                autocomplete="one-time-code"
                maxLength={PINCODE_LENGTH}
                pattern={`\\d{${PINCODE_LENGTH}}`}
                placeholder={t('Enter verification code')}
                label={t('Verification code')}
                value={pincodeField.value}
                onChange={(e) => pincodeField.onChange(e.target.value)}
                isInvalid={!isEmpty(errors?.pincode?.message)}
                errorMessage={
                  errors?.pincode?.message?.translateKey
                    ? t(
                        errors?.pincode?.message?.translateKey,
                        errors?.pincode?.message?.params,
                      )
                    : errors?.pincode?.message
                }
              />
            </Box>
            <Button
              data-e2e-id="sms-verification-send-code_button"
              onClick={handleSendCode}
              variant="outlineSendCode"
              size="sm"
              minWidth="80px"
              isDisabled={isDisabledSendBtn}
              mb={
                !isEmpty(errors?.pincode?.message) ? '15px !important' : '0px'
              }
              isLoading={isSendingCode}
            >
              {buildSendSmsBtnText()}
            </Button>
          </HStack>
          <RecaptchaCheckbox
            refReCAPTCHA={refReCAPTCHA}
            refCheckboxReCAPTCHA={refCheckboxReCAPTCHA}
            isShowCheckboxRecaptcha={isShowCheckboxRecaptcha}
            onRecaptchaCheckboxCallback={onRecaptchaCheckboxCallback}
            isForceShowRecaptcha={true}
            errorText={
              <ErrorText as="span" mt="6px">
                {t('Verification code will be sent after you pass reCAPTCHA')}
              </ErrorText>
            }
          />
          <Button
            data-e2e-id="sms-verification-verify-submit_button"
            isFullWidth
            variant="solid"
            mt="32px"
            onClick={handleSubmit(onSubmit)}
            isDisabled={!hasSentCode || !isEmpty(errors?.pincode?.message)}
            isLoading={isVerifyingCode}
          >
            {t('Next')}
          </Button>
        </form>
        <Center mt="64px">
          <Button onClick={handlePrevStep} variant="link" size="sm">
            {t('Back')}
          </Button>
        </Center>
      </Box>
    </>
  );
};

export default StepTwo;
