import React from 'react';
import { createIcon } from '@chakra-ui/react';

const CrownCircle = createIcon({
  displayName: 'CrownCircle',
  viewBox: '0 0 20 20',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 10C2 5.578 5.57861 2 10 2C14.422 2 18 5.57861 18 10C18 14.422 14.4214 18 10 18C5.578 18 2 14.4214 2 10ZM3.25 10C3.25 13.7311 6.26941 16.75 10 16.75C13.7311 16.75 16.75 13.7306 16.75 10C16.75 6.26892 13.7306 3.25 10 3.25C6.26892 3.25 3.25 6.26941 3.25 10ZM13.5507 8.06693C13.6625 8.01535 13.7958 8.03147 13.8903 8.10796C13.9849 8.18444 14.0226 8.30679 13.9865 8.41953L12.799 12.1232C12.759 12.248 12.6376 12.3333 12.5 12.3333H7.5C7.36243 12.3333 7.24103 12.248 7.20102 12.1232L6.01352 8.41951C5.97738 8.30677 6.01513 8.18443 6.10966 8.10794C6.20419 8.03145 6.33753 8.01535 6.4493 8.06692L8.15936 8.85625L9.76393 7.10216C9.82328 7.03727 9.90941 7 10 7C10.0906 7 10.1767 7.03727 10.2361 7.10216L11.8406 8.85627L13.5507 8.06693Z"
      fill="currentColor"
    />
  ),
});

export default CrownCircle;
