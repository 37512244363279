const appBrowserRegex = new RegExp('FBAN|FBAV|Line|Instagram|MicroMessenger');

export function isInAppBrowser() {
  const userAgent = window?.navigator?.userAgent ?? '';
  return appBrowserRegex.test(userAgent);
}

export function isSafariMobileBrowser() {
  const userAgent = window?.navigator?.userAgent ?? '';
  return /Safari/.test(userAgent) && /iPad|iPhone/.test(userAgent);
}

export function isMobile() {
  return window?.matchMedia('(max-width: 480px)')?.matches;
}

export function isPad() {
  return window?.matchMedia('(max-width: 767px)')?.matches;
}

export default {
  isInAppBrowser,
  isSafariMobileBrowser,
  isMobile,
  isPad,
};
