/* eslint-disable react/prop-types */
import React from 'react';
import { Select as ChakraSelect } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import { Chevron } from '../../../context/Chakra/Icons';
import { InputLabel } from '../Input/Input';

const Select = (props) => {
  const { label, isInvalid } = props;
  return (
    <>
      {!isEmpty(label) && (
        <InputLabel label={label} isInvalid={isInvalid} mb={0} />
      )}
      <ChakraSelect icon={<Chevron />} iconSize="20px" {...props} />
    </>
  );
};
export default Select;
