const Checkbox = {
  baseStyle: {
    container: {
      marginBottom: '0',
    },
    label: {
      color: '#c0c0c0',
      fontWeight: 500,
    },
    control: {
      backgroundColor: 'white',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#ececec',
      borderRadius: '4px',
      alignSelf: 'flex-start',
      _checked: {
        color: '#356dff',
        borderColor: '#356dff',
        backgroundColor: 'white',
        _hover: {
          color: '#356dff',
          borderColor: '#356dff',
          backgroundColor: 'white',
        },
      },
      _hover: {
        color: '#356dff',
        borderColor: '#356dff',
        backgroundColor: 'white',
      },
      _focus: {
        boxShadow: 'none',
      },
      _disabled: {
        color: '#c0c0c0 !important',
        backgroundColor: '#ececec !important',
        borderColor: '#ececec !important',
      },
    },
  },
  sizes: {
    md: {
      label: {
        fontSize: '12px',
        lineHeight: '16px',
      },
      control: {
        marginTop: '1px',
        width: '16px',
        height: '16px',
      },
    },
  },
};

export default Checkbox;
