import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_TYPE, PLATFORM } from '../../../../../constants/benchat';
import useGlobalSDK from '../../../../../hooks/useGlobalSDK';
import { notify as pnotifyNotify } from '../../../../../service/pnotifyService';
import { UseBenchatSubscriptionsPropTypes } from '../../../utils/propTypes';
import FacebookComponent from './FacebookComponent';

function FacebookSubscription({ appId, platformChannelId, topics, benchat }) {
  const FB = useGlobalSDK('FB');
  const { t } = useTranslation();
  const refDiv = useRef();
  const origin = window.location.origin.replace(/^http:\/\//, /^https:\/\//);

  useEffect(() => {
    setTimeout(function () {
      FB.XFBML.parse();
    }, 0);
  }, [FB]);

  useEffect(
    () => {
      const subscribeFacebookEvent = async (e) => {
        if (e.event === 'opt_in') {
          try {
            const patchedSubscriptions = await benchat.patch(
              topics.map((topic) =>
                benchat.generateData({
                  platform: PLATFORM.FACEBOOK,
                  topic,
                }),
              ),
            );
            await benchat.activate(patchedSubscriptions);
            await benchat.get();
            pnotifyNotify(
              t('Subscription - connection notification - success'),
            );
          } catch (error) {
            // handle unexpected error
            console.error(error);
            pnotifyNotify(
              t('Subscription - FACEBOOK - connection notification - failure'),
              {
                customClass: 'error',
                icon: 'fa fa-exclamation-triangle',
              },
            );
          }
        }
      };

      FB.Event.subscribe('send_to_messenger', subscribeFacebookEvent);

      return () => {
        FB.Event.unsubscribe('send_to_messenger', subscribeFacebookEvent);
      };
    },
    // It confirmed that userId, channelOwnerId are all from server
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <FacebookComponent
      ref={refDiv}
      size="xlarge"
      color="white"
      appId={appId}
      pageId={platformChannelId}
      origin={origin}
      dataRef={`${benchat.userRef}-${NOTIFICATION_TYPE.MARKETING}`}
    />
  );
}

FacebookSubscription.propTypes = {
  /** Facebook App ID */
  appId: PropTypes.string.isRequired,
  platformChannelId: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(PropTypes.string).isRequired,
  benchat: UseBenchatSubscriptionsPropTypes,
};

export default FacebookSubscription;
