import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Text, Portal, Box, Stack } from '@chakra-ui/react';
import { Button } from '../../../components';

const RegisterStatusPopup = (props) => {
  const { t } = useTranslation();
  const { errorMessage, handleRegisterClick, handleLoginClick } = props;

  return (
    <Portal>
      <Box
        position="fixed"
        bottom={0}
        left={0}
        right={0}
        padding={{ base: '15px ', sm: '15px 0' }}
        maxW={{ base: 'auto', sm: 'signup-login-page.container.desktop' }}
        margin="auto"
      >
        <Box
          backgroundColor="rgba(0, 0, 0, 0.7)"
          borderWidth="1px"
          borderColor="#fff"
          boxShadow="0px 0px 30px rgba(0, 0, 0, 0.25)"
          borderRadius="12px"
          padding="24px 16px"
          backdropFilter="blur(2px)"
        >
          <Text
            fontSize="14px"
            color="#fff"
            mb="24px"
            className="chakra-error_message"
          >
            {errorMessage}
          </Text>
          <Stack spacing="10px" direction="row">
            <Button
              variant="outlineWhite"
              flex={1}
              color="white"
              borderColor="white"
              onClick={handleRegisterClick}
            >
              {t('Continue Sign up')}
            </Button>
            <Button variant="solidWhite" flex={1} onClick={handleLoginClick}>
              {t('Sign In')}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Portal>
  );
};

RegisterStatusPopup.propTypes = {
  errorMessage: PropTypes.string,
  handleRegisterClick: PropTypes.func,
  handleLoginClick: PropTypes.func,
};

export default RegisterStatusPopup;
