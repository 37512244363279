import { COLOR } from '../constants/style';

const Input = {
  sizes: {
    md: {
      field: {
        paddingBottom: '12px',
        paddingRight: '26px',
        h: '26px',
        fontSize: '1.4rem',
      },
    },
  },
  baseStyle: {
    field: {
      color: '#272D36',
      _placeholder: {
        color: '#888',
      },
      boxShadow: 'none !important',
    },
  },
  defaultProps: {
    focusBorderColor: '#EDEDED',
    errorBorderColor: COLOR.ERROR,
  },
};

export default Input;
