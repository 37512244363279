export const registerStatus = {
  ALLOW_CREATE_MEMBER: 'ALLOW_CREATE_MEMBER',
  EMAIL_BELONGS_TO_EXISTING_GUEST: 'EMAIL_BELONGS_TO_EXISTING_GUEST',
  EMAIL_BELONGS_TO_EXISTING_MEMBER: 'EMAIL_BELONGS_TO_EXISTING_MEMBER',
  MOBILE_PHONE_BELONGS_TO_EXISTING_GUEST:
    'MOBILE_PHONE_BELONGS_TO_EXISTING_GUEST',
  MOBILE_PHONE_BELONGS_TO_EXISTING_MEMBER:
    'MOBILE_PHONE_BELONGS_TO_EXISTING_MEMBER',
  EMAIL_AND_MOBILE_PHONE_BOTH_NOT_ALLOWED:
    'EMAIL_AND_MOBILE_PHONE_BOTH_NOT_ALLOWED',
};

export const registerResult = {
  NEW: 'new',
  GUEST: 'guest',
  MEMBER: 'member',
};

export const registrationErrorMessageMap = {
  [registerStatus.ALLOW_CREATE_MEMBER]: '',
  [registerStatus.EMAIL_BELONGS_TO_EXISTING_GUEST]:
    'Register Status - result - email is taken',
  [registerStatus.EMAIL_BELONGS_TO_EXISTING_MEMBER]:
    'Register Status - result - email is taken',
  [registerStatus.MOBILE_PHONE_BELONGS_TO_EXISTING_GUEST]:
    'Register Status - result - mobile phone is taken',
  [registerStatus.MOBILE_PHONE_BELONGS_TO_EXISTING_MEMBER]:
    'Register Status - result - mobile phone is taken',
  [registerStatus.EMAIL_AND_MOBILE_PHONE_BOTH_NOT_ALLOWED]:
    'Register Status - result - email and mobile phone both not allowed',
};
