import { useState, useEffect } from 'react';

function useGlobalSDK(sdkName) {
  const [sdk, setSDK] = useState(() => window?.[sdkName]);

  useEffect(() => {
    if (!sdk) {
      const unsubscribe = window.globalSDKObserver?.[sdkName]?.subscribe?.(
        setSDK,
      );
      return unsubscribe;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdk]);

  return sdk;
}

export default useGlobalSDK;
