import * as yup from 'yup';
import {
  validateMailAndMobile,
  validateEmail,
  validatePhoneWithCountry,
} from '../../../utils/validators';
import { PINCODE_LENGTH } from '../constants/pincode';

export const emailSchema = yup
  .string()
  .trim()
  .max(50, 'Validation - email - max')
  .test('custom', 'Validation - email - invalid', validateEmail)
  .required('Validation - email - required');

export const phoneCountrySchema = yup.string();

export const mobilePhoneSchema = yup
  .string()
  .required('Validation - mobile phone - required')
  .test('custom', 'Validation - mobile phone - invalid', (value, context) => {
    return validatePhoneWithCountry({
      phoneCountry: context.parent.phone_country,
      number: value,
    });
  });

export const acceptPolicySchema = yup
  .boolean()
  .oneOf([true], 'Validation - terms and policy - required');

export const pincodeSchema = yup
  .string()
  .required({ translateKey: 'Validation - pincode - required' })
  .length(PINCODE_LENGTH, {
    translateKey: 'Validation - pincode - max length',
    params: { maxLength: PINCODE_LENGTH },
  })
  .matches(new RegExp(`\\d{${PINCODE_LENGTH}}`), {
    message: {
      translateKey: 'Validation - pincode - invalid',
    },
  });

export const emailAndMobileSchema = yup
  .string()
  .required('Validation - email and mobile - required')
  .test('custom', 'Validation - email and mobile - invalid', (value) =>
    validateMailAndMobile(value),
  );

export const nameSchema = yup
  .string()
  .trim()
  .required('Validation - username - required')
  .max(50, 'Validation - username - too long');

export const passwordSchema = yup
  .string()
  .required('Validation - password - required')
  .min(8, 'Validation - password - too short')
  .max(50, 'Validation - password - too long');

export const genderSchema = yup.string().required({
  translateKey: 'Validation - field - required',
  params: { fieldName: 'Gender' },
});

export const birthdaySchema = yup.object().shape({
  year: yup.number().nullable(),
  month: yup.number().nullable(),
  date: yup.number().nullable(),
});

export const generateCustomFieldsSchema = (customFields) => {
  return customFields
    ?.filter((customField) => customField.options.signup.include === 'true')
    ?.reduce((accu, curr) => {
      accu[curr.field_id] =
        curr.options.signup.required === 'true'
          ? yup.string().required({
              translateKey: 'Validation - field - required',
              fieldName: curr.name_translations,
            })
          : yup.string();
      return accu;
    }, {});
};
