/* istanbul ignore file */
import PropTypes from 'prop-types';
import { values } from 'lodash-es';
import { SIGNUP_METHOD } from '../../../constants/userSetting';

export const merchantDataPropTypes = PropTypes.shape({
  base_country_code: PropTypes.string,
});

export const userSettingPropTypes = PropTypes.shape({
  signup_method: PropTypes.oneOf(values(SIGNUP_METHOD)),
});

export const localeDataPropTypes = PropTypes.shape({
  loadedLanguage: PropTypes.shape({
    code: PropTypes.string,
  }),
});

export const BenchatSubscriptionPropTypes = PropTypes.shape({
  /** when login, it is current user ID, or request session ID */
  userId: PropTypes.string,
  /** userId with random string */
  userRef: PropTypes.string,
  /** usually merchant ID */
  channelOwnerId: PropTypes.string,
  platform: PropTypes.string,
  topic: PropTypes.string,
});

export const UseBenchatSubscriptionsPropTypes = PropTypes.shape({
  subscriptions: PropTypes.arrayOf(BenchatSubscriptionPropTypes),
  isLoaded: PropTypes.bool,
  get: PropTypes.func,
  patch: PropTypes.func,
  activate: PropTypes.func,
  deactivate: PropTypes.func,
  generateData: PropTypes.func,
});
