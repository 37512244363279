import { cond, matches, constant } from 'lodash-es';
import { registerStatus, registerResult } from '../constants/registerStatus';

export const checkIsRegistrationExisted = cond([
  [
    matches({ mobile: registerResult.NEW, email: registerResult.NEW }),
    constant(registerStatus.ALLOW_CREATE_MEMBER),
  ],
  [
    matches({ mobile: registerResult.NEW, email: registerResult.GUEST }),
    constant(registerStatus.EMAIL_BELONGS_TO_EXISTING_GUEST),
  ],
  [
    matches({ mobile: registerResult.NEW, email: registerResult.MEMBER }),
    constant(registerStatus.EMAIL_BELONGS_TO_EXISTING_MEMBER),
  ],
  [
    matches({ mobile: registerResult.GUEST, email: registerResult.NEW }),
    constant(registerStatus.MOBILE_PHONE_BELONGS_TO_EXISTING_GUEST),
  ],
  [
    matches({ mobile: registerResult.GUEST, email: registerResult.GUEST }),
    constant(registerStatus.MOBILE_PHONE_BELONGS_TO_EXISTING_GUEST),
  ],
  [
    matches({ mobile: registerResult.GUEST, email: registerResult.MEMBER }),
    constant(registerStatus.EMAIL_BELONGS_TO_EXISTING_MEMBER),
  ],
  [
    matches({ mobile: registerResult.MEMBER, email: registerResult.NEW }),
    constant(registerStatus.MOBILE_PHONE_BELONGS_TO_EXISTING_MEMBER),
  ],
  [
    matches({ mobile: registerResult.MEMBER, email: registerResult.GUEST }),
    constant(registerStatus.MOBILE_PHONE_BELONGS_TO_EXISTING_MEMBER),
  ],
  [
    matches({ mobile: registerResult.MEMBER, email: registerResult.MEMBER }),
    constant(registerStatus.EMAIL_AND_MOBILE_PHONE_BOTH_NOT_ALLOWED),
  ],
  [
    matches({ email: registerResult.NEW }),
    constant(registerStatus.ALLOW_CREATE_MEMBER),
  ],
  [
    matches({ email: registerResult.GUEST }),
    constant(registerStatus.EMAIL_BELONGS_TO_EXISTING_GUEST),
  ],
  [
    matches({ email: registerResult.MEMBER }),
    constant(registerStatus.EMAIL_BELONGS_TO_EXISTING_MEMBER),
  ],
  [
    matches({ mobile: registerResult.NEW }),
    constant(registerStatus.ALLOW_CREATE_MEMBER),
  ],
  [
    matches({ mobile: registerResult.GUEST }),
    constant(registerStatus.MOBILE_PHONE_BELONGS_TO_EXISTING_GUEST),
  ],
  [
    matches({ mobile: registerResult.MEMBER }),
    constant(registerStatus.MOBILE_PHONE_BELONGS_TO_EXISTING_MEMBER),
  ],
]);
