export default {
  translation: {
    'Validation - field - required': '{{ fieldName }} 是必須的',
    'Sign Up': '註冊會員',
    'Mobile Number': '手機號碼',
    Email: '電郵',
    'Enter your email': '請輸入您的電郵',
    'Accept to receive store updates':
      '我願意接收{{ shopName }}的最新優惠消息及服務推廣相關資訊',
    'Terms of Use and Privacy Policy':
      '我同意網站<0>服務條款</0>及<2>隱私權政策</2>',
    'Terms of Use and Privacy Policy and Age Policy':
      '我同意網站<0>服務條款</0>及<2>隱私權政策</2>，且我已滿 {{ age }} 歲',
    Next: '下一步',
    'Already a member': '已經有帳號？',
    'Sign in to enjoy more benefits': '立即登入享有更多優惠！',
    'Validation - email - required': '電郵是必須的',
    'Validation - email - invalid': '電郵無效',
    'Validation - email - max': '電郵太長',
    'Validation - mobile phone - required': '手機號碼是必須的',
    'Validation - mobile phone - invalid': '手機號碼無效',
    'Validation - terms and policy - required': '你必須同意細則和私隱條款',
    'Continue Sign up': '繼續註冊',
    'Sign In': '登入',
    'Register Status - result - email belongs to guest':
      '此電郵已經在這間商店使用過，請查看電郵取得設立帳戶指示',
    'Register Status - result - email belongs to member':
      '此電郵為已註冊電郵，請前往登入頁面或者使用其他電郵以完成註冊',
    'Register Status - result - mobile phone belongs to guest':
      '此手機已經在這間商店使用過，請查看簡訊取得設立帳戶指示',
    'Register Status - result - mobile phone belongs to member':
      '此電話號碼為已註冊號碼，請前往登入頁面或者使用其他電話號碼以完成註冊',
    'Register Status - result - email and mobile phone both not allowed':
      '電郵與手機號碼已分別被註冊過，請前往登入',
    'Register Status - result - email is taken': '此 電郵 已存在於其他帳號',
    'Register Status - result - mobile phone is taken':
      '此 手機 已存在於其他帳號',
    'Verify mobile number': '驗證手機號碼',
    'Verification code': '驗證碼',
    'Enter verification code': '請輸入驗證碼',
    'Send verification code': '發送驗證碼',
    'Resend Verification Code': '重發驗證碼',
    'Seconds left': '{{ seconds }} 秒',
    'Verification code will be sent after you pass reCAPTCHA':
      '驗證碼將在通過 reCAPTCHA 測試後發送',
    'Verification code has been sent to':
      '已發送驗證碼至 +{{countryCode}} {{phoneNumber}}',
    Back: '返回上一步',
    'Validation - pincode - required': '驗證碼是必須的',
    'Validation - pincode - max length': '驗證碼長度必須 {{ maxLength }} 碼',
    'Validation - pincode - invalid': '驗證碼無效',
    'Last Step': '還差一步',
    'Please enter your profile': '請輸入你的個人資料，以完成註冊流程！',
    Username: '用戶名',
    'Enter your username': '請輸入用戶名',
    Password: '密碼',
    'Password(at least 8 characters)': '密碼 (至少 8 個字元)',
    Gender: '性別',
    'Gender - male': '男',
    'Gender - female': '女',
    'Gender - undisclosed': '不透露',
    Birthday: '生日日期',
    'Birthday - year': '年',
    'Birthday - month': '月',
    'Birthday - date': '日',
    'Validation - birthday - invalid': '生日日期 無效',
    'Validation - birthday - min age': '生日日期無效，需大於 {{age}} 歲',
    'Validation - birthday - required': '生日日期是必須的',
    Optional: '(選填)',
    'Sign me up': '立即加入!',
    'Validation - username - required': '用戶名是必須的',
    'Validation - username - too long': '用戶名太長',
    'Validation - password - required': '密碼是必須的',
    'Validation - password - too short': '密碼太短',
    'Validation - password - too long': '密碼太長',
    'Email or Mobile Number': '電郵或手機號碼',
    'Password (8 characters minimum)': '密碼 (至少 8 個字元)',
    'Forgot Password': '忘記密碼？',
    'Lets go': '開始購物吧！',
    'Not a member': '還不是會員？',
    'Validation - email and mobile - required': '電郵或手機號碼是必須的',
    'Validation - email and mobile - invalid': '電郵或手機號碼無效',
    'Sign up Success': '謝謝！您的帳號已經成功註冊。',
    'Complete member info': '完成會員資訊',
    'Complete Phone Number Notice': '輸入手機號碼以獲得更完整的商家活動資訊',
    'Complete Email Notice': '輸入電郵以獲得更完整的商家活動資訊',
    Complete: '完成',
    Skip: '略過',
    'sign up with social account': '或使用社群帳戶註冊',
    'login in with social account': '或使用社群帳號登入',
    'Login - email - label': '電郵',
    'Login - email - placeholder': '請輸入電郵',
    'Login - mobile phone - label': '手機號碼',
    'Login - mobile phone - placeholder': '請輸入手機號碼',
    'Login - email or mobile phone - label': '電郵或手機號碼',
    'Login - email or mobile phone - placeholder': '請輸入電郵或手機號碼',
    'Login - Error - unconfirmed email':
      '歡迎回來！為保護您的帳號安全，即日起需完成帳號驗證才能登入。驗證信已發送至 {{email}}，請前往收信驗證，謝謝您！',
    'Member info has been updated': '會員資料更新完成',
    'Subscription - description': '使用社群平台訂閱優惠消息及服務推廣相關資訊',
    'Subscription - LINE - subscribe': '訂閱 LINE',
    'Subscription - connection notification - success': '訂閱成功',
    'Subscription - LINE - connection notification - failure':
      '訂閱失敗，請重新登入 LINE',
    'Verification - email - trial merchant - reach limit - failure':
      '商店試營運中，無法發送驗證郵件，如有需求請聯繫商店。',
    'Subscription - FACEBOOK - connection notification - failure':
      '訂閱失敗，請重新登入 FB',
  },
};
