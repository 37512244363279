import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Clock = createIcon({
  displayName: 'Clock',
  viewBox: '0 0 20 20',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.79688 6.51562C9.79688 6.12598 10.1104 5.8125 10.5 5.8125C10.8896 5.8125 11.2031 6.12598 11.2031 6.51562V10.125L13.7021 11.7891C14.0244 12.0059 14.1123 12.4424 13.8721 12.7646C13.6816 13.0869 13.2451 13.1748 12.9229 12.9346L10.1104 11.0596C9.91406 10.9541 9.79688 10.7344 9.79688 10.4736V6.51562ZM10.5 3C14.6426 3 18 6.35742 18 10.5C18 14.6426 14.6426 18 10.5 18C6.35742 18 3 14.6426 3 10.5C3 6.35742 6.35742 3 10.5 3ZM4.40625 10.5C4.40625 13.8662 7.13379 16.5938 10.5 16.5938C13.8662 16.5938 16.5938 13.8662 16.5938 10.5C16.5938 7.13379 13.8662 4.40625 10.5 4.40625C7.13379 4.40625 4.40625 7.13379 4.40625 10.5Z"
      fill="currentColor"
    />
  ),
});

export default Clock;
