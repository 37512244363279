import { Box } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import trackerService from '../../../../service/trackerService';
import { MISSING_FIELD } from '../../constants/missingField';
import { useAppContext } from '../../context/appContext';
import {
  emailSchema,
  mobilePhoneSchema,
  phoneCountrySchema,
  pincodeSchema,
} from '../../utils/yupSchema';
import {
  EVENT_CATEGORY_FOR_USER_FLOW,
  EVENT_NAME_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
} from '../../../../shop/constants/signupLoginEventsTracking';
import EmailForm from './forms/EmailForm';
import MobileForm from './forms/MobileForm';

const CompleteInfoSection = () => {
  const { smsVerificationEnabled, missingFields } = useAppContext();
  const [missingField] = missingFields;

  const schema = useMemo(() => {
    switch (missingField) {
      case MISSING_FIELD.MOBILE:
        return yup.object().shape({
          phone_country: phoneCountrySchema,
          mobile_phone: mobilePhoneSchema,
          ...(smsVerificationEnabled ? { pincode: pincodeSchema } : {}),
        });
      case MISSING_FIELD.EMAIL:
        return yup.object().shape({ email: emailSchema });
      // no default
    }
  }, [missingField, smsVerificationEnabled]);

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    trackerService.track({
      type: EVENT_NAME_FOR_USER_FLOW.UpdateMemberInfoFormShow,
      data: {
        event_category: EVENT_CATEGORY_FOR_USER_FLOW.ProfileUpdate,
        property: PROPERTY_FOR_USER_FLOW.update_info,
        value: missingField,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <FormProvider {...methods}>
        {missingField === MISSING_FIELD.MOBILE && <MobileForm />}
        {missingField === MISSING_FIELD.EMAIL && <EmailForm />}
      </FormProvider>
    </Box>
  );
};

export default CompleteInfoSection;
