import React from 'react';
import { createIcon } from '@chakra-ui/react';

const EyeOpen = createIcon({
  displayName: 'EyeOpen',
  viewBox: '0 0 20 14',
  path: (
    <>
      <path
        d="M10 3.75C8.20507 3.75 6.75 5.20507 6.75 7C6.75 8.79493 8.20507 10.25 10 10.25C11.7949 10.25 13.25 8.79493 13.25 7C13.25 5.20507 11.7949 3.75 10 3.75ZM8.25 7C8.25 6.0335 9.0335 5.25 10 5.25C10.9665 5.25 11.75 6.0335 11.75 7C11.75 7.9665 10.9665 8.75 10 8.75C9.0335 8.75 8.25 7.9665 8.25 7Z"
        fill="currentColor"
      />
      <path
        d="M10 0.25C5.61579 0.25 1.85847 2.92206 0.305875 6.71594C0.231375 6.89799 0.231375 7.10202 0.305875 7.28406C1.85847 11.0779 5.6158 13.75 10 13.75C14.3842 13.75 18.1415 11.0779 19.6941 7.28406C19.7686 7.10202 19.7686 6.89799 19.6941 6.71594C18.1415 2.92206 14.3842 0.25 10 0.25ZM10 12.25C6.3488 12.25 3.21109 10.0915 1.81629 7C3.21109 3.90852 6.34879 1.75 10 1.75C13.6512 1.75 16.7889 3.90852 18.1837 7C16.7889 10.0915 13.6512 12.25 10 12.25Z"
        fill="currentColor"
      />
    </>
  ),
});

export default EyeOpen;
