import React, { useMemo, useState, useEffect } from 'react';
import { useFormContext, useController, Controller } from 'react-hook-form';
import { Box, Text, Center } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import { getCountryCallingCode } from 'libphonenumber-js/max';
import { useCookies } from 'react-cookie';
import { translateModel } from '../../../../../utils/translate';
import authenticationService from '../../../../../service/authenticationService';
import benchatService from '../../../../../service/benchatService';
import { RECAPTCHA_TOKEN_FIELD } from '../../../../../constants/recaptchable';
import { TOPIC } from '../../../../../constants/benchat';
import { BOOLEAN_STATUS } from '../../../../../constants/userSetting';
import {
  TitleText,
  Input,
  Select,
  Button,
  ErrorText,
  ErrorBanner,
  BirthdayPicker,
  RecaptchaCheckbox,
} from '../../../components';
import { useAppContext } from '../../../context/appContext';
import extractServerErrorMessages from '../../../utils/extractServerErrorMessages';
import useBenchatSubscriptions from '../../../hooks/useBenchatSubscriptions';
import { FacebookSubscription, LineSubscription } from '../../components';
import pnotifyService from '../../../../../service/pnotifyService';
import { QUOTA_LIMIT_ERROR_TYPE } from '../../../../../shop/constants/quota_limit';
import {
  EVENT_CATEGORY_FOR_USER_FLOW,
  EVENT_NAME_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
} from '../../../../../shop/constants/signupLoginEventsTracking';
import trackerService from '../../../../../service/trackerService';
import { useRecaptcha } from '../../../hooks';

const SUBSCRIPTION_TOPICS = [TOPIC.MARKETING];

// eslint-disable-next-line react/prop-types
const StepThree = ({ handlePrevStep, handleSignUpSuccess }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    userSetting,
    birthdaySetting,
    genderSetting,
    isEmailRequired,
    isMobilePhoneRequired,
    isRecaptchaEnabled,
    smsVerificationEnabled,
    sessionId,
    merchantData,
    benchatSubscriptionConfig,
  } = useAppContext();
  const { handleSubmit, control, errors } = useFormContext();
  const { field: nameField } = useController({
    name: 'name',
    control,
    defaultValue: '',
  });

  const { field: passwordField } = useController({
    name: 'password',
    control,
    defaultValue: '',
  });

  const { field: genderField } = useController({
    name: 'gender',
    control,
    defaultValue: '',
  });

  const { field: birthdayField } = useController({
    name: 'birthday',
    control,
    defaultValue: {
      year: null,
      month: null,
      date: null,
    },
  });

  // Third-party subscriptions
  const {
    line: lineBenchatConfig,
    facebook: facebookBenchatConfig,
  } = benchatSubscriptionConfig;

  const lineBenchat = useBenchatSubscriptions({
    userId: sessionId,
    channelOwnerId: merchantData._id,
    queryFilter: (subscriptions) =>
      benchatService.getLineActiveSubscriptions(
        SUBSCRIPTION_TOPICS,
        subscriptions,
      ),
  });

  const facebookBenchat = useBenchatSubscriptions({
    userId: sessionId,
    channelOwnerId: merchantData._id,
    queryFilter: (subscriptions) =>
      benchatService.getFacebookActiveSubscriptions(
        SUBSCRIPTION_TOPICS,
        subscriptions,
      ),
  });

  const [isLoadingSignUp, setIsLoadingSignUp] = useState(false);
  const [cookies] = useCookies();
  const [signUpErrors, setSignUpErrors] = useState([]);

  const customFields = useMemo(() => {
    return userSetting.custom_fields
      ?.filter(
        (customField) =>
          customField.options.signup.include === BOOLEAN_STATUS.TRUE,
      )
      .map((customField) => {
        return (
          <Box mt="12px" key={customField.field_id}>
            <Controller
              control={control}
              name={`custom_data.${customField.field_id}`}
              defaultValue=""
              render={({ onChange, value }) => (
                <Input
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  type="text"
                  label={`${translateModel(
                    customField.name_translations,
                    language,
                  )}  ${
                    customField.options.signup.required === BOOLEAN_STATUS.TRUE
                      ? ''
                      : t('Optional')
                  }`}
                  placeholder={`${translateModel(
                    customField.hint_translations,
                    language,
                  )}`}
                  isInvalid={
                    !isEmpty(
                      errors?.custom_data?.[customField.field_id]?.message,
                    )
                  }
                  errorMessage={t(
                    errors?.custom_data?.[customField.field_id]?.message
                      .translateKey,
                    {
                      fieldName: translateModel(
                        errors?.custom_data?.[customField.field_id]?.message
                          .fieldName,
                        language,
                      ),
                    },
                  )}
                />
              )}
            />
          </Box>
        );
      });
  }, [control, errors?.custom_data, language, t, userSetting.custom_fields]);

  const onSubmit = async (fieldValues) => {
    try {
      const urlQueries = new URLSearchParams(window.location.search);
      const encryptData = urlQueries.get('encrypt_data');
      const payload = {
        user: {
          ...(isEmailRequired ? { email: fieldValues.email } : {}),
          ...(isMobilePhoneRequired
            ? {
                mobile_phone: fieldValues.mobile_phone,
                country_calling_code: getCountryCallingCode(
                  fieldValues.phone_country,
                ),
              }
            : {}),
          ...(smsVerificationEnabled ? { code: fieldValues.pincode } : {}),
          name: fieldValues.name,
          password: fieldValues.password,
          time_zone_offset: new Date().getTimezoneOffset() / -60,
          subscribed_email_types: fieldValues.subscription
            ? ['marketing.news']
            : [],
        },
        encrypt_data: encryptData,
        benchatFields: {
          subscriptions: {
            line: JSON.stringify(lineBenchat.subscriptions),
            facebook: JSON.stringify(facebookBenchat.subscriptions),
          },
        },
        ...(location.search.indexOf('from=shop_crm') > 0
          ? { from: 'shop_crm' }
          : {}),
      };

      if (genderSetting?.include === BOOLEAN_STATUS.TRUE) {
        payload.user.gender = fieldValues.gender;
      }

      if (birthdaySetting.include === BOOLEAN_STATUS.TRUE) {
        const { year, month, date } = fieldValues.birthday;
        payload.user.birth_year = year;
        payload.user.birth_month = month;
        payload.user.birth_day = date;
      }

      if (!isEmpty(fieldValues.custom_data)) {
        payload.user.custom_data = fieldValues.custom_data;
      }

      if (isRecaptchaEnabled) {
        const token = await getRecaptchaToken();
        payload[RECAPTCHA_TOKEN_FIELD] = token;
        if (recaptchaCheckboxToken) {
          payload['is_use_checkbox'] = true;
        }
        refReCAPTCHA.current?.reset();
        if (isShowCheckboxRecaptcha) {
          setIsShowCheckboxRecaptcha(false);
        }
      }
      setIsLoadingSignUp(true);
      setSignUpErrors([]);
      const {
        data: { redirect_to },
      } = await authenticationService.createUser(payload);

      // tracking user sign up form successfully submit
      await trackerService.track({
        type: EVENT_NAME_FOR_USER_FLOW.MemberSignUpInfoSubmit,
        data: {
          event_category: EVENT_CATEGORY_FOR_USER_FLOW.SignUp,
          property: PROPERTY_FOR_USER_FLOW.sign_up_option,
          value: userSetting?.signup_method,
        },
      });

      handleSignUpSuccess();

      if (redirect_to && redirect_to.indexOf('confirmation') > 0) {
        window.location.href = redirect_to;
      } else if (redirect_to) {
        const fbc = cookies['_fbc'] || '';
        const fbp = cookies['_fbp'] || '';
        window.location.href = `/sign_up_confirmation?${new URLSearchParams({
          fbc,
          fbp,
        })}`;
      }
    } catch (e) {
      const data = e?.response?.data ?? {};

      if (
        data.error_code === QUOTA_LIMIT_ERROR_TYPE.TRIAL_EMAIL_QUOTA_EXCEEDED
      ) {
        pnotifyService.notify(
          t('Verification - email - trial merchant - reach limit - failure'),
          {
            customClass: 'error',
            icon: 'fa fa-exclamation-triangle',
          },
        );
      } else if (isRecaptchaError({ errorCode: data?.error_code })) {
        refCheckboxReCAPTCHA.current?.reset();
        setIsShowCheckboxRecaptcha(true);
      } else {
        setSignUpErrors(extractServerErrorMessages(data));
      }
    } finally {
      setIsLoadingSignUp(false);
      setRecaptchaCheckboxToken('');
    }
  };

  const {
    refReCAPTCHA,
    refCheckboxReCAPTCHA,
    recaptchaCheckboxToken,
    setRecaptchaCheckboxToken,
    isRecaptchaError,
    getRecaptchaToken,
    isShowCheckboxRecaptcha,
    setIsShowCheckboxRecaptcha,
    onRecaptchaCheckboxCallback,
  } = useRecaptcha({ callback: handleSubmit(onSubmit) });

  useEffect(() => {
    trackerService.track({
      type: EVENT_NAME_FOR_USER_FLOW.MemberSignUpInfoFormShow,
      data: {
        event_category: EVENT_CATEGORY_FOR_USER_FLOW.SignUp,
        property: PROPERTY_FOR_USER_FLOW.sign_up_option,
        value: userSetting?.signup_method,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <TitleText mb="16px">{t('Last Step')}</TitleText>
      <Text color="#888" fontSize="14px" fontWeight={500} mb="32px">
        {t('Please enter your profile')}
      </Text>
      <ErrorBanner errorMessages={signUpErrors} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Input
            data-e2e-id="fill-info-sign-up-name_input"
            value={nameField.value}
            onChange={(e) => nameField.onChange(e.target.value)}
            label={t('Username')}
            placeholder={t('Enter your username')}
            name="name"
            isInvalid={!isEmpty(errors?.name?.message)}
            errorMessage={t(errors?.name?.message)}
          />
        </Box>
        <Box mt="12px">
          <Input.PasswordInput
            data-e2e-id="fill-info-sign-up-password_input"
            value={passwordField.value}
            onChange={(e) => passwordField.onChange(e.target.value)}
            label={t('Password')}
            placeholder={t('Password(at least 8 characters)')}
            isInvalid={!isEmpty(errors?.password?.message)}
            errorMessage={t(errors?.password?.message)}
          />
        </Box>
        {genderSetting.include === BOOLEAN_STATUS.TRUE && (
          <Box mt="12px">
            <Select
              label={`${t('Gender')} ${
                genderSetting.required === BOOLEAN_STATUS.TRUE
                  ? ''
                  : t('Optional')
              }`}
              placeholder={`${t('Gender')} ${
                genderSetting.required === BOOLEAN_STATUS.TRUE
                  ? ''
                  : t('Optional')
              }`}
              value={genderField.value}
              onChange={(e) => genderField.onChange(e.target.value)}
              isInvalid={!isEmpty(errors?.gender?.message)}
            >
              <option value="male">{t('Gender - male')}</option>
              <option value="female">{t('Gender - female')}</option>
              <option value="other">{t('Gender - undisclosed')}</option>
            </Select>
            {!isEmpty(errors?.gender?.message) && (
              <ErrorText>
                {t(errors.gender.message.translateKey, {
                  fieldName: t(errors.gender.message.params.fieldName),
                })}
              </ErrorText>
            )}
          </Box>
        )}
        {birthdaySetting.include === BOOLEAN_STATUS.TRUE && (
          <Box mt="12px">
            <BirthdayPicker
              value={birthdayField.value}
              onChange={(val) =>
                birthdayField.onChange({ ...birthdayField.value, ...val })
              }
              birthdayFormat={userSetting.birthday_format}
              isInvalid={!isEmpty(errors?.birthday?.message)}
              label={`${t('Birthday')} ${
                birthdaySetting.required === BOOLEAN_STATUS.TRUE
                  ? ''
                  : t('Optional')
              }`}
            />
            {!isEmpty(errors?.birthday?.message) && (
              <ErrorText>
                {t(
                  errors.birthday.message.translateKey,
                  errors.birthday.message.params,
                )}
              </ErrorText>
            )}
          </Box>
        )}
        {customFields}
        {(lineBenchatConfig.enabled || facebookBenchatConfig.enabled) && (
          <Box mt="32px">
            <Box
              mb="16px"
              color="#C0C0C0"
              fontSize="12px"
              fontWeight={500}
              lineHeight="16px"
              className="chakra-checkbox__label"
            >
              {t('Subscription - description')}
            </Box>
            <Box>
              {lineBenchatConfig.enabled && (
                <Box mt="10px">
                  <LineSubscription
                    platformChannelId={
                      lineBenchatConfig.channel.platformChannelId
                    }
                    topics={SUBSCRIPTION_TOPICS}
                    benchat={lineBenchat}
                  />
                </Box>
              )}
              {facebookBenchatConfig.enabled && (
                <Box mt="10px">
                  <FacebookSubscription
                    appId={facebookBenchatConfig.config.appId}
                    platformChannelId={
                      facebookBenchatConfig.channel.platformChannelId
                    }
                    topics={SUBSCRIPTION_TOPICS}
                    benchat={facebookBenchat}
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}
        <RecaptchaCheckbox
          refReCAPTCHA={refReCAPTCHA}
          refCheckboxReCAPTCHA={refCheckboxReCAPTCHA}
          isShowCheckboxRecaptcha={isShowCheckboxRecaptcha}
          onRecaptchaCheckboxCallback={onRecaptchaCheckboxCallback}
        />
        <Button
          data-e2e-id="fill-info-sign-up-submit_button"
          mt="32px"
          isFullWidth
          onClick={handleSubmit(onSubmit)}
          variant="solid"
          isLoading={isLoadingSignUp}
        >
          {t('Sign me up')}
        </Button>
        <Center mt="40px">
          <Button
            onClick={handlePrevStep}
            variant="link"
            size="sm"
            isDisabled={isLoadingSignUp}
          >
            {t('Back')}
          </Button>
        </Center>
      </form>
    </Box>
  );
};

export default StepThree;
