// regex copied from https://github.com/arasatasaygin/is.js

const isMobileOrPad = () => {
  const userAgent = ((navigator && navigator.userAgent) || '').toLowerCase();

  return (
    /iphone(?:.+?os (\d+))?/.test(userAgent) ||
    /android/.test(userAgent) ||
    /ipad.+?os (\d+)/.test(userAgent) ||
    /ipod.+?os (\d+)/.test(userAgent)
  );
};

export default isMobileOrPad;
