import regex from '../shop/constants/regex';
import { validatePhone } from './intlTel';

export const validatePhoneWithCountry = ({ number, phoneCountry }) => {
  if (number.length === 0) {
    return false;
  }
  if (/[&^!@$_*%#`~=]/.test(number)) {
    return false;
  }
  return validatePhone(number, phoneCountry).length === 0;
};

export const validateEmail = (value) =>
  regex.EMAIL_REGEX.test(value) && regex.W3C_EMAIL_REGEX.test(value);

export const validateMailAndMobile = (value) =>
  regex.EMAIL_MOBILE_REGEX.test(value);
