import React from 'react';
import { CompleteInfoSection, LoginSection, SignUpSection } from './sections';
import { useAppContext } from './context/appContext';
import { PANEL } from './constants/panel';

const SignupLogin = () => {
  const { currentPanel } = useAppContext();

  return (
    <div id="signup-login-page">
      {currentPanel === PANEL.LOGIN && <LoginSection />}
      {currentPanel === PANEL.SIGN_UP && <SignUpSection />}
      {currentPanel === PANEL.COMPLETE_INFO && <CompleteInfoSection />}
    </div>
  );
};

export default SignupLogin;
