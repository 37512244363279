import React, { createContext, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash-es';
import slFeatureServiceModule from '@shopline/sl-feature/js/sl-feature';
import { SIGNUP_METHOD } from '../../../constants/userSetting';
import useGlobalSDK from '../../../hooks/useGlobalSDK';
import { parseUserSetting } from '../../../utils/userSetting';
import { userSettingPropTypes } from '../utils/propTypes';
import benchatService from '../../../service/benchatService';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppContextProvider = ({
  children,
  defaultPanel,
  featuresLimit,
  userSetting: serverUserSetting,
  ...restProps
}) => {
  const grecaptcha = useGlobalSDK('grecaptcha');

  const [currentPanel, setCurrentPanel] = useState(defaultPanel); // 'LOGIN' | 'SIGN_UP'
  const [defaultAccount, setDefaultAccount] = useState({
    email: '',
    mobile_phone: '',
  });

  const userSetting = parseUserSetting(serverUserSetting);

  const isEmailRequired = [
    SIGNUP_METHOD.EMAIL,
    SIGNUP_METHOD.EMAIL_AND_MOBILE,
  ].includes(userSetting?.signup_method);

  const isMobilePhoneRequired = [
    SIGNUP_METHOD.MOBILE,
    SIGNUP_METHOD.EMAIL_AND_MOBILE,
  ].includes(userSetting?.signup_method);

  const smsVerificationEnabled =
    isMobilePhoneRequired && userSetting?.sms_verification?.status;

  const emailVerificationEnabled =
    isEmailRequired &&
    userSetting?.email_verification?.status &&
    userSetting?.login_with_verification?.status;

  const birthdaySetting =
    find(userSetting?.general_fields, {
      type: 'birthday',
    })?.options?.signup ?? {};
  const genderSetting =
    find(userSetting?.general_fields, {
      type: 'gender',
    })?.options?.signup ?? {};
  const slFeatureService = slFeatureServiceModule(featuresLimit);
  const contextProps = useMemo(
    () => ({
      userSetting,
      currentPanel,
      setCurrentPanel,
      defaultAccount,
      setDefaultAccount,
      isEmailRequired,
      isMobilePhoneRequired,
      smsVerificationEnabled,
      emailVerificationEnabled,
      birthdaySetting,
      genderSetting,
      slFeatureService,
      grecaptcha,
      benchatService,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPanel, defaultAccount, grecaptcha],
  );
  return (
    <AppContext.Provider value={{ ...contextProps, ...restProps }}>
      {children}
    </AppContext.Provider>
  );
};

AppContextProvider.propTypes = {
  defaultPanel: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  userSetting: userSettingPropTypes,
  // eslint-disable-next-line react/forbid-prop-types
  featuresLimit: PropTypes.object,
  isRecaptchaEnabled: PropTypes.bool,
  recaptchaSiteKey: PropTypes.string,
};
