import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import {
  NOTIFICATION_TYPE,
  PLATFORM,
  TOPIC,
} from '../../../../../constants/benchat';
import { isInAppBrowser } from '../../../../../service/browserService';
import benchatService from '../../../../../service/benchatService';
import { notify as pnotifyNotify } from '../../../../../service/pnotifyService';
import getPopupWindowSize from '../../../../../utils/getPopupWindowSize';
import { CheckCircle, Line } from '../../../../context/Chakra/Icons';
import { UseBenchatSubscriptionsPropTypes } from '../../../utils/propTypes';

async function createSubscriptions(benchat, topics = []) {
  const data = topics.map((topic) => ({
    platform: PLATFORM.LINE_MES_API,
    topic,
  }));
  data.push({
    platform: PLATFORM.LINE_LOGIN,
    topic: TOPIC.USER_PROFILE,
  });
  return benchat.patch(data.map(benchat.generateData));
}

function LineSubscription({ platformChannelId, topics, benchat }) {
  const { t } = useTranslation();
  const refPopupWindow = useRef();

  const isActive = benchat.subscriptions.length > 0;

  const handleSubscription = async () => {
    if (isInAppBrowser()) {
      await createSubscriptions(benchat, topics);
      const url = benchatService.getLineMessageSubscriptionRedirectToUri(
        platformChannelId,
        benchat.userRef,
        NOTIFICATION_TYPE.MARKETING,
      );
      window.location.replace(
        `${url}&shopRedirectUri=${window.location.pathname}`,
      );
    } else {
      refPopupWindow.current = window.open(
        benchatService.getLineMessageSubscriptionPopupUri(
          platformChannelId,
          benchat.userRef,
          NOTIFICATION_TYPE.MARKETING,
        ),
        'LINE LOGIN',
        getPopupWindowSize(),
      );
      await createSubscriptions(benchat, [TOPIC.MARKETING]);
      const timer = setInterval(async () => {
        if (refPopupWindow.current.closed) {
          clearInterval(timer);
          const subscriptions = await benchat.get();
          if (subscriptions.length > 0) {
            pnotifyNotify(
              t('Subscription - connection notification - success'),
            );
          } else {
            pnotifyNotify(
              t('Subscription - LINE - connection notification - failure'),
              {
                customClass: 'error',
                icon: 'fa fa-exclamation-triangle',
              },
            );
          }
        }
      }, 1000);
    }
  };

  const handleClick = () => {
    !isActive && handleSubscription();
  };

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      bg="white"
      borderRadius="4px"
      borderWidth="1px"
      p={['8px', '10px', '7px']}
      color="#06C755"
      fontSize="16px"
      fontWeight={500}
      fontFamily="Helvetica, Arial, sans-serif"
      lineHeight={1}
      cursor="pointer"
      onClick={handleClick}
    >
      {isActive ? (
        <CheckCircle w="16px" h="16px" mr="6px" ml="2px" color="#06C755" />
      ) : (
        <Line w="16px" h="16px" mr="6px" ml="2px" />
      )}
      {t('Subscription - LINE - subscribe')}
    </Box>
  );
}

LineSubscription.propTypes = {
  platformChannelId: PropTypes.string.isRequired,
  topics: PropTypes.arrayOf(PropTypes.string).isRequired,
  benchat: UseBenchatSubscriptionsPropTypes,
};

export default LineSubscription;
