import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { Box, Center, Text } from '@chakra-ui/react';
import { values, isPlainObject } from 'lodash-es';
import { RECAPTCHA_TOKEN_FIELD } from '../../../../../constants/recaptchable';
import trackerService from '../../../../../service/trackerService';
import {
  registerStatus,
  registrationErrorMessageMap,
} from '../../../constants/registerStatus';
import authenticationService from '../../../../../service/authenticationService';
import pnotifyService from '../../../../../service/pnotifyService';
import { CLASSNAME } from '../../../constants/style';
import { checkIsRegistrationExisted } from '../../../utils/checkRegisterStatus';
import { getInputFieldProps } from '../../../utils/propsBuilder';
import extractServerErrorMessages from '../../../utils/extractServerErrorMessages';
import { useAppContext } from '../../../context/appContext';
import {
  Input,
  TitleText,
  Button,
  ErrorBanner,
  RecaptchaCheckbox,
} from '../../../components';
import { useRecaptcha } from '../../../hooks';
import {
  EVENT_CATEGORY_FOR_USER_FLOW,
  VALUE_FOR_USER_FLOW,
  PROPERTY_FOR_USER_FLOW,
  EVENT_NAME_FOR_USER_FLOW,
} from '../../../../../shop/constants/signupLoginEventsTracking';

const CHECK_REGISTER_STATUS_FAILED = 'checkRegisterStatus failed';
const RETURN_TO_HOME_PAGE_WAITING_TIME = 2500; /* discuss w/ PM, this time is enough for user to read 2 message of toast*/

const EmailForm = () => {
  const { t } = useTranslation();
  const { redirectTo, isRecaptchaEnabled, currentUser } = useAppContext();
  const {
    errors,
    control,
    formState,
    handleSubmit,
    setError,
  } = useFormContext();
  const [errorMessages, setErrorMessages] = useState([]);

  const { field: emailField } = useController({
    name: 'email',
    control,
    defaultValue: '',
  });

  const checkRegisterStatus = async () => {
    try {
      const response = await authenticationService.checkRegisterStatus({
        email: emailField.value.trim(),
      });
      const result = checkIsRegistrationExisted(response.data);

      if (result !== registerStatus.ALLOW_CREATE_MEMBER) {
        throw new Error(result);
      }
    } catch (error) {
      if (values(registerStatus).includes(error?.message)) {
        setError('email', {
          type: 'custom',
          message: t(registrationErrorMessageMap[error.message]),
        });
        throw CHECK_REGISTER_STATUS_FAILED;
      }

      // unexpected error
      throw error;
    }
  };

  const handleSubmitError = (error) => {
    if (isPlainObject(error?.response?.data)) {
      setErrorMessages(extractServerErrorMessages(error.response.data));
    } else if (error === CHECK_REGISTER_STATUS_FAILED) {
      // handle unexpected error
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    setErrorMessages([]);
    try {
      const payload = {
        user: data,
      };

      await checkRegisterStatus();

      if (isRecaptchaEnabled) {
        const token = await getRecaptchaToken();
        payload[RECAPTCHA_TOKEN_FIELD] = token;
        if (recaptchaCheckboxToken) {
          payload['is_use_checkbox'] = true;
        }
        if (isShowCheckboxRecaptcha) {
          setIsShowCheckboxRecaptcha(false);
        }
        refUpdateUserReCAPTCHA.current?.reset();
      }

      const res = await authenticationService.updateUser(
        currentUser._id,
        payload,
      );

      const updateUserResData = res?.data?.data || {};
      if (updateUserResData.trial_email_quota_exceeded) {
        pnotifyService.notify(
          t('Verification - email - trial merchant - reach limit - failure'),
          {
            customClass: 'error',
            icon: 'fa fa-exclamation-triangle',
          },
        );
      }

      pnotifyService.notify(t('Member info has been updated'), {});

      // track update member email info succeed
      await trackerService.track({
        type: EVENT_NAME_FOR_USER_FLOW.UpdateMemberInfoSucceed,
        data: {
          event_category: EVENT_CATEGORY_FOR_USER_FLOW.ProfileUpdate,
          property: PROPERTY_FOR_USER_FLOW.update_info,
          value: VALUE_FOR_USER_FLOW.email,
        },
      });

      setTimeout(() => {
        window.location.href = redirectTo;
      }, RETURN_TO_HOME_PAGE_WAITING_TIME);
    } catch (error) {
      if (isRecaptchaError({ errorCode: error?.response?.data?.error_code })) {
        refCheckboxReCAPTCHA.current?.reset();
        setIsShowCheckboxRecaptcha(true);
      } else {
        handleSubmitError(error);
      }
    }
  };

  const {
    refReCAPTCHA: refUpdateUserReCAPTCHA,
    refCheckboxReCAPTCHA,
    recaptchaCheckboxToken,
    isRecaptchaError,
    getRecaptchaToken,
    isShowCheckboxRecaptcha,
    setIsShowCheckboxRecaptcha,
    onRecaptchaCheckboxCallback,
  } = useRecaptcha({ callback: handleSubmit(onSubmit) });

  return (
    <>
      <Box mb="72px">
        <TitleText mb="16px">{t('Complete member info')}</TitleText>
        <Text
          color="#888"
          fontSize="14px"
          fontWeight={500}
          mb="32px"
          className={CLASSNAME.DESCRIPTION}
        >
          {t('Complete Email Notice')}
        </Text>
        <ErrorBanner errorMessages={errorMessages} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb="10px">
            <Input
              data-e2e-id="complete-info-email_input"
              placeholder={t('Enter your email')}
              label={t('Email')}
              {...getInputFieldProps({
                field: emailField,
                error: errors?.email,
                t,
              })}
            />
          </Box>
          <RecaptchaCheckbox
            refReCAPTCHA={refUpdateUserReCAPTCHA}
            refCheckboxReCAPTCHA={refCheckboxReCAPTCHA}
            isShowCheckboxRecaptcha={isShowCheckboxRecaptcha}
            onRecaptchaCheckboxCallback={onRecaptchaCheckboxCallback}
          />
          <Button
            data-e2e-id="complete-info-email-submit_button"
            isFullWidth
            onClick={handleSubmit(onSubmit)}
            mt="32px"
            type="submit"
            isLoading={formState.isSubmitting}
          >
            {t('Complete')}
          </Button>
        </form>
        <Center mt="64px">
          <Button
            onClick={() => (window.location.href = redirectTo)}
            variant="link"
            size="sm"
          >
            {t('Skip')}
          </Button>
        </Center>
      </Box>
    </>
  );
};

export default EmailForm;
