export default {
  translation: {
    'Validation - field - required': '{{ fieldName }} 是必须的',
    'Sign Up': '注册会员',
    'Mobile Number': '手机号码',
    Email: '电邮',
    'Enter your email': '请输入您的电邮',
    'Accept to receive store updates':
      '我愿意接收{{ shopName }}的最新优惠消息及服务推广相关资讯',
    'Terms of Use and Privacy Policy':
      '我同意网站<0>服务条款</0>及<2>隐私权政策</2>',
    'Terms of Use and Privacy Policy and Age Policy':
      '我同意网站<0>服务条款</0>及<2>隐私权政策</2>，且我已满 {{ age }} 岁',
    Next: '下一步',
    'Already a member': '已经有帐号？',
    'Sign in to enjoy more benefits': '立即登入享有更多优惠！',
    'Validation - email - required': '电邮是必须的',
    'Validation - email - invalid': '电邮无效',
    'Validation - email - max': '电邮太长',
    'Validation - mobile phone - required': '手机号码是必须的',
    'Validation - mobile phone - invalid': '手机号码无效',
    'Validation - terms and policy - required': '你必须同意细则和私隐条款',
    'Continue Sign up': '继续注册',
    'Sign In': '登入',
    'Register Status - result - email belongs to guest':
      '此电邮已经在这间商店使用过，请查看电邮取得设立帐户指示',
    'Register Status - result - email belongs to member':
      '此电邮为已注册电邮，请前往登入页面或者使用其他电邮以完成注册',
    'Register Status - result - mobile phone belongs to guest':
      '此手机已经在这间商店使用过，请查看短信取得设立帐户指示',
    'Register Status - result - mobile phone belongs to member':
      '此电话号码为已注册号码，请前往登入页面或者使用其他电话号码以完成注册',
    'Register Status - result - email and mobile phone both not allowed':
      '电邮与手机号码已分别被注册过，请前往登入',
    'Register Status - result - email is taken': '此 电邮 已存在于其他帐号',
    'Register Status - result - mobile phone is taken':
      '此 手机 已存在于其他帐号',
    'Verify mobile number': '验证手机号码',
    'Verification code': '验证码',
    'Enter verification code': '请输入验证码',
    'Send verification code': '发送验证码',
    'Resend Verification Code': '重发验证码',
    'Verification code will be sent after you pass reCAPTCHA':
      '验证码将在通过 reCAPTCHA 测试后发送',
    'Verification code has been sent to':
      '已发送验证码至 +{{countryCode}} {{phoneNumber}}',
    'Seconds left': '{{ seconds }} 秒',
    Back: '返回上一步',
    'Validation - pincode - required': '验证码是必须的',
    'Validation - pincode - max length': '验证码长度必须 {{ maxLength }} 码',
    'Validation - pincode - invalid': '验证码无效',
    'Last Step': '还差一步',
    'Please enter your profile': '请输入你的个人资料，以完成注册流程！',
    Username: '用户名',
    'Enter your username': '请输入用户名',
    Password: '密码',
    'Password(at least 8 characters)': '密码 (至少 8 个字元)',
    Gender: '性别',
    'Gender - male': '男',
    'Gender - female': '女',
    'Gender - undisclosed': '不透露',
    Birthday: '生日日期',
    'Birthday - year': '年',
    'Birthday - month': '月',
    'Birthday - date': '日',
    'Validation - birthday - invalid': '生日日期 无效',
    'Validation - birthday - min age': '生日日期无效，需大于 {{age}} 岁',
    'Validation - birthday - required': '生日日期是必须的',
    Optional: '(选填)',
    'Sign me up': '立即加入!',
    'Validation - username - required': '用户名是必须的',
    'Validation - username - too long': '用户名太长',
    'Validation - password - required': '密码是必须的',
    'Validation - password - too short': '密码太短',
    'Validation - password - too long': '密码太长',
    'Email or Mobile Number': '电邮或手机号码',
    'Password (8 characters minimum)': '密码 (至少 8 个字元)',
    'Forgot Password': '忘记密码？',
    'Lets go': '开始购物吧！',
    'Not a member': '还不是会员？',
    'Validation - email and mobile - required': '电邮或手机号码是必须的',
    'Validation - email and mobile - invalid': '电邮或手机号码无效',
    'Sign up Success': '谢谢！您的帐号已经成功注册。',
    'Complete member info': '完成会员资讯',
    'Complete Phone Number Notice': '输入手机号码以获得更完整的商家活动资讯',
    'Complete Email Notice': '输入电邮以获得更完整的商家活动资讯',
    Complete: '完成',
    Skip: '略过',
    'sign up with social account': '或使用社群帐户注册',
    'login in with social account': '或使用社群帐号登入',
    'Login - email - label': '电邮',
    'Login - email - placeholder': '请输入电邮',
    'Login - mobile phone - label': '手机号码',
    'Login - mobile phone - placeholder': '请输入手机号码',
    'Login - email or mobile phone - label': '电邮或手机号码',
    'Login - email or mobile phone - placeholder': '请输入电邮或手机号码',
    'Login - Error - unconfirmed email':
      '欢迎回来！为保护您的帐号安全，即日起需完成帐号验证才能登入。验证信已发送至 {{email}}，请前往收信验证，谢谢您！',
    'Member info has been updated': '会员资料更新完成',
    'Subscription - description': '使用社群平台订阅优惠消息及服务推广相关资讯',
    'Subscription - LINE - subscribe': '订阅 LINE',
    'Subscription - connection notification - success': '订阅成功',
    'Subscription - LINE - connection notification - failure':
      '订阅失败，请重新登入 LINE',
    'Verification - email - trial merchant - reach limit - failure':
      '商店试营运中无法发送验证邮件，如有需求请联系商店，谢谢。',
    'Subscription - FACEBOOK - connection notification - failure':
      '订阅失败，请重新登入 FB',
  },
};
