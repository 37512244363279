import { useEffect } from 'react';
import { useAppContext } from '../context/appContext';

function useFlash(callback) {
  const { flash } = useAppContext();
  useEffect(
    () => {
      callback(flash);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
}

export default useFlash;
