/**
 * Use Singleton Pattern for react-on-rails
 *  Due to their different entry files, it is not possible to share between different entry files.
 *  So we use the singleton pattern
 *  github issue: https://github.com/shakacode/react_on_rails/issues/991
 */
const getReactOnRailsInstance = () => {
  if (!window.__SL_REACT_ON_RAILS__) {
    // react-on-rails imports cannot be placed at the top, because they will be imported by different "entry" files，lead to a different scope
    window.__SL_REACT_ON_RAILS__ = require('react-on-rails').default;
    Object.freeze(window.__SL_REACT_ON_RAILS__);
  }

  return window.__SL_REACT_ON_RAILS__;
};

const reactOnRails = getReactOnRailsInstance();

export default reactOnRails;
