import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Bell = createIcon({
  displayName: 'Bell',
  viewBox: '0 0 20 20',
  path: (
    <>
      <g clipPath="url(#clip0_5658_5437)">
        <path
          d="M9.9999 18C11.1036 18 11.999 17.1047 11.999 16H8.00084C8.00084 17.1047 8.89615 18 9.9999 18ZM16.7308 13.3216C16.1271 12.6728 14.9974 11.6969 14.9974 8.5C14.9974 6.07188 13.2949 4.12812 10.9993 3.65125V3C10.9993 2.44781 10.5518 2 9.9999 2C9.44802 2 9.00052 2.44781 9.00052 3V3.65125C6.7049 4.12812 5.0024 6.07188 5.0024 8.5C5.0024 11.6969 3.87271 12.6728 3.26896 13.3216C3.08146 13.5231 2.99834 13.7641 2.9999 14C3.00334 14.5125 3.40552 15 4.00302 15H15.9968C16.5943 15 16.9968 14.5125 16.9999 14C17.0015 13.7641 16.9183 13.5228 16.7308 13.3216Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_5658_5437">
          <rect
            width="14"
            height="16"
            fill="white"
            transform="translate(3 2)"
          />
        </clipPath>
      </defs>
    </>
  ),
});

export default Bell;
