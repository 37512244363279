// 612 and 600 is following Facebook`s size
export const DEFAULT_WIDTH = 600;
export const DEFAULT_HEIGHT = 612;

function getPopupWindowSize(params = {}) {
  const { width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT } = params;
  const { width: screenWidth = 0, height: screenHeight = 0 } =
    window?.screen ?? {};

  const windowSize = {
    width,
    height,
    // For centering window
    top: Math.max((screenHeight - height) / 2, 0),
    left: Math.max((screenWidth - width) / 2, 0),
  };

  return Object.entries(windowSize)
    .map(([key, value]) => `${key}=${value}`)
    .join(',');
}

export default getPopupWindowSize;
