import axios from 'axios';
import reactOnRails from '../utils/reactOnRails';
import SignupLogin from '../pages/SignupLogin';
import '../pages/SignupLogin/services/i18n';

const CsrfToken = document
  .querySelector('meta[name=csrf-token]')
  ?.getAttribute('content');

if (CsrfToken) {
  axios.defaults.headers.common['X-CSRF-Token'] = CsrfToken;
}

reactOnRails.register({ SignupLogin });
