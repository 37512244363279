export const STEPS = {
  SIGN_UP: {
    FILL_IN_ACCOUNT: 'FILL_IN_ACCOUNT', // step 1
    MOBILE_PHONE_VERIFICATION: 'MOBILE_PHONE_VERIFICATION', // step 2
    BASIC_INFO: 'BASIC_INFO', // step 3
    SUCCESS: 'SUCCESS', // step success
  },
  LOGIN: {
    FILL_IN_ACCOUNT: 'FILL_IN_ACCOUNT',
  },
};
