import { COLOR } from '../constants/style';

const Select = {
  sizes: {
    md: {
      field: {
        padding: '7px 25px 10px 0',
        h: '36px',
        fontSize: '1.4rem',
      },
    },
  },
  baseStyle: {
    field: {
      color: '#272D36',
      _placeholder: {
        color: '#888',
      },
      boxShadow: 'none !important',
      _focus: {
        _invalid: {
          borderColor: COLOR.ERROR,
        },
      },
    },
  },
  defaultProps: {
    focusBorderColor: '#EDEDED',
    errorBorderColor: COLOR.ERROR,
    iconColor: '#474F5E',
    variant: 'flushed',
  },
};

export default Select;
