import { pick, merge } from 'lodash-es';

export const DEFAULT_PNOTIFY_VALUE = {
  addclass: 'success',
  animate: {
    animate: true,
    in_class: 'fadeInDown',
    out_class: 'fadeOut',
  },
  delay: 4000,
  icon: 'fa fa-check-circle',
};

export function notify(title, styleParams = {}) {
  const { customClass: addclass, ...restStyleParams } = styleParams;

  new PNotify(
    merge(
      { title },
      DEFAULT_PNOTIFY_VALUE,
      { addclass },
      pick(restStyleParams, ['animate', 'delay', 'icon']),
    ),
  );
}

export default {
  notify,
};
