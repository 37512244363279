import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import IntlTelInput from 'react-intl-tel-input';
import clsx from 'classnames';
import { FormControl } from '@chakra-ui/react';
import { getCountryData } from '../../../../utils/intlTel';
import { Chevron } from '../../../context/Chakra/Icons';
import { CLASSNAME } from '../../constants/style';
import { useInput } from '../../hooks/useInput';
import { InputLabel, InputErrorMessage } from './Input';
import 'react-intl-tel-input/dist/main.css';
import './MobilePhoneInput.scss';

const MobilePhoneInput = (props) => {
  const {
    name,
    isInvalid,
    label,
    errorMessage,
    defaultCountry,
    onPhoneNumberBlur,
    onSelectFlag,
    e2eId = '',
    ...restProps
  } = props;
  const { shouldShowLabel, onFocus, onBlur } = useInput(props);
  const [country, setCountry] = useState(defaultCountry?.toUpperCase());

  const phoneRef = useRef();
  const [arrowContainer, setArrowContainer] = useState(null);
  useEffect(() => {
    if (country) {
      const selectedFlagContainer = phoneRef.current.querySelector(
        '.intl-tel-input .selected-flag .iti-flag',
      );
      selectedFlagContainer.innerHTML = `<span class="country-code">${country}</span>`;
    }
  }, [country]);

  useEffect(() => {
    setTimeout(() => {
      const arrowContainer = phoneRef.current?.querySelector(
        '.selected-flag .arrow',
      );
      setArrowContainer(arrowContainer);
    });
  }, []);

  return (
    <FormControl
      className={`mobile-phone-input ${isInvalid ? 'is-error' : ''}`}
      isInvalid={isInvalid}
      ref={phoneRef}
    >
      <InputLabel
        label={shouldShowLabel ? label : ''}
        isInvalid={isInvalid}
        mb="2px"
      />
      <div data-e2e-id={e2eId}>
        <IntlTelInput
          fieldName={name}
          separateDialCode
          inputClassName={clsx(
            'enter-phone-input',
            isInvalid ? CLASSNAME.CUSTOM_ERROR_BORDER : '',
          )}
          preferredCountries={[]}
          onPhoneNumberFocus={onFocus}
          onPhoneNumberBlur={(isValid, newNumber) => {
            onBlur();
            onPhoneNumberBlur(isValid, newNumber);
          }}
          onSelectFlag={(currentNumber, selectedCountryData) => {
            onSelectFlag?.(currentNumber, selectedCountryData);
            setCountry(selectedCountryData?.iso2?.toUpperCase());
          }}
          defaultCountry={country.toLowerCase()}
          countriesData={getCountryData()}
          {...restProps}
        />
      </div>
      {arrowContainer && createPortal(<Chevron />, arrowContainer)}
      <InputErrorMessage>{errorMessage}</InputErrorMessage>
    </FormControl>
  );
};

MobilePhoneInput.propTypes = {
  isInvalid: PropTypes.bool,
  name: PropTypes.string,
  onSelectFlag: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
  defaultCountry: PropTypes.string,
  onPhoneNumberBlur: PropTypes.func,
  renderButton: PropTypes.func,
  e2eId: PropTypes.string,
};

export default MobilePhoneInput;
