import React from 'react';
import * as yup from 'yup';
import { Box } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import LoginForm from './forms/LoginForm';
import { passwordSchema } from '../../utils/yupSchema';
import { validateMailAndMobile } from '../../../../utils/validators';

const errorMessageI18nKey = {
  required: 'Validation - email and mobile - required',
  invalid: 'Validation - email and mobile - invalid',
};

const LoginSection = () => {
  const emailAndMobileSchema = yup
    .string()
    .required(errorMessageI18nKey.required)
    .test('custom', errorMessageI18nKey.invalid, validateMailAndMobile);

  const schema = yup.object().shape({
    emailAndMobile: emailAndMobileSchema.nullable(),
    password: passwordSchema,
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  return (
    <Box>
      <FormProvider {...methods}>
        <LoginForm />
      </FormProvider>
    </Box>
  );
};

export default LoginSection;
