import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../locale/en';
import vi from '../locale/vi';
import th from '../locale/th';
import zhHant from '../locale/zh-hant';
import zhCn from '../locale/zh-cn';

i18n.use(initReactI18next).init({
  resources: {
    en,
    vi,
    th,
    'zh-hant': zhHant,
    'zh-cn': zhCn,
  },
  debug: process.env.NODE_ENV === 'development',
  defaultNS: 'translation',
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  fallbackLng: 'en',
  initImmediate: false,
  lowerCaseLng: true,
});

export default i18n;
